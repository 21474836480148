<template>
  <div>
    <div v-if="computedShowSimpleText">
      Starte mit
      {{ formatCurrency(currentBrand.CondRequiredCapitalFrom) }}
      Eigenkapital, um deinen Traum zu verwirklichen.
    </div>
    <div v-else>
      Starte mit
      <strong>{{
        formatCurrency(currentBrand.CondRequiredCapitalFrom)
      }}
        Eigenkapital</strong>
      um weitere
      <strong>{{
        formatCurrency(
          currentBrand.CondInvestSumFrom -
          currentBrand.CondRequiredCapitalFrom
        )
      }}
        Fremdkapital</strong>
      aufzunehmen und die
      <strong>Gesamtinvestition von
        {{ formatCurrency(currentBrand.CondInvestSumFrom) }}</strong>
      zu erreichen und deinen Traum zu verwirklichen.
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConditionsGeneratedText',
  components: {
  },
  props: {
    currentBrand: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  methods: {
  },
  computed: {
    formatCurrency () {
      return (value) => {
        return (
          new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(value) + ' €'
        )
      }
    },
    computedCondRequiredCapital () {
      if (this.currentBrand.CondRequiredCapitalType === 0) {
        return 0
      }
      return this.currentBrand.CondRequiredCapitalFrom
    },
    computedCondInvestSum () {
      if (this.currentBrand.CondInvestSumType === 0) {
        return 0
      }
      return this.currentBrand.CondInvestSumFrom
    },
    computedForeignCapital () {
      if (this.currentBrand.CondInvestSumType === 0) {
        return null
      }
      return this.computedCondInvestSum - this.computedCondRequiredCapital
    },
    computedShowSimpleText () {
      return this.computedCondRequiredCapital >= this.computedCondInvestSum || this.computedForeignCapital === 0
    }
  }
}

</script>
