<template>
  <div class="mb-bar-chart p-4 mb-4 card" :class="!statIsOpen ? 'mb-collapsed' : ''">
      <div class="d-flex align-items-center">
          <h2 v-if="title != ''" @click="openOrCloseStat()">{{title}} <i class="fa" :class="titleArrowClass" v-if="this.collapsed"></i></h2>
          <div class="ms-5 d-flex" v-if="showMemberSelection && statIsOpen">
              <b-form-checkbox v-model="includeMembers" :value="true" :unchecked-value="false">Mitglieder</b-form-checkbox>
              <b-form-checkbox v-model="includeNonMembers" :value="true" :unchecked-value="false" class="ms-4">Nicht-Mitglieder</b-form-checkbox>
              <b-button v-if="showRefreshButton" @click="getChartData()" class="ms-4" variant="success">Daten aktualisieren</b-button>
          </div>
      </div>
      <div v-if="statIsOpen">
        <div v-if="isLoading" class="mb-loading"><span v-t="'page.statistics.loading'"></span></div>
        <div v-if="!isLoading && showNoDataHint" class="mb-no-chart-data"><span v-t="'page.statistics.nodata'"></span></div>
        <div v-if="!isLoading && showErrorMessage" class="mb-no-chart-data"><span v-t="'page.statistics.error'"></span></div>
          <bar-chart
              v-if="!isLoading && chartData.datasets != null && chartData.datasets.length > 0"
              :chartData="chartData"
              :options="chartData.options"
              :plugins="[]"
              :totalText="null"
          ></bar-chart>
      </div>
  </div>
</template>

<script>
import BarChart from './Bar.vue'
import moment from 'moment'

export default {
  components: {
    BarChart
  },
  name: 'ImpressionsChartBar',
  props: ['startDate', 'endDate', 'groupby', 'title', 'currentBrand', 'showMemberSelection', 'scaleYstepSize', 'filterByBrandIDs', 'parent', 'collapsed', 'loadCounter', 'showAvgData'],
  data: function () {
    return {
      chartData: {},
      chartLabels: [],
      barChartDatasetsPortal: [],
      isLoading: false,
      showNoDataHint: false,
      includeMembers: true,
      includeNonMembers: false,
      showRefreshButton: false,
      showErrorMessage: false,
      dataColCount: 0,
      avgBrands: {
        de: [],
        at: [],
        ch: []
      },
      membersData: [],
      filterBrandIDs: [],
      titleArrowClass: 'fa-arrow-down',
      showFirstTime: true,
      currentPortals: ['DE', 'AT', 'CH'],
      chartDataDE: null,
      chartDataCH: null,
      chartDataAT: null
    }
  },
  computed: {
    statIsOpen () {
      return this.collapsed === true ? this.titleArrowClass === 'fa-arrow-up' : true
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    parentPage: function () {
      if (this.parent == null) return this.$parent
      return this.parent
    }
  },
  created: function () {
  },
  methods: {
    getChartData (groupByChanged = false) {
      if (!this.statIsOpen) return
      const promiseAll = []
      if (this.currentBrand != null &&
              this.currentBrand.BrandModules[0].Fair != null &&
              this.currentBrand.BrandModules[0].Fair.FairPortals.length > 0) {
        const fairPortals = this.currentBrand.BrandModules[0].Fair.FairPortals
        this.currentPortals = []
        if (!groupByChanged) {
          fairPortals.forEach(fairPortal => {
            let portalAdded = false
            if (fairPortal.PortalID === 1) {
              this.currentPortals.push('DE')
              promiseAll.push(this.getDetailBOChartData(fairPortal.PortalID))
              portalAdded = true
            }
            if (fairPortal.PortalID === 2) {
              this.currentPortals.push('CH')
              promiseAll.push(this.getDetailBOChartData(fairPortal.PortalID))
              portalAdded = true
            }
            if (fairPortal.PortalID === 3) {
              this.currentPortals.push('AT')
              promiseAll.push(this.getDetailBOChartData(fairPortal.PortalID))
              portalAdded = true
            }
            if (!portalAdded) {
              promiseAll.push(Promise.resolve(null))
            }
          })
        } else {
          promiseAll.push(this.chartDataDE)
          promiseAll.push(this.chartDataCH)
          promiseAll.push(this.chartDataAT)
        }
      }
      const momentStarDate = moment(this.startDate, 'DD.MM.YYYY')
      this.filterBrandIDs = this.filterByBrandIDs != null && this.filterByBrandIDs.length > 0
        ? this.filterByBrandIDs
        : (this.currentBrand != null ? this.parentPage.SM.getBrandsBySpecialSectorGroup(this.currentBrand.SpecialSector.SpecialSectorGroup) : [])

      this.setColumnCount()
      Promise.all(promiseAll)
        .then(async (results) => {
          const [detailBODataDE, detailBODataCH, detailBODataAT] = results
          let detailBOChartBarData = null
          // let avgLineChartData = []
          if ((detailBODataDE != null && detailBODataDE !== false) || (detailBODataCH != null && detailBODataCH !== false) || (detailBODataAT != null && detailBODataAT !== false)) {
            this.chartDataDE = detailBODataDE
            this.chartDataCH = detailBODataCH
            this.chartDataAT = detailBODataAT
            detailBOChartBarData = this.mapChartBOBarData(detailBODataDE, detailBODataCH, detailBODataAT, this.dataColCount, momentStarDate)
            this.showNoDataHint = false
          } else {
            this.showNoDataHint = true
          }
          this.chartData = this.getDefaultChartData()
          this.chartData.datasets = detailBOChartBarData
          this.chartData.options.tooltips.callbacks.beforeLabel = this.tooltipCallbackBeforeLabel
          this.chartData.options.tooltips.callbacks.label = this.tooltipCallbackLabel
          this.chartData.options.legend.labels.filter = this.getFilteredLabels
          this.chartData.options.legend.labels.generateLabels = this.changeLegendText
          this.chartData.labels = this.chartLabels
        }).finally(() => {
          this.isLoading = false
        })
    },
    setColumnCount () {
      const momentStarDate = moment(this.startDate, 'DD.MM.YYYY')
      const momentEndDate = moment(this.endDate, 'DD.MM.YYYY')
      this.dataColCount = momentEndDate.diff(momentStarDate, this.groupby.toLowerCase() + 's') + 1
      if (this.dataColCount === 1 && (this.groupby === 'Week' || this.groupby === 'Month')) {
        if (momentStarDate.format('w') !== momentEndDate.format('w')) this.dataColCount++
      }
    },
    getDetailBOChartData (portalID) {
      this.showRefreshButton = false
      return this.parentPage.SM.getDetailBOChartData(portalID, this.currentBrand.BrandID, 'Seite aufgerufen', this.startDate, this.endDate).then(result => {
        if (result === false) {
          this.showErrorMessage = true
          this.showRefreshButton = true
          this.showNoDataHint = false
          return false
        }
        return result
      })
    },
    mapChartBOBarData (apiDataDE, apiDataCH, apiDataAT, dataColCount, startDate) {
      if (this.isLoading) return

      this.isLoading = true
      this.chartLabels = []
      startDate = moment(this.startDate, 'DD.MM.YYYY')

      const dataBrand = {
        de: new Array(dataColCount).fill(0),
        at: new Array(dataColCount).fill(0),
        ch: new Array(dataColCount).fill(0),
        ug: new Array(dataColCount).fill(0)
      }
      const dataPrev = {
        de: new Array(dataColCount).fill(0),
        at: new Array(dataColCount).fill(0),
        ch: new Array(dataColCount).fill(0),
        ug: new Array(dataColCount).fill(0)
      }
      let currentDay = startDate
      let dayFormat = 'YYYY-MM-DD'
      let labelFormat = 'DD.MM'
      if (this.groupby === 'Week') {
        dayFormat = 'GGGG/WW'
        labelFormat = dayFormat
      }
      if (this.groupby === 'Month') {
        dayFormat = 'YYYY-MM'
        labelFormat = dayFormat
      }
      const portals = ['de', 'ch', 'at']
      for (let i = 0; i < dataColCount; i++) {
        if (!this.chartLabels.includes(currentDay.format(labelFormat))) this.chartLabels.push(currentDay.format(labelFormat))
        portals.forEach(portal => {
          let apiData = null
          if (portal === 'de') apiData = apiDataDE
          if (portal === 'ch') apiData = apiDataCH
          if (portal === 'at') apiData = apiDataAT
          if (apiData != null && apiData !== false) {
            if (!this.barChartDatasetsPortal.includes(portal)) this.barChartDatasetsPortal.push(portal)
            apiData.body.forEach(thisData => {
              if (moment(thisData.date).format(dayFormat) === currentDay.format(dayFormat)) {
                dataBrand[portal][i] += parseInt(thisData.nb_uniq_visitors)
              }
            })
          }
        })
        currentDay = startDate.add(1, this.groupby.toLowerCase() + 's')
      }

      return this.createDataSets(dataBrand, dataPrev)
    },
    mapPortal (portal) {
      if (portal == null || portal === '') return

      portal = portal.toLowerCase()
      let result = portal

      if (portal.startsWith('franchiseportal')) result = portal.replace('franchiseportal.', '')
      if (portal.startsWith('franchisekomparator')) result = 'kp' + portal.replace('franchisekomparator.', '')
      if (portal.startsWith('chatbot')) result = 'cb' + portal.replace('chatbot.', '')
      if (portal.startsWith('unternehmer')) result = 'ug'

      return result
    },
    createDataSets (dataBrand, dataPrev) {
      const datasets = []
      if (this.barChartDatasetsPortal.includes('de')) {
        datasets.push(
          {
            label: 'FranchisePORTAL.de ' + this.$t('page.statistics.brandpage'),
            data: dataBrand.de,
            backgroundColor: '#005578',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('at')) {
        datasets.push(
          {
            label: 'FranchisePORTAL.at ' + this.$t('page.statistics.brandpage'),
            data: dataBrand.at,
            backgroundColor: '#72bb53',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('ch')) {
        datasets.push(
          {
            label: 'FranchisePORTAL.ch ' + this.$t('page.statistics.brandpage'),
            data: dataBrand.ch,
            backgroundColor: '#ff3823',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('ug')) {
        datasets.push(
          {
            label: 'UG.com ' + this.$t('page.statistics.brandpage'),
            data: dataBrand.ug,
            backgroundColor: '#ffa834',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }

      return datasets
    },
    getFilteredLabels (item, chart) { // Filtern der Legende
      return !item.text.includes(this.$t('page.statistics.brandpage')) && !item.text.startsWith('Ø')
    },
    changeLegendText (chart) { // Textänderung der Legende
      const data = chart.data
      const legends = Array.isArray(data.datasets)
        ? data.datasets.map(function (dataset, i) {
          return {
            text: dataset.label.replace(' ' + this.$t('page.statistics.brandpage'), '') + '   (X)',
            fillStyle: (!Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor : dataset.backgroundColor[0]),
            hidden: !chart.isDatasetVisible(i),
            lineCap: dataset.borderCapStyle,
            lineDash: dataset.borderDash,
            lineDashOffset: dataset.borderDashOffset,
            lineJoin: dataset.borderJoinStyle,
            lineWidth: dataset.borderWidth,
            strokeStyle: dataset.borderColor,
            pointStyle: dataset.pointStyle,
            datasetIndex: i
          }
        }, this)
        : []
      return legends
    },
    tooltipCallbackBeforeLabel (tooltipItem, data) {
      const current = data.datasets[tooltipItem.datasetIndex]
      const label = current.label
      const labelGroup = label.split(' ')
      if (label.endsWith(this.$t('page.statistics.brandpage'))) {
        return labelGroup[0]
      }
      if (label.startsWith('Ø')) {
        const avgCurrent = current.avgBrandData[tooltipItem.index]
        const specialSectorGroup = (this.currentBrand != null ? this.currentBrand.SpecialSector.SpecialSectorGroup.Caption + '<br>' : '')
        if (avgCurrent != null) return 'Ø ' + specialSectorGroup + this.$t('page.statistics.nuberofbrands') + ': ' + avgCurrent.BrandCount + '<br>Max: ' + avgCurrent.max.Count + ' - ' + avgCurrent.max.Name + '<br>Min: ' + avgCurrent.min.Count + ' - ' + avgCurrent.min.Name
      }
      return ''
    },
    tooltipCallbackLabel (tooltipItem, data) {
      let label = data.datasets[tooltipItem.datasetIndex].label
      if (label.startsWith('Ø')) return label + ': ' + tooltipItem.yLabel
      const splitLabel = label.split(' ')
      label = label.replace(splitLabel[0], '')
      return label + ': ' + tooltipItem.yLabel
    },
    getDefaultChartData () {
      return {
        labels: [],
        datasets: [],
        options: {
          responsive: true,
          tooltips: {
            enabled: false,
            mode: 'label',
            callbacks: {}
          },
          scales: {
            xAxes: [{ id: 'xbar-stacked', stacked: true }],
            yAxes: [
              { id: 'ybar-stacked', stacked: true, ticks: { stepSize: this.scaleYstepSize ? this.scaleYstepSize : (this.groupby === 'Month' ? 10 : 5) } }
            ]
          },
          legend: {
            display: true,
            labels: {}
          },
          maintainAspectRatio: false
        }
      }
    },
    openOrCloseStat () {
      if (this.collapsed == null) return
      this.titleArrowClass = this.statIsOpen ? 'fa-arrow-down' : 'fa-arrow-up'
      this.getChartData()
    }
  },
  watch: {
    'currentBrand.BrandID': function () {
      this.getChartData()
    },
    startDate: function () {
      if (typeof this.loadCounter !== 'undefined') return
      this.getChartData()
    },
    endDate: function () {
      if (typeof this.loadCounter !== 'undefined') return
      this.getChartData()
    },
    groupby: function () {
      if (typeof this.loadCounter !== 'undefined') return
      this.getChartData(true)
    },
    includeMembers: function () {
      this.showRefreshButton = true
    },
    includeNonMembers: function () {
      this.showRefreshButton = true
    },
    filterByBrandIDs: function () {
      this.getChartData()
    },
    loadCounter: function () {
      this.getChartData()
    }
  },
  mounted: function () {
    this.getChartData()
  }
}
</script>

<style lang="less" scoped>
.mb-bar-chart {
  min-height: 400px;
  position: relative;

  &.mb-collapsed {
    min-height: 0;
  }
}
.mb-no-chart-data, .mb-loading {
  background-color: rgba(255,255,255,0.5);
  position: absolute;
  display: grid;
  place-items: center;
  height: calc(100% - (1.5rem * 2) - 15px);
  width: calc(100% - (1.5rem * 2));
  font-weight: 700;
  font-size: 20px;
  bottom: 0px;
}
.custom-control {
  z-index: initial;
}
h2 {
  cursor: pointer;
}
</style>
