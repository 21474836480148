<template>
  <div id="app" :class="dynamicAppClassName">
    <loading :active.sync="pageStore.getLoadingState" :can-cancel="false" :is-full-page="true" />

    <template v-if="isMainApp">

      <chat-drawer />

      <router-view v-if="loginLoaded && isGlobalRoute" />

      <header v-if="!isGlobalRoute">
        <Header v-if="isLoggedIn && hasCurrentBrand" />
        <PublicHeader v-else />
      </header>
      <main v-if="!isGlobalRoute">
        <div class="d-flex justify-content-center align-item-stretch w-100">
          <Sidebar />
          <div :class="computedContentClass">
            <keep-alive>
              <router-view v-if="loginLoaded && !isGlobalRoute" />
            </keep-alive>
          </div>
        </div>
      </main>

      <div id="saveBar" ref="saveBar" v-show="changesNotSaved && showSaveBar" v-if="!isGlobalRoute">
        <div class="row d-flex">
          <div class="col d-flex justify-content-between align-items-center">
            {{ $t("savebar.unsavedchanges") }}
            <b-button variant="primary" size="sm" style="margin-left: 30px" @click.prevent="saveChanges" :disabled="computedIsSavingDiabled">
              {{ $t("savebar.buttonsave") }}
            </b-button>
          </div>
        </div>
      </div>

      <Footer v-if="!isGlobalRoute" />

    </template>
    <template v-else>
      <keep-alive>
        <router-view />
      </keep-alive>
    </template>
  </div>
</template>

<script>
import Vue, { computed } from 'vue'
import Config from './config'
import Api from './modules/Api'
import ApiService from './modules/Api_Service'
import ApiStripe from './modules/Api_Stripe'
import Tour from './modules/Tour'

import ChatDrawer from './components/3.0/ChatDrawer'
import Header from './components/3.0/Header'
import PublicHeader from './components/3.0/PublicHeader'

import Footer from './components/Footer'
import Sidebar from './components/Sidebar'

import VueSweetalert2 from 'vue-sweetalert2'

import Loading from 'vue-loading-overlay'

import { Settings as LuxonSettings } from 'luxon'

import getSlug from 'speakingurl'

import ApiSql from '@/modules/Api_Sql'
import isEmail from 'validator/lib/isEmail'

import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/de'
import 'vue2-datepicker/locale/en'

import {
  setDocumentDirectionPerLocale,
  setDocumentLang,
  setDocumentTitle
} from '@/modules/i18n/document'
import { useBrandStore } from '@/stores/brandStore'
import { usePageStore } from '@/stores/pageStore'
import { useUserStore } from '@/stores/userStore'
import moment from 'moment'
import ApiStats from './modules/Api_Stats'

Vue.use(VueSweetalert2)
Vue.use(Loading)
LuxonSettings.defaultLocale = 'de'

const _Api = new Api()
const _ApiStripe = new ApiStripe()
const _ApiService = new ApiService()
const _ApiMedia = new Api(Config.MEDIA_API_URL)
const _ApiSql = new ApiSql()
const _ApiStats = new ApiStats()

const appPreloads = [
  'countries',
  'provinces',
  'mainsectors',
  'sectors',
  'currencies',
  'providedmanagementassistances',
  'providedfinancialaids',
  'providedsupports',
  'providedtrainings',
  'candidateproperties',
  'candidateprevknowledges',
  'partnermainactivities',
  'partnertargetgroups',
  'brandroles',
  'companyroles',
  'salutations',
  'titles',
  'modules',
  'articletypes',
  'specialsectorgroups'
]

const getEmptyPreloads = () => {
  const emptyPreloads = {}
  appPreloads.forEach((preloadName) => {
    emptyPreloads[preloadName] = []
  })
  return emptyPreloads
}

export default {
  name: 'App',
  components: {
    Header,
    PublicHeader,
    Footer,
    Sidebar,
    Loading,
    ChatDrawer
  },
  provide () {
    return {
      changeBrand: this.changeBrand,
      currentBrand: computed(() => this.currentBrand),
      hasCurrentBrand: computed(() => this.hasCurrentBrand),
      API: this.API,
      APIStripe: this.APIStripe,
      APIMedia: this.APIMedia,
      APIService: this.APIService,
      APIStats: this.APIStats,
      doLogout: () => {
        this.doLogout(true)
      },
      GoogleMap: this.GoogleMap
    }
  },
  data: function () {
    return {
      availableBrands: [],
      currentBrand: null,
      currentBrandSave: null,
      currentMembership: null,
      currentContact: null,
      rpsBrand: null,
      messestand: null,
      preloads: getEmptyPreloads(),
      preloaded: false,
      login: null,
      loginLoaded: false,
      shortlyRegistered: false,
      shortlyRegisteredBrand: false,
      navIsOpen: true,
      specials: {
        SystemPresentationLength: null
      },
      unsavedChanges: [],
      showSaveSuccessPopup: true,
      showSaveBar: true,
      showPageLoadingOnSave: true,
      showUnloadPrompt: true,
      saveBrandInProgress: false,
      loadBrandDataAfterSaveBrand: true,
      rewriteDefaultValuesOnLoadBrand: true,
      membershipTestimonials: [],
      chatbotTestimonials: [],
      Tour: null,
      hiddenElements: {},
      pflichtfeldCounter: -1,
      hideHeader: false,
      similarBrands: [],
      usetifulIsLoaded: false,
      pageStore: usePageStore(),
      brandStore: useBrandStore(),
      userStore: useUserStore(),
      isSaveDisabled: false,
      GoogleMap: {
        map: null,
        geocoder: null,
        loaded: null,
        circle: null
      }
    }
  },
  computed: {
    isStripeCustomer () {
      return (
        this.currentBrand != null &&
        this.currentBrand.StripeCustomerID !== null &&
        this.currentMembership != null &&
        this.currentMembership.Checkout !== null
      )
    },
    computedIsSavingDiabled () {
      if (this.isSaveDisabled) return true
      return false
    },
    isGlobalRoute () {
      const meta = this.$route.meta
      if (typeof meta.global !== 'undefined') {
        if (meta.global === true) {
          return true
        }
      }
      return false
    },
    isMainApp () {
      const meta = this.$route.meta
      if (typeof meta.mainApp !== 'undefined') {
        if (meta.mainApp === false) {
          return false
        }
      }
      return true
    },
    changesNotSaved () {
      return this.unsavedChanges.length > 0
    },
    computedContentClass () {
      const currentRouteSlug = getSlug(this.$route.path)
      return `mb-main-content-area mb-route-${currentRouteSlug}`.trim()
    },
    membershipTypes () {
      return [
        { MembershipTypeID: 0, Caption: this.$t('membershiptype.0') },
        { MembershipTypeID: 1, Caption: this.$t('membershiptype.1') },
        { MembershipTypeID: 2, Caption: this.$t('membershiptype.2') },
        { MembershipTypeID: 3, Caption: this.$t('membershiptype.3') }
      ]
    },
    membershipType () {
      if (this.currentBrand == null) return
      const currentMembershipID = parseInt(this.currentBrand.Membership)
      return this.membershipTypes
        .filter(function (elem) {
          return elem.MembershipTypeID === currentMembershipID
        })
        .shift()
    },
    isActiveMember () {
      return this.membershipType
        ? this.membershipType.MembershipTypeID === 3
        : false
    },
    isAdministrator () {
      if (this.login === null) return false
      // 2020-09-29: addeed SysRoleID 6 for FP-Team to be treated as Admin
      return this.login.user.SysRoleID <= 2 || this.login.user.SysRoleID === 6
    },
    hasCurrentBrand: function () {
      if (this.currentBrand === null) return false
      return typeof this.currentBrand.BrandID !== 'undefined'
    },
    hasMessestand: function () {
      return this.messestand !== null
    },
    API: function () {
      return _Api
    },
    APIStripe: function () {
      return _ApiStripe
    },
    APIMedia: function () {
      return _ApiMedia
    },
    APIService: function () {
      return _ApiService
    },
    APIStats: function () {
      return _ApiStats
    },
    dynamicAppClassName: function () {
      const className = [
        this.isLoggedIn ? 'mb-area' : 'mb-login',
        `app-route-${this.$route.name != null ? this.$route.name.toLowerCase() : ''
        }`,
        'mb-nav-open-' + this.navIsOpen,
        this.hideHeader ? 'mb-hide-header' : ''
      ]
      return className.join(' ')
    },
    isLoggedIn: function () {
      return this.login !== null
    },
    currentBrandLogo: function () {
      return this.getImage(this.currentBrand.Logo, this.currentBrand.LogoID)
    },
    currentBrandSlug: function () {
      if (!this.currentBrand) return ''

      const currentBrandName = this.currentBrand.Name
      const currentBrandID = this.currentBrand.BrandID
      return (
        getSlug(currentBrandName, {
          lang: 'de',
          custom: {
            '+': ''
          }
        }) +
        '-m-' +
        currentBrandID
      )
    },
    hasActiveMembership: function () {
      return (
        this.currentMembership != null &&
        this.currentMembership.Memberships != null &&
        this.currentMembership.ScheduledMemberships != null &&
        this.currentMembership.Status === 'Mitglied' && // eine Mitgliedschaft ist nur dann aktiv, wenn keine geplant ist. Dies ist von der Schnittstelle so vorgesehen aus Kompatibilitätsgründen.
        ((this.currentMembership.Memberships.DE &&
          !this.currentMembership.ScheduledMemberships.DE) ||
          (this.currentMembership.Memberships.AT &&
            !this.currentMembership.ScheduledMemberships.AT) ||
          (this.currentMembership.Memberships.CH &&
            !this.currentMembership.ScheduledMemberships.CH))
      )
    },
    membershipPlaned: function () {
      return (
        this.currentMembership != null &&
        this.currentMembership.ScheduledMemberships != null &&
        (this.currentMembership.ScheduledMemberships.DE ||
          this.currentMembership.ScheduledMemberships.AT ||
          this.currentMembership.ScheduledMemberships.CH)
      )
    },
    isDiscountAvailable () {
      if (this.currentMembership) {
        return this.currentMembership.discountAvailable
      }
      return false
    },
    isMembershipUpgradeAvailable () {
      if (
        this.currentMembership !== null &&
        typeof this.currentMembership.StripeSubscriptions !== 'undefined'
      ) {
        const currentMemberships =
          this.currentMembership.StripeSubscriptions.filter((sub) => {
            return sub.type === 'membership' && sub.upgrade !== null
          })

        if (currentMemberships.length === 0) return null

        let currentUpgrade = null

        currentMemberships.forEach((sub) => {
          if (currentUpgrade === null) {
            currentUpgrade = Object.assign({}, sub)
            return
          }
          if (sub.upgrade.amount > currentUpgrade.upgrade.amount) {
            currentUpgrade = Object.assign({}, sub)
          }
        })

        return currentUpgrade.upgrade
      }

      return null
    },
    computedSpecialSector: {
      get: function () {
        return this.getSelectedSpecialSectorForDropDown(
          this.currentBrand.SpecialSectorID
        )
      },
      set: function (newValue = []) {
        this.currentBrand.SpecialSectorID = newValue.SpecialSectorID
        this.registerChangedContent(this.saveBrand)
        this.checkPflichtfeldStatus()
      }
    }
  },
  watch: {
    $route () {
      this.loadBodyClass()
    }
  },
  mounted: async function () {
    if (!this.GoogleMap.loaded) {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${Config.GOOGLE_MAPS_API_KEY}`
      script.async = true
      script.defer = true
      document.body.appendChild(script)
      this.GoogleMap.loaded = true
    }

    this.loadFromCookie()
    this.loadBodyClass()

    if (this.isLoggedIn) {
      this.authCheck()
        .then(async () => {
          await this.getCurrentContact()
          this.createJivoChat()
          this.createUsetiful()
          this.preload()
            .then(() => {
              const savedCurrentBrandID = this.getCurrentBrandIDFromCookie()
              this.setJivoChatUser()

              if (this.$route.name === 'new-fair-form') return
              if (this.$route.name === 'MarkenauswahlProfileEdit') return

              this.loadAvailableBrandsIfEmpty().then(() => {
                if (savedCurrentBrandID) {
                  this.changeBrand(savedCurrentBrandID).then(() => {
                    if (
                      getSlug(this.$route.path) !== 'markeanlegendetails-all' &&
                      this.checkPflichtfeldStatus(false, false, true).counter >
                      0
                    ) {
                      this.$router.push(
                        '/markeanlegendetails#' + savedCurrentBrandID
                      )
                    }
                  })
                }
              })
            })
            .catch(this.doLogout)
        })
        .catch(() => {
          this.doLogout(false)
        })
    } else {
      if (!this.$route.path.startsWith('/login')) {
        this.userStore.logout()
        this.$router.push('/login')
      }
    }

    this.setMembershipTestimonials()
    this.setChatbotTestimonials()
    this.$watch(
      '$i18n.locale',
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return
        }
        setDocumentLang(newLocale)
        setDocumentDirectionPerLocale(newLocale)
        setDocumentTitle(this.$t('app.title'))
        Object.keys(this.preloads).forEach((preloadName) => {
          this.translatePreload(preloadName)
        })
        if (this.isStripeCustomer) {
          this.setStripeLanguage(newLocale)
        }
      },
      { immediate: true }
    )
    if (this.$route.query.ln != null) this.$i18n.locale = this.$route.query.ln
  },
  created: function () {
    window.addEventListener('beforeunload', (event) => {
      if (this.changesNotSaved === false) {
        return
      }
      event.returnValue = this.$t('popupmessage.warn.changesnotsaved')
    })
  },
  methods: {
    disableSave () {
      this.isSaveDisabled = true
    },
    enableSave () {
      this.isSaveDisabled = false
    },
    setStripeLanguage (newLang) {
      const loginFromStorage = localStorage.getItem('FPMB_Login')
      const login = loginFromStorage ? JSON.parse(loginFromStorage) : {}
      const userId = login.user.UserID
      const username = login.user.Name

      const stripeSaveData = {
        StripeCustomerID: this.currentBrand.StripeCustomerID,
        BrandID: this.currentBrand.BrandID,
        UserID: userId,
        Username: username,
        token: login.token,
        NewLanguage: newLang
      }

      _ApiStripe._post('customer/language/set', stripeSaveData)
    },
    createUsetiful () {
      if (!this.usetifulIsLoaded && this.isLoggedIn) {
        this.usetifulIsLoaded = true
        this.createScriptInHead(
          'https://www.usetiful.com/dist/usetiful.js',
          'usetifulScript',
          { token: '32f23f061eccd7d5e50061c1f3380140' }
        )
      }
    },
    reinitializeUsetiful () {
      if (!this.usetifulIsLoaded) return
      // eslint-disable-next-line no-undef
      USETIFUL.reinitialize()
    },
    createJivoChat () {
      try {
        if (this.isAdministrator) return
        const destroyed = this.destroyJivoChat()
        let jivoID = 'ySFEtzXXsO' // DE
        if (this.$i18n.locale !== 'de') jivoID = 'dq6PP09zEt'
        if (this.isLoggedIn) {
          this.createScriptInHead('//code-eu1.jivosite.com/widget/' + jivoID)
        }
        if (destroyed && this.isLoggedIn) {
          // eslint-disable-next-line no-undef, camelcase
          jivo_config.widget_id = jivoID
          // eslint-disable-next-line no-undef
          jivo_init()
        }
      } catch (error) {
        console.error(error)
      }
    },
    setJivoChatUser () {
      if (!this.isLoggedIn || this.isAdministrator) return
      /* eslint-disable */
      try {
        if (typeof jivo_api != "undefined" && this.currentContact != null) {
          jivo_api.setContactInfo({
            name:
              this.currentContact.Firstname +
              " " +
              this.currentContact.Lastname,
            email: this.currentContact.Mail,
            phone: this.currentContact.Phone,
          });
        } else {
          setTimeout(() => {
            this.setJivoChatUser();
          }, 10000);
        }
      } catch (error) {
        console.error(error);
        setTimeout(() => {
          this.setJivoChatUser();
        }, 10000);
      }
      /* eslint-enable */
    },
    destroyJivoChat () {
      // eslint-disable-next-line camelcase
      if (typeof jivo_destroy === 'function') {
        // eslint-disable-next-line no-undef
        jivo_destroy()
        return true
      }
      return false
    },
    setJivoBrandData () {
      try {
        let brandName = ''
        let brandID = ''
        if (this.hasCurrentBrand) {
          brandName = this.currentBrand.Name
          brandID = this.currentBrand.BrandID
        }
        // eslint-disable-next-line no-undef, camelcase
        jivo_api.setCustomData([
          {
            brand: brandName,
            brandid: brandID
          }
        ])
      } catch (error) {
        console.error(error)
      }
    },
    createScriptInHead (src, id = null, dataset = null) {
      const externalScript = document.createElement('script')
      externalScript.setAttribute('src', src)
      externalScript.async = true
      if (id != null) externalScript.setAttribute('id', id)
      if (dataset != null) {
        Object.keys(dataset).forEach((key, index) => {
          externalScript.dataset[key] = dataset[key]
        })
      }
      document.head.appendChild(externalScript)
    },
    getUnloadPrompt () {
      if (!this.showUnloadPrompt) return new Promise()
      return this.$swal({
        title: this.$t('popupmessage.warn.attentiontitle'),
        text: this.$t('popupmessage.warn.changesnotsaved'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('button.save'),
        cancelButtonText: this.$t('button.discard')
      })
    },
    resetRegisteredChanges () {
      this.unsavedChanges = []
    },
    saveChanges (checkPflichtfelder = true) {
      /* soll vorerst nicht mehr geprüft werden beim Speichern
      if (checkPflichtfelder && this.checkPflichtfeldStatus(false, true).counter > 0) {
        return this.$swal({
          title: this.$t('popupmessage.warn.attentiontitle'),
          html: this.$t('popupmessage.warn.fillmandantory'),
          type: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: this.$t('button.ok')
        })
      } */
      this.unsavedChanges.forEach((callback) => {
        callback()
      })
      this.unsavedChanges = []
    },
    registerChangedContent (saveCallback) {
      if (this.currentBrand == null) return
      let alreadyExists = false
      this.unsavedChanges.forEach((existentSaveCallback) => {
        if (saveCallback === existentSaveCallback) {
          alreadyExists = true
        }
      })

      if (alreadyExists === false) {
        this.unsavedChanges.push(saveCallback)
      }
    },
    loadAvailableBrandsIfEmpty: function () {
      return new Promise((resolve, reject) => {
        if (
          this.isLoggedIn &&
          (this.availableBrands == null || this.availableBrands.length === 0)
        ) {
          this.loadAvailableBrands()
            .then((result) => {
              resolve(result)
            })
            .catch(() => {
              resolve([])
            })
        } else {
          resolve(this.availableBrands)
        }
      })
    },
    loadAvailableBrands: async function () {
      if (this.pageStore.isLoading('loadAvailableBrands')) {
        return false
      }

      this.pageStore.startLoading('loadAvailableBrands')

      try {
        if (this.isAdministrator) {
          return
        }
        if (this.login === null) {
          return
        }

        const currentContactID = this.userStore.getCurrentContactID
        const currentUsername = this.userStore.getCurrentUsername

        const availableBrandIdsResponse = await _ApiSql._post('loadAvailableBrands', {
          ContactID: currentContactID,
          Username: currentUsername
        })

        const availableBrandIDs = availableBrandIdsResponse.body.brandIds

        if (availableBrandIDs.length === 0) {
          return false
        }

        const availableBrandsResponse = await _Api._post('brandfilterviewrs/', {
          FullSubEntities: true,
          ResFrom: 1,
          ResTo: 250,
          filter: [
            {
              field: 'BrandID',
              operator: 'IN',
              value: availableBrandIDs.join(',')
            }
          ],
          OrderBy: null
        })

        const availableBrands = availableBrandsResponse.body.Result.Data.slice(0)

        if (availableBrands.length === 0) {
          return false
        }

        this.setBrands(availableBrands)
      } catch (error) {
        console.error('error', error)
      } finally {
        this.pageStore.stopLoading('loadAvailableBrands')
      }
    },
    loadAvailableBrandsOld: function () {
      if (this.pageStore.isLoading('loadAvailableBrands')) return Promise.resolve()
      this.pageStore.startLoading('loadAvailableBrands')

      return new Promise((resolve, reject) => {
        if (this.isAdministrator) {
          return reject(
            new Error('User is Administrator. Not loading Brands.')
          )
        }
        if (this.login === null) {
          return reject(new Error('User is not logged in!'))
        }

        _Api
          ._post('contactbrandroles/', {
            ResFrom: 1,
            ResTo: 1000,
            FullSubEntities: true,
            Filter: [
              {
                Field: 'ContactID',
                Operator: 'eq',
                Value: this.userStore.getCurrentContactID
              }
            ]
          })
          .then((response) => {
            const result = response.body.Result.Data
            const brandIDs = []

            result.forEach((contactBrandRole) => {
              brandIDs.push(contactBrandRole.BrandID)
            })

            const BrandIDsToFilter = [...new Set(brandIDs)]

            if (BrandIDsToFilter.length > 0) {
              _Api
                ._post('brandfilterviewrs/', {
                  FullSubEntities: true,
                  ResFrom: 1,
                  ResTo: 250,
                  filter: [
                    {
                      field: 'BrandID',
                      operator: 'IN',
                      value: BrandIDsToFilter.join(',')
                    }
                  ],
                  OrderBy: null
                })
                .then((response) => {
                  const availableBrands = response.body.Result.Data.slice(0)

                  if (availableBrands.length) {
                    this.setBrands(availableBrands)
                  }
                  resolve(response)
                })
                .catch(reject)
            } else {
              reject(new Error('No Brands to filter.', response))
            }
          })
      })
        .catch((error) => {
          console.error('Error at loading available Brands...', error)
        })
        .finally(() => {
          this.pageStore.stopLoading('loadAvailableBrands')
        })
    },
    setBrands (availableBrands) {
      this.availableBrands = availableBrands.slice(0).filter((Brand) => {
        if (
          Brand.Status == null ||
          Brand.Status.Caption === 'Archiviert' ||
          Brand.Status.Caption === 'Temporär deaktiviert'
        ) {
          return false
        }
        if (Brand.CompanyID === null) return false
        return true
      })
    },
    getSlug (slug, options) {
      return getSlug(slug, options)
    },
    async doLogin (loginData, brandID) {
      this.pageStore.startLoading('doLogin')

      try {
        const tokenData = await _Api.login(loginData)

        this.createJivoChat()
        this.login = tokenData.login
        this.createUsetiful()
        localStorage.setItem('FPMB_Login', JSON.stringify(this.login))

        await this.preload()

        // const SearchLead = JSON.parse(localStorage.getItem('_DELAYED_LEADID'))
        // if (SearchLead !== null) {
        //   this.$router.push(`/anfragen/${SearchLead.BrandID}/${SearchLead.LeadID}`)
        // } else {
        //   throw new Error('No LeadID found.')
        // }

        await this.getCurrentContact()
        this.loadAvailableBrands()

        this.setJivoChatUser()

        if (brandID != null) {
          await this.changeBrand(brandID)

          const hash = window.location.hash
          if (hash != null && hash !== '') {
            this.$router.push(hash.replace('#', ''))
          } else {
            this.$router.push('/markeanlegendetails/all#' + brandID)
          }
        } else {
          if (this.$route.name !== 'Markenauswahl') {
            this.$router.push('/markenauswahl')
          }
        }

        // .then(() => {

        // })
        // .catch((error) => {
        //   console.error(error)
        //   this.routeToMandantoryOrThisPage('/markenauswahl')
        //   resolve()
        // })
      } catch (error) {
        console.error('Error at Login', error)
        // this.routeToMandantoryOrThisPage('/markenauswahl')
        throw error
      } finally {
        this.pageStore.stopLoading('doLogin')
      }
    },
    routeToMandantoryOrThisPage (routeToPage) {
      if (this.availableBrands.length > 1) {
        this.$router.push(routeToPage)
      } else if (
        this.availableBrands.length === 1 &&
        this.availableBrands[0].PflichfeldStatusID === 0
      ) {
        this.$router.push(
          '/markeanlegendetails#' + this.availableBrands[0].BrandID
        )
      } else if (this.availableBrands.length === 0 && !this.isAdministrator) {
        this.$router.push('/markeanlegen')
      } else {
        this.$router.push(routeToPage)
      }
    },
    isValidCurrentBrand () {
      return (
        this.currentBrand != null && this.currentBrand.PflichfeldStatusID >= 1
      )
    },
    doLogout (showNotification = true) {
      this.login = null
      this.currentBrand = null
      this.currentBrandSave = null
      this.currentContact = null
      this.currentMembership = null
      this.availableBrands = []
      this.shortlyRegistered = false
      this.shortlyRegisteredBrand = false

      // reset preloads
      this.preloaded = false
      this.preloads = getEmptyPreloads()

      localStorage.removeItem('FPMB_Login')
      localStorage.removeItem('FPMB_CurrentBrandID')
      localStorage.removeItem('_DELAYED_LEADID')
      this.$router.push('/login').then(() => {
        if (showNotification === true) {
          this.$swal(
            this.$t('menue.logout'),
            this.$t('popupmessage.success.logoutmessage'),
            'success'
          )
        }
      })
      this.destroyJivoChat()
    },
    loadFromCookie () {
      const loadedLoginData = localStorage.getItem('FPMB_Login')
      this.login = loadedLoginData ? JSON.parse(loadedLoginData) : null
      this.loginLoaded = true
    },
    getCurrentBrandIDFromCookie () {
      const loadedData = localStorage.getItem('FPMB_CurrentBrandID')
      return loadedData ? parseInt(loadedData) : false
    },
    getCountryNameByID (id) {
      const country = this.getCountryByID(id)
      return country === '' ? '' : country.Caption
    },
    getCountryByID (id) {
      const searchCountryID = parseInt(id)
      if (!(searchCountryID > 0)) return ''
      const foundCountres = this.preloads.countries.filter((country) => {
        return country.CountryID === searchCountryID
      })
      return foundCountres.length === 1 ? foundCountres[0] : ''
    },
    getCountryListStartWithDeAtCh (addCountryLic = null) {
      const startCountries = ['DE', 'AT', 'CH']
      let availableCountries = [
        1, 2, 3, 4, 5, 8, 9, 11, 12, 14, 15, 21, 25, 27, 32, 34, 36, 38, 45, 49,
        50, 51, 54, 57, 58, 59, 60, 61, 113, 166, 187
      ]
      let additionalCountries = []

      if (addCountryLic != null) {
        const addCountries = this.currentBrand.CountryLicenses
        if (addCountries != null) {
          additionalCountries = addCountries
            .filter((license) => {
              return license[addCountryLic] === 1
            })
            .map((license) => {
              return license.CountryID
            })
        }
      }
      availableCountries = [
        ...new Set([...availableCountries, ...additionalCountries])
      ] // concat & remove duplicate
      const firstCountries = this.preloads.countries.filter((country) => {
        return startCountries.includes(country.Code)
      })
      const lastCountries = this.preloads.countries.filter((country) => {
        return (
          !startCountries.includes(country.Code) &&
          availableCountries.includes(country.CountryID)
        )
      })
      const allCountries = [...firstCountries, ...lastCountries]
      allCountries.forEach((country) => {
        if (country.Code !== '') {
          const transKey = 'country.' + country.Code.toLowerCase()
          const countryName = this.$t(transKey)
          if (countryName !== transKey) country.Caption = countryName
        }
      })
      return allCountries
    },
    getCountryByCountryCode (code) {
      const country = this.preloads.countries.filter((country) => {
        return country.Code === code
      })
      if (country.length > 0) return country[0]
      return {}
    },
    loadBodyClass () {
      const el = document.body
      const currentRoute = this.$route
      el.className = ''
      if (
        currentRoute.meta.bodyClass != null &&
        (currentRoute.meta.bodyClass.indexOf('mb-area_') > -1 ||
          currentRoute.meta.bodyClass.indexOf('mb-login_') > -1)
      ) {
        const classes = currentRoute.meta.bodyClass.split('_')
        classes.forEach((thisClass) => {
          el.classList.add(thisClass)
        })
      } else if (typeof currentRoute.meta.bodyClass !== 'undefined') {
        el.classList.add('mb-login')
        el.classList.add(currentRoute.meta.bodyClass)
      } else {
        el.classList.add('mb-area')
      }

      const isGlobalRoute =
        typeof currentRoute.meta.global !== 'undefined' &&
        currentRoute.meta.global === true
      if (isGlobalRoute) {
        el.classList.remove('mb-area')
      }

      if (typeof this.$route.meta.sidebar !== 'undefined') {
        this.navIsOpen = this.$route.meta.sidebar
      } else {
        this.navIsOpen = true
      }
    },
    async authCheck () {
      try {
        const authResponse = await _Api._get('version/')

        if (
          typeof authResponse.body.Errors !== 'undefined' &&
          authResponse.body.Errors.length === 0
        ) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
    preload () {
      return new Promise((resolve, reject) => {
        if (this.preloaded === true) return resolve()

        Promise.all(
          appPreloads.map((preloadName) => {
            let fullSubEntities = false
            if (preloadName === 'specialsectorgroups') fullSubEntities = true
            return _Api
              ._post(`${preloadName}/`, {
                ResFrom: 1,
                ResTo: 369,
                FullSubEntities: fullSubEntities
              })
              .then((response) => {
                this.preloads[preloadName] = response.body.Result.Data
                if (
                  preloadName === 'providedsupports' ||
                  preloadName === 'providedfinancialaids' ||
                  preloadName === 'providedtrainings' ||
                  preloadName === 'providedmanagementassistances'
                ) {
                  // "Leistung wird nicht angeboten" nach ganz oben setzen
                  const noSupportCaption = this.$t(
                    'providedmanagementassistances.20',
                    'de'
                  )
                  const noSupportCaptionEn = this.$t(
                    'providedmanagementassistances.20',
                    'en'
                  )
                  const noSupportItem = this.preloads[preloadName].filter(
                    (item) => {
                      return (
                        item.Caption === noSupportCaption ||
                        item.Caption === noSupportCaptionEn
                      )
                    }
                  )
                  this.preloads[preloadName] = this.preloads[
                    preloadName
                  ].filter((item) => {
                    return (
                      item.Caption !== noSupportCaption &&
                      item.Caption !== noSupportCaptionEn
                    )
                  })
                  if (noSupportItem.length > 0) {
                    this.preloads[preloadName].unshift(noSupportItem[0])
                  }
                }

                // Translation
                this.translatePreload(preloadName)
              })
          })
        )
          .then(() => {
            this.preloaded = true
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    translatePreload (preloadName, translateObject = null) {
      try {
        if (typeof this.preloads !== 'object') return
        let key = ''
        if (preloadName === 'provinces') key = 'Abbreviation'
        if (preloadName === 'mainsectors') key = 'MainSectorID'
        if (preloadName === 'sectors') key = 'SectorID'
        if (preloadName === 'providedmanagementassistances') {
          key = 'ProvidedManagementAssistanceID'
        }
        if (preloadName === 'providedfinancialaids') {
          key = 'ProvidedFinancialAidID'
        }
        if (preloadName === 'providedsupports') key = 'ProvidedSupportID'
        if (preloadName === 'providedtrainings') key = 'ProvidedTrainingID'
        if (preloadName === 'candidateproperties') {
          key = 'CandidatePropertiesID'
        }
        if (preloadName === 'candidateprevknowledges') {
          key = 'CandidatePrevKnowledgesID'
        }
        if (preloadName === 'partnermainactivities') {
          key = 'PartnerMainActivitiesID'
        }
        if (preloadName === 'partnertargetgroups') key = 'PartnerTargetGroupID'
        if (preloadName === 'companyroles') key = 'CompanyRoleID'
        if (preloadName === 'salutations') key = 'SalutationID'
        if (preloadName === 'specialsectorgroups') key = 'SpecialSectorGroupID'
        if (preloadName === 'specialsectors') key = 'SpecialSectorID'

        if (key === '') return
        translateObject = translateObject || this.preloads[preloadName]
        translateObject.forEach((element) => {
          try {
            if (element == null) return
            let keyValue = element[key]
            if (typeof keyValue === 'string') keyValue = keyValue.toLowerCase()
            const transKey = preloadName + '.' + keyValue
            const countryName = this.$t(transKey)
            if (countryName !== transKey) element.Caption = countryName
            if (preloadName === 'specialsectorgroups') {
              this.translatePreload('specialsectors', element.SpecialSectors)
            }
          } catch (error) {
            console.error(error, preloadName, element, key)
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    showSaveSuccess (loader) {
      if (!this.showSaveSuccessPopup) return
      this.$swal(
        this.$t('popupmessage.success.savedtitle'),
        this.$t('popupmessage.success.changessaved'),
        'success'
      )
      if (loader) {
        loader.hide()
      }
    },
    showSaveError (loader) {
      this.$swal(
        this.$t('popupmessage.warn.attentiontitle'),
        this.$t('popupmessage.error.saveerror') +
        '<br><br>' +
        this.$t('popupmessage.error.errorreported'),
        'error'
      )
      if (loader != null) loader.hide()
    },
    saveBrand () {
      return new Promise((resolve, reject) => {
        const changes = {}
        this.currentBrand.Homepage = this.getValidHomapageUrl(
          this.currentBrand.Homepage
        )
        Object.keys(this.currentBrandSave).forEach((key) => {
          if (this.currentBrandSave[key] !== this.currentBrand[key]) {
            changes[key] = this.currentBrand[key]
            if (this.currentBrand[key] === null) {
              changes.NullFields = changes.NullFields || []
              changes.NullFields.push(key)
            }
          }
        })

        if (Object.keys(changes).length === 0) {
          return resolve(this.showSaveSuccess())
        }

        changes.BrandID = this.currentBrand.BrandID

        delete changes.InterestedContact
        delete changes.OperationUnits

        delete changes.PrimaryContactID
        delete changes.InterestedContactID

        changes.NullFields = changes.NullFields || []
        if (
          typeof this.currentBrand.CondInvestSumType !== 'undefined' &&
          this.currentBrand.CondInvestSumType !== 1
        ) {
          this.currentBrand.CondInvestSumTo = null
          changes.NullFields.push('CondInvestSumTo')
        }
        if (
          typeof this.currentBrand.CondEntryFeeType !== 'undefined' &&
          this.currentBrand.CondEntryFeeType !== 1
        ) {
          this.currentBrand.CondEntryFeeTo = null
          changes.NullFields.push('CondEntryFeeTo')
        }
        if (
          typeof this.currentBrand.CondLiquidAssetsType !== 'undefined' &&
          this.currentBrand.CondLiquidAssetsType !== 1
        ) {
          this.currentBrand.CondLiquidAssetsTo = null
          changes.NullFields.push('CondLiquidAssetsTo')
        }
        if (
          typeof this.currentBrand.CondInitialCapitalType !== 'undefined' &&
          this.currentBrand.CondInitialCapitalType !== 1
        ) {
          this.currentBrand.CondInitialCapitalTo = null
          changes.NullFields.push('CondInitialCapitalTo')
        }
        if (
          typeof this.currentBrand.CondRequiredCapitalType !== 'undefined' &&
          this.currentBrand.CondRequiredCapitalType !== 1
        ) {
          this.currentBrand.CondRequiredCapitalTo = null
          changes.NullFields.push('CondRequiredCapitalTo')
        }

        if (
          typeof this.currentBrand.CondInvestSumType !== 'undefined' &&
          this.currentBrand.CondInvestSumType === 0
        ) {
          this.currentBrand.CondInvestSumFrom = null
          changes.NullFields.push('CondInvestSumFrom')
        }
        if (
          typeof this.currentBrand.CondEntryFeeType !== 'undefined' &&
          this.currentBrand.CondEntryFeeType === 0
        ) {
          this.currentBrand.CondEntryFeeFrom = null
          changes.NullFields.push('CondEntryFeeFrom')
        }
        if (
          typeof this.currentBrand.CondLiquidAssetsType !== 'undefined' &&
          this.currentBrand.CondLiquidAssetsType === 0
        ) {
          this.currentBrand.CondLiquidAssetsFrom = null
          changes.NullFields.push('CondLiquidAssetsFrom')
        }
        if (
          typeof this.currentBrand.CondInitialCapitalType !== 'undefined' &&
          this.currentBrand.CondInitialCapitalType === 0
        ) {
          this.currentBrand.CondInitialCapitalFrom = null
          changes.NullFields.push('CondInitialCapitalFrom')
        }
        if (
          typeof this.currentBrand.CondRequiredCapitalType !== 'undefined' &&
          this.currentBrand.CondRequiredCapitalType === 0
        ) {
          this.currentBrand.CondRequiredCapitalFrom = null
          changes.NullFields.push('CondRequiredCapitalFrom')
        }

        if (
          typeof this.currentBrand.ComParamAssocMemberOther !== 'undefined' &&
          this.currentBrand.ComParamAssocMemberOther === 0
        ) {
          this.currentBrand.ComParamAssocMemberOther = null
          changes.NullFields.push('ComParamAssocMemberOther')
        }
        if (
          typeof this.currentBrand.ComParamMemberOther !== 'undefined' &&
          this.currentBrand.ComParamMemberOther === 0
        ) {
          this.currentBrand.ComParamMemberOther = null
          changes.NullFields.push('ComParamMemberOther')
        }

        const loader = {
          hide: function () { }
        }
        if (this.showPageLoadingOnSave) {
          // loader = this.$loading.show({
          //   canCancel: false
          // })
        }
        this.saveBrandInProgress = true

        _Api
          ._put('brand/', changes)
          .then((response) => {
            const resultString = response.body.Result.Result

            if (resultString === 'Succeeded') {
              if (this.loadBrandDataAfterSaveBrand) {
                this.loadBrand(changes.BrandID)
                  .then(() => {
                    this.showSaveSuccess(loader)
                    this.saveBrandInProgress = false
                    this.unsavedChanges = []
                    resolve(response)
                  })
                  .catch(reject)
              } else {
                this.showSaveSuccess(loader)
                this.saveBrandInProgress = false
                this.unsavedChanges = []
                resolve(response)
              }
            } else {
              this.saveBrandInProgress = false
              this.showSaveError(loader)
            }
          })
          .catch((e) => {
            this.saveBrandInProgress = false
            this.showSaveError(loader)
          })
          .finally(() => {
            loader.hide()
          })
      })
    },
    storeNewBrandID (brandID) {
      if (brandID === null) {
        this.currentBrand = null
        this.currentBrandSave = null
        this.specials.SystemPresentationLength = null
        localStorage.removeItem('FPMB_CurrentBrandID')
      } else {
        localStorage.setItem('FPMB_CurrentBrandID', brandID)
      }
    },
    updateRpsBrand (updatedData) {
      Object.keys(updatedData).forEach((key) => {
        if (this.rpsBrand[key] !== updatedData[key]) {
          this.rpsBrand[key] = updatedData[key]
        }
      })
    },
    resetBrand () {
      this.currentBrand = null
      this.currentBrandSave = null
      this.rpsBrand = null
      this.similarBrands = []
      this.brandStore.setCurrentBrand(null)
    },
    async changeBrand (brandID) {
      console.trace('changeBrand')
      try {
        this.resetBrand()

        if (brandID === false) {
          this.storeNewBrandID(null)
          return
        }

        this.pageStore.startLoading('changeBrand')

        const brand = await this.loadBrand(brandID)
        this.storeNewBrandID(brandID)

        this.$i18n.locale =
          this.currentBrand.Company.CorrespondenceLanguageID == null ||
            this.currentBrand.Company.CorrespondenceLanguageID === 2
            ? 'de'
            : 'en'

        this.setJivoBrandData()

        if (this.login.user.LoginCount <= 2) {
          _Api.scheduleMBMembershipViewMails(
            this.currentBrand.BrandID,
            this.currentContact.ContactID
          )
        }

        // Try-catch for JivoChat creation
        try {
          if (
            this.$i18n.locale !== 'de' &&
            // eslint-disable-next-line camelcase, no-undef
            jivo_config.widget_id === 'ySFEtzXXsO'
          ) {
            this.createJivoChat()
          }
        } catch (error) {
          console.error(error)
        }

        this.changeToBrandLang()

        if (this.$route.name === 'Markenauswahl') {
          this.$router.push('/dashboard')
        }

        return brand
      } catch (error) {
        console.error('Error on changeBrand', error)
        throw error
      } finally {
        this.pageStore.stopLoading('changeBrand')
      }
    },
    getMessestandModuleId () {
      return this.preloads.modules.find(
        (module) => module.Caption === 'Messestand'
      ).ModuleID
    },
    async loadBrand (brandID) {
      if (this.showPageLoadingOnSave) {
        this.pageStore.startLoading('loadBrand')
      }

      try {
        if (!this.isLoggedIn) {
          throw new Error('Not logged in.')
        }

        if (!this.isAdministrator && !this.availableBrands.some(brand => brand.BrandID === parseInt(brandID))) {
          throw new Error('No permission for brand: ' + brandID)
        }

        const response = await _Api._post('brandfilterviewrs/', {
          FullSubEntities: true,
          Filter: [
            {
              Field: 'BrandID',
              Operator: 'eq',
              Value: brandID
            }
          ]
        })

        const resultBrands = response.body.Result.Data
        if (!resultBrands.length) {
          throw new Error('Keine Marken gefunden.')
        }

        const newLoadedBrand = resultBrands.shift()

        if (this.rewriteDefaultValuesOnLoadBrand) {
          // Rewrite null values to defaults on load
          ['CondInvestSumType', 'CondEntryFeeType', 'CondInitialCapitalType', 'CondRequiredCapitalType'].forEach(attrName => {
            if (newLoadedBrand[attrName] === null) {
              newLoadedBrand[attrName] = 0
            }
          });

          ['CondContractPeriod', 'CondContractRenewal', 'CondContractRenewalFee', 'CondMinimumTurnover', 'CondInternalPurchaseType', 'CondRegularLicenceType', 'CondRegularMarketingFeeType', 'CondCommercialSpaceType', 'CondCatchmentType', 'CondExtraCharge', 'PartnerLateralEntrance', 'PartnerSmallBusiness', 'PartnerHomeOffice', 'PartnerMultipleLocations', 'ConceptHasPilotProject'].forEach(attrName => {
            if (newLoadedBrand[attrName] === null) {
              newLoadedBrand[attrName] = -1
            }
          })
        }

        this.currentBrand = { ...newLoadedBrand }
        this.currentBrandSave = { ...newLoadedBrand }
        this.currentMembership = null

        await this.getBrandMembershipValues()

        this.brandStore.setCurrentBrand(newLoadedBrand)

        const allPromises = []

        allPromises.push(await this.brandStore.loadFaqData())

        // Load new fair and module if BrandModules are empty
        if (this.currentBrand.BrandModules.length === 0) {
          allPromises.push(
            (async () => {
              const fairResponse = await _Api._put('fair/', {
                FairID: -1,
                StartDate: null,
                StopDate: null
              })
              const newFairID = fairResponse.body.Result.NewID

              await _Api._put('brandmodule/', {
                BrandModuleID: -1,
                BrandID: brandID,
                ModuleID: this.getMessestandModuleId(),
                FairID: newFairID
              })

              const fairDataResponse = await _Api._post('fairs/', {
                ResFrom: 1,
                ResTo: 1,
                FullSubEntities: true,
                Filter: [
                  {
                    Field: 'FairID',
                    Operator: 'eq',
                    Value: newFairID
                  }
                ]
              })

              this.messestand = fairDataResponse.body.Result.Data.shift()
            })()
          )
        } else {
          this.messestand = { ...this.currentBrand.BrandModules[0].Fair }
        }

        // if (typeof this.messestand.FairExperiences !== 'undefined') {
        //   const fairExperiencesResponse = await _Api._post('fairexperiences/', {
        //     ResFrom: 1,
        //     ResTo: 1000,
        //     FullSubEntities: true,
        //     Filter: [
        //       {
        //         Field: 'FairID',
        //         Operator: 'eq',
        //         Value: this.messestand.FairID
        //       }
        //     ]
        //   })
        //   this.messestand.FairExperiences = fairExperiencesResponse.body.Result.Data
        // }

        // Load RPS Brand
        allPromises.push(
          (async () => {
            const rpsResponse = await _Api._post('rpsbrands/', {
              ResFrom: 1,
              ResTo: 1,
              FullSubEntities: true,
              Filter: [
                {
                  Field: 'BrandID',
                  Operator: 'eq',
                  Value: newLoadedBrand.BrandID
                }
              ]
            })

            const loadedRPSBrands = rpsResponse.body.Result.Data

            if (loadedRPSBrands.length) {
              this.rpsBrand = loadedRPSBrands.shift()
            } else {
              const newRPSResponse = await _Api._put('rpsbrand/', {
                RPSBrandID: -1,
                BrandID: newLoadedBrand.BrandID
              })

              const newRPSBrandID = newRPSResponse.body.Result.NewID

              const newRPSDataResponse = await _Api._post('rpsbrands/', {
                ResFrom: 1,
                ResTo: 1,
                FullSubEntities: true,
                Filter: [
                  {
                    Field: 'RPSBrandID',
                    Operator: 'eq',
                    Value: newRPSBrandID
                  }
                ]
              })

              const newLoadedRPSBrands = newRPSDataResponse.body.Result.Data

              if (newLoadedRPSBrands.length) {
                this.rpsBrand = newLoadedRPSBrands.shift()
              } else {
                throw new Error('No RPS Brands found.')
              }
            }
          })()
        )

        // Run all independent requests in parallel
        await Promise.all(allPromises)

        // Load System Presentation if applicable
        if (this.messestand.SystemPresentation > 0) {
          const contentResponse = await _Api._post('contents/', {
            ResFrom: 1,
            ResTo: 1,
            FullSubEntities: true,
            Filter: [
              {
                Field: 'ContentID',
                Operator: 'eq',
                Value: this.messestand.SystemPresentation
              }
            ]
          })
          this.specials.SystemPresentationLength = contentResponse.body.Result.Data[0].Body.length
        } else {
          this.specials.SystemPresentationLength = 0
        }
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.pageStore.stopLoading('loadBrand')
        _Api.setBrandContext(this.currentBrand, this.currentContact)
      }
    },

    getBrandMembershipValues () {
      return new Promise((resolve, reject) => {
        const postData = {
          BrandID: this.currentBrand.BrandID
        }

        return _ApiStripe
          ._post('getBrandMembershipValues', postData)
          .then((result) => {
            this.currentMembership = result.body
            resolve(result)
          })
          .catch(reject)
      })
    },
    getImage (image, imageID) {
      if (imageID === null || imageID === 0) {
        return require('./assets/images/brand-logo.jpg')
      }
      if (typeof image !== 'object' || image === null) {
        return require('./assets/images/brand-logo.jpg')
      }
      if (typeof image.FileID === 'undefined') {
        return require('./assets/images/brand-logo.jpg')
      }
      if (typeof image.FileID !== 'undefined') {
        return `${Config.MEDIA_API_URL}id/${image.FileID}`
      }
      return require('./assets/images/brand-logo.jpg')
    },
    getCountryIcon (brand) {
      const countryList = this.preloads.countries.filter(function (country) {
        return country.CountryID === brand.ComParamOriginCountryID
      })

      if (countryList.length) {
        const country = countryList.shift()
        const countryCode = String(country.Code).toLowerCase()

        let countrySource = ''

        try {
          countrySource = require(`./assets/images/flags/${countryCode}.svg`)
        } catch (e) {
          return countrySource
        }
        return countrySource
      }
    },
    startLoader () {
      return this.$loading.show({
        canCancel: false
      })
    },
    stopLoader (loader) {
      if (typeof loader !== 'undefined' && typeof loader.hide === 'function') {
        loader.hide()
      }
    },
    checkEmail (email) {
      return new Promise((resolve, reject) => {
        if (!isEmail(email)) {
          reject(new Error(this.$t('page.register.emailerror')))
        }

        _Api.checkEmail(email).then((result) => {
          if (!result.status) {
            reject(new Error(result.message))
          } else {
            resolve(result.status)
          }
        })
      })
    },
    giftAvailable: function () {
      return (
        typeof this.currentBrand !== 'undefined' &&
        this.currentBrand !== null &&
        this.currentBrand.TrailerPromotionAvailable === 1 &&
        this.currentBrand.Membership === 3 &&
        this.$i18n.locale === 'de'
      )
    },
    setMembershipTestimonials: function () {
      this.membershipTestimonials = [
        {
          brandname: 'datarev',
          logo: this.getImage({ FileID: 169962710 }),
          contact: {
            img: this.getImage({ FileID: 936732583 }),
            name: 'Jürgen Recha'
          },
          text: 'testimonials.members.datarev'
        },
        {
          brandname: 'FT-Club',
          logo: this.getImage({ FileID: 441451285 }),
          contact: {
            img: this.getImage({ FileID: 642170974 }),
            name: 'Jessica Mumdey'
          },
          text: 'testimonials.members.ftclub'
        },
        {
          brandname: 'Mail Boxes Etc.',
          logo: this.getImage({ FileID: 603601090 }),
          contact: {
            img: this.getImage({ FileID: 739920273 }),
            name: 'Ute Petrenko, Eik Briege, Silvia Kachel'
          },
          text: 'testimonials.members.mailboxes'
        }
      ]
    },
    setChatbotTestimonials: function () {
      this.chatbotTestimonials = [
        {
          brandname: 'Snap-on Tools',
          logo: this.getImage({ FileID: 448791152 }),
          contact: {
            img: this.getImage({ FileID: 593032922 }),
            name: 'Andreas Zander, Sales Development Manager'
          },
          text: 'testimonials.chatbot.snapontools'
        },
        {
          brandname: 'Easyfitness.club',
          logo: this.getImage({ FileID: 955304226 }),
          contact: {
            img: require('@/assets/images/testimonial/testimonial-easyfitness-merten-2.jpg'),
            name: 'Sebastian Merten, Expansionsmanager'
          },
          text: 'testimonials.chatbot.easyfitnessclub'
        },
        {
          brandname: 'PROMEDICA PLUS',
          logo: this.getImage({ FileID: 795954722 }),
          contact: {
            img: require('@/assets/images/testimonial/Profilbild_Miriam-Arnhoelter-1.jpg'),
            name: 'Miriam Arnhölter, Marketing Managerin'
          },
          text: 'testimonials.chatbot.promedicaplus'
        }
      ]
    },
    getContactByID (contactID) {
      if (contactID == null || contactID <= 0) return null
      return this.API._post('contact/', {
        ContactID: contactID,
        FullSubEntities: true
      })
    },
    getCurrentContact () {
      return new Promise((resolve, reject) => {
        if (this.currentContact != null) resolve(this.currentContact)
        this.loadFromCookie()

        if (this.login === null) this.$router.push('/')

        const username = String(this.login.user.Email)

        this.API._post('contacts/', {
          ResFrom: 1,
          ResTo: 1,
          FullSubEntities: true,
          Filter: [
            {
              Field: 'Mail',
              Operator: 'eq',
              Value: username
            }
          ],
          OrderBy: [
            {
              Field: 'ContactID',
              SortOrder: 'DESC'
            }
          ]
        })
          .then((response) => {
            const loggedInContacts = response.body.Result.Data

            if (loggedInContacts.length === 0) {
              reject(new Error('Keine Kontakte für diesen Login gefunden'))
            }

            const foundContact = loggedInContacts[loggedInContacts.length - 1]

            const userStore = useUserStore()
            userStore.setCurrentContact(foundContact)

            resolve(foundContact)
          })
          .catch(reject)
      }).then((result) => {
        this.currentContact = result
      })
    },
    startTour () {
      if (this.$route.name !== 'Dashboard') {
        this.$router.push('/dashboard')
      }
      const stepTexts = this.$t('tour')
      this.Tour = new Tour(this.membershipType, stepTexts)
      this.Tour.startTour()
    },
    checkPflichtfeldStatus (
      hideElementsIfValid = false,
      checkBrandSave = false,
      checkLength = false
    ) {
      this.hiddenElements = {}
      this.pflichtfeldCounter = 30
      let result = {
        counter: this.pflichtfeldCounter,
        hiddenElements: {},
        notValid: []
      }

      if (this.currentBrand == null) return result

      result = this.checkPflichtfeld(
        this.isValidID,
        'ComParamOriginCountryID',
        result,
        hideElementsIfValid,
        checkBrandSave
      )
      result = this.checkPflichtfeld(
        this.isValidID,
        'ComParamIntRootCountryID',
        result,
        hideElementsIfValid,
        checkBrandSave
      )
      result = this.checkPflichtfeld(
        this.isValidID,
        'ComParamIntRootYear',
        result,
        hideElementsIfValid,
        checkBrandSave
      )
      result = this.checkPflichtfeld(
        this.isValidID,
        'ComParamIntRootFirstPartnerYear',
        result,
        hideElementsIfValid,
        checkBrandSave
      )
      result = this.checkPflichtfeld(
        this.isValidID,
        'LogoID',
        result,
        hideElementsIfValid,
        checkBrandSave
      )
      result = this.checkPflichtfeld(
        this.isValidID,
        'MainPictureID',
        result,
        hideElementsIfValid,
        checkBrandSave,
        this.messestand
      )
      result = this.checkPflichtfeld(
        this.isValidID,
        'SpecialSectorID',
        result,
        hideElementsIfValid,
        checkBrandSave
      )
      result = this.checkPflichtfeld(
        this.isValidLength,
        'ProvidedSupportIDs',
        result,
        hideElementsIfValid,
        checkBrandSave
      )
      result = this.checkPflichtfeld(
        this.isValidLength,
        'ProvidedFinancialAidIDs',
        result,
        hideElementsIfValid,
        checkBrandSave
      )
      result = this.checkPflichtfeld(
        this.isValidLength,
        'ProvidedTrainingIDs',
        result,
        hideElementsIfValid,
        checkBrandSave
      )
      result = this.checkPflichtfeld(
        this.isValidLength,
        'ProvidedManagementAssistanceIDs',
        result,
        hideElementsIfValid,
        checkBrandSave
      )
      result = this.checkPflichtfeld(
        this.isValidLength,
        'ConceptServices',
        result,
        hideElementsIfValid,
        checkBrandSave,
        null,
        checkLength ? 399 : 0
      )
      result = this.checkPflichtfeld(
        this.isValidLength,
        'ConceptUSP',
        result,
        hideElementsIfValid,
        checkBrandSave,
        null,
        checkLength ? 179 : 0
      )
      result = this.checkPflichtfeld(
        this.isValidLength,
        'CandidateRemark',
        result,
        hideElementsIfValid,
        checkBrandSave,
        null,
        checkLength ? 179 : 0
      )
      result = this.checkPflichtfeld(
        this.isValidLength,
        'PartnerActivityShortDescription',
        result,
        hideElementsIfValid,
        checkBrandSave,
        null,
        checkLength ? 179 : 0
      )
      result = this.checkPflichtfeld(
        this.isValidLength,
        'Homepage',
        result,
        hideElementsIfValid,
        checkBrandSave
      )
      result = this.checkPflichtfeld(
        this.isValidLength,
        'CondCurrency',
        result,
        hideElementsIfValid,
        checkBrandSave
      )
      result = this.checkPflichtfeld(
        this.isValidLength,
        'PartnerTargetGroupIDs',
        result,
        hideElementsIfValid,
        checkBrandSave
      )
      result = this.checkPflichtfeld(
        this.isValidID,
        'PartnerLateralEntrance',
        result,
        hideElementsIfValid,
        checkBrandSave,
        null,
        -1
      )
      result = this.checkPflichtfeld(
        this.isValidID,
        'PartnerHomeOffice',
        result,
        hideElementsIfValid,
        checkBrandSave,
        null,
        -1
      )
      result = this.checkPflichtfeld(
        this.isValidID,
        'PartnerSmallBusiness',
        result,
        hideElementsIfValid,
        checkBrandSave,
        null,
        -1
      )
      result = this.checkPflichtfeld(
        this.isValidID,
        'PartnerMultipleLocations',
        result,
        hideElementsIfValid,
        checkBrandSave,
        null,
        -1
      )
      if (
        (this.currentBrand.PartnerFullTimeJob != null &&
          parseInt(this.currentBrand.PartnerFullTimeJob) === 1) ||
        (this.currentBrand.PartnerPartTimeJob != null &&
          parseInt(this.currentBrand.PartnerPartTimeJob) === 1)
      ) {
        result.counter -= 1
        if (hideElementsIfValid) this.setHiddenElement('PartnerFullTimeJob')
      } else result.notValid.push('PartnerFullTimeJob')

      if (this.currentBrand.DetailedSystemHandbook != null) {
        result.counter -= 1
        if (hideElementsIfValid) {
          this.setHiddenElement('DetailedSystemHandbook')
        }
      } else result.notValid.push('DetailedSystemHandbook')

      if (
        this.isValidLength(this.currentBrand.CondLicenceRemark) ||
        this.currentBrand.CondRegularLicenceType !== -1
      ) {
        result.counter -= 1
        if (hideElementsIfValid) this.setHiddenElement('CondLicenceRemark')
      } else {
        if (
          checkBrandSave &&
          !this.isValidLength(this.currentBrandSave.CondLicenceRemark)
        ) {
          result.counter -= 1
        } else result.notValid.push('CondLicenceRemark')
      }

      if (
        this.isValidFromToField(
          this.currentBrand.CondInvestSumType,
          this.currentBrand.CondInvestSumFrom,
          this.currentBrand.CondInvestSumTo
        )
      ) {
        result.counter -= 1
        if (hideElementsIfValid) this.setHiddenElement('CondInvestSumType')
      } else result.notValid.push('CondInvestSumType')

      if (
        this.isValidFromToField(
          this.currentBrand.CondRequiredCapitalType,
          this.currentBrand.CondRequiredCapitalFrom,
          this.currentBrand.CondRequiredCapitalTo
        )
      ) {
        result.counter -= 1
        if (hideElementsIfValid) {
          this.setHiddenElement('CondRequiredCapitalType')
        }
      } else result.notValid.push('CondRequiredCapitalType')

      if (
        this.isValidFromToField(
          this.currentBrand.CondEntryFeeType,
          this.currentBrand.CondEntryFeeFrom,
          this.currentBrand.CondEntryFeeTo
        )
      ) {
        result.counter -= 1
        if (hideElementsIfValid) this.setHiddenElement('CondEntryFeeType')
      } else result.notValid.push('CondEntryFeeType')

      if (
        this.isValidFromToField(
          this.currentBrand.CondInitialCapitalType,
          this.currentBrand.CondInitialCapitalFrom,
          this.currentBrand.CondInitialCapitalTo
        ) ||
        this.currentBrand.CondInitialCapitalType === -1
      ) {
        result.counter -= 1
        if (hideElementsIfValid) {
          this.setHiddenElement('CondInitialCapitalType')
        }
      } else result.notValid.push('CondInitialCapitalType')

      if (this.isValidLicenceInformation()) {
        result.counter -= 1
        if (hideElementsIfValid) {
          this.setHiddenElement('CondRegularLicenceType')
        }
      } else result.notValid.push('CondRegularLicenceType')

      result.hiddenElements = this.hiddenElements
      this.pflichtfeldCounter = result.counter
      return result
    },
    checkPflichtfeld (
      isValidFunc,
      pflichtfeldName,
      result,
      hideElementsIfValid,
      checkBrandSave,
      currentObject = null,
      minLength = 0
    ) {
      try {
        currentObject = currentObject || this.currentBrand
        if (isValidFunc(currentObject[pflichtfeldName], minLength)) {
          result.counter -= 1
          if (hideElementsIfValid) this.setHiddenElement(pflichtfeldName)
        } else {
          if (
            checkBrandSave &&
            !isValidFunc(this.currentBrandSave[pflichtfeldName])
          ) {
            result.counter -= 1
          } else {
            result.notValid.push({
              [pflichtfeldName]: currentObject[pflichtfeldName]
            })
          }
        }
      } catch (error) {
        console.error(
          error,
          'checkPflichtfeld',
          pflichtfeldName,
          this.currentBrand
        )
      }
      return result
    },
    isValidID (value, minVal = 0) {
      return value != null && value > minVal
    },
    isValidLength (value, minLength = 0, stripHTML = true) {
      if (value != null && stripHTML) value = this.stripHtml(value)
      return value != null && value.length > minLength
    },
    isValidString (value) {
      return value != null && value !== ''
    },
    isValidFromToField (typeValue, fromValue, toValue) {
      if (typeValue == null || typeValue <= 0) return false

      let result = false
      if (
        typeValue === 1 &&
        this.isValidString(toValue) &&
        this.isValidString(fromValue)
      ) {
        result = true
      }
      if (
        (typeValue === 2 || typeValue === 3) &&
        this.isValidString(fromValue)
      ) {
        result = true
      }

      return result
    },
    isValidLicenceInformation () {
      return (
        (this.currentBrand.CondRegularLicenceType != null &&
          this.currentBrand.CondRegularLicenceType <= 0) ||
        (this.currentBrand.CondRegularLicenceType === 1 &&
          this.currentBrand.CondRegularLicencePercentage != null &&
          this.currentBrand.CondRegularLicencePercentage !== '') ||
        (this.currentBrand.CondRegularLicenceType === 2 &&
          this.currentBrand.CondRegularLicenceAmount != null &&
          this.currentBrand.CondRegularLicenceAmount !== '') ||
        (this.currentBrand.CondRegularLicenceType === 3 &&
          this.currentBrand.CondRegularLicenceAmount != null &&
          this.currentBrand.CondRegularLicenceAmount !== '') ||
        (this.currentBrand.CondRegularLicenceType >= 5 &&
          this.currentBrand.CondRegularLicencePercentage != null &&
          this.currentBrand.CondRegularLicencePercentage !== '' &&
          this.currentBrand.CondRegularLicenceAmount != null &&
          this.currentBrand.CondRegularLicenceAmount !== '')
      )
    },
    stripHtml: function (html) {
      const tmp = document.createElement('DIV')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    },
    setHiddenElement (element) {
      this.hiddenElements[element] = true
    },
    changeToBrandLang () {
      const currentCompany = this.currentBrand.Company
      let brandLang = 'en'
      if (
        currentCompany.CorrespondenceLanguageID === 2 ||
        currentCompany.CorrespondenceLanguageID == null
      ) {
        brandLang = 'de'
      }
      if (brandLang !== this.$i18n.locale) {
        currentCompany.CorrespondenceLanguageID =
          this.$i18n.locale === 'de' ? 2 : 5
        this.createJivoChat()
        this.setJivoChatUser()
        this.reinitializeUsetiful()
        LuxonSettings.defaultLocale = this.$i18n.locale
      }
    },
    getMedium (id) {
      return new Promise((resolve, reject) => {
        if (id == null) {
          reject(new Error('Medium ID is empty'))
          return
        }
        _Api
          ._post('medium/', {
            MediumID: id
          })
          .then((resonse) => {
            if (resonse.body.Result.Data.MediumID > 0) {
              resolve(Object.assign({}, resonse.body.Result.Data))
            } else {
              reject(new Error('PreviewImage was not found.'))
            }
          })
      })
    },
    getValidHomapageUrl (url) {
      if (url == null) return null

      if (!url.startsWith('http')) url = 'http://' + url

      return url
    },
    maskedBrandID: function () {
      if (!this.currentBrand) return 0
      return this.currentBrand.BrandID * 360 - 527 * 6
    },
    getSpecialSectorDropdownData: function () {
      const options = []
      this.preloads.specialsectorgroups.forEach((group) => {
        const sectors = []
        group.SpecialSectors.forEach((sector) => {
          sectors.push({
            Caption: group.Caption + '|' + sector.Caption,
            SpecialSectorID: sector.SpecialSectorID,
            groupcaption: group.Caption
          })
        })
        options.push({ group: group.Caption, sectors })
      })

      return options
    },
    getSelectedSpecialSectorForDropDown: function (specialSectorID) {
      const options = this.getSpecialSectorDropdownData()
      let result = null
      options.forEach((group) => {
        group.sectors.forEach((sector) => {
          if (result === null && sector.SpecialSectorID === specialSectorID) {
            result = sector
          }
        })
      })

      return result
    },
    getSimilarBrandsBySpecialSector (specialSector, portalID) {
      if (specialSector == null || specialSector <= 0) return

      return new Promise((resolve, reject) => {
        if (this.similarBrands.length > 0) {
          this.similarBrands.sort(() => Math.random() - 0.5)
          resolve(this.similarBrands)
        } else {
          _Api
            ._post('BrandsSpecialSectorRelated/', {
              ResFrom: 1,
              ResTo: 20,
              FullSubEntities: true,
              MinWeight: 5,
              PortalId: portalID,
              SpecialSectorId: specialSector
            })
            .then((response) => {
              const result = response.body.Result.Data
              result.sort(() => Math.random() - 0.5)
              this.similarBrands = result
              resolve(result)
            })
        }
      })
    },
    getPortalIDFromBrand () {
      let portalID = 1

      if (this.currentBrand.Membership === 3) {
        const fair = this.getFairFromBrand()
        portalID = this.getFirstPortalVisibility(fair)
      } else {
        if (this.currentBrand.ComParamOriginCountryID === 2) portalID = 3
        else if (this.currentBrand.ComParamOriginCountryID === 5) portalID = 2
        else portalID = 1
      }

      return portalID
    },
    getFairFromBrand () {
      if (
        this.currentBrand == null ||
        this.currentBrand.BrandModules == null ||
        this.currentBrand.BrandModuleslength === 0
      ) {
        return null
      }

      this.currentBrand.BrandModules.forEach((module) => {
        if (
          module.ModuleID === 1 &&
          this.currentBrand.BrandID === module.BrandID
        ) {
          if (module.Fair != null) return module.Fair
        }
      })

      return null
    },
    getFirstPortalVisibility (fair) {
      if (
        fair == null ||
        fair.FairPortals == null ||
        fair.FairPortals.length === 0
      ) {
        return 1
      }

      const result = []
      fair.FairPortals.forEach((portal) => {
        result.push(portal)
      })

      return result.length > 0 ? result[0].PortalID : 1
    },
    getMailHistoryForMembership () {
      return new Promise((resolve, reject) => {
        _Api
          ._post('EmailHistories/', {
            ResFrom: 1,
            ResTo: 500,
            Filter: [
              {
                Field: 'BrandID',
                Operator: 'EQ',
                Value: this.currentBrand.BrandID
              },
              {
                Field: 'EmailID',
                Operator: 'IN',
                Value: '7,8'
              },
              {
                Field: 'SendDate',
                Operator: 'GE',
                Value: moment()
                  .subtract(3, 'month')
                  .format('YYYY-MM-DD HH:mm:ss')
              }
            ]
          })
          .then((response) => {
            resolve(Object.assign({}, response.body.Result.Data))
          })
      })
    },
    getPlannedMailsForMembershipView () {
      return new Promise((resolve, reject) => {
        _Api
          ._post('EmailScheduleds/', {
            ResFrom: 1,
            ResTo: 500,
            Filter: [
              {
                Field: 'BrandID',
                Operator: 'EQ',
                Value: this.currentBrand.BrandID
              },
              {
                Field: 'EmailID',
                Operator: 'IN',
                Value: '7,8'
              },
              {
                Field: 'StatusID',
                Operator: 'LT',
                Value: 1
              },
              {
                Field: 'ScheduleDate',
                Operator: 'GE',
                Value: moment().format('YYYY-MM-DD HH:mm:ss')
              }
            ]
          })
          .then((response) => {
            if (_Api.isValidApiResponse(response, 'EmailScheduleds/')) {
              resolve(Object.assign({}, response.body.Result.Data))
            } else {
              reject(new Error('Error occoured'))
            }
          })
      })
    },
    scheduleMembershipMails () {
      _Api.scheduleMBMembershipViewMails(
        this.currentBrand.BrandID,
        this.currentContact.ContactID
      )
    }
  }
}
</script>
<style lang="css" scoped>
main {
  flex-grow: 1;
}

.mb-area main {
  position: relative;
  display: flex;
}

.mb-area .mb-main-content-area {
  width: 100%;
  background-position: -1000px 200px;
}

@media (max-width: 768px) {
  .mb-area .mb-main-content-area {
    background-position: -1000px 430px;
  }
}

@media (max-width: 992px) {
  .mb-area .mb-main-content-area {
    background-position: -1000px 360px;
  }
}

@media (max-width: 1600px) {
  .mb-area .mb-main-content-area {
    background-position: -1000px 300px;
  }
}

#saveBar {
  position: fixed;
  bottom: 0;
  width: calc(100% - 248px);
  z-index: 1001;
  background-color: white;
  border-top: 2px solid grey;
  padding: 1.4rem 1rem;
  margin-left: 248px;
}

.mb-route-mitgliedschaften {
  background-color: white !important;
}
</style>
