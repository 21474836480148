<template>
  <FairFieldEditor :title="computedTitle" :index="9" buttonSaveText="Korrekturwunsch absenden" :showContentOnEdit="true"
    :loading="isLoading" @save="handleSave" :isEditing="isEditing" :saveDisabled="!computedIsValid"
    :is-modal-open.sync="isModalOpen" @toggleEdit="toggleEdit" @toggle="toggleContent" :showOpenButton="false"
    :usePageStore="true" v-if="computedShowStep" @adminSave="handleAdminSave" :is-admin-modal-open.sync="isAdminModalOpen" @update:isAdminModalOpen="toggleAdminEdit">

    <div class="brand-fitting-html" v-html="computedFittingHtml" />

    <template v-slot:edit>
      <hr />
      <div class="d-flex flex-column w-100 gap-3">
        <input type="text" class="form-control w-100" :placeholder="formFields.placeholder"
          v-model="formFields.value" maxlength="850" />
      </div>
    </template>
    <template v-slot:admin>
      <div class="d-flex flex-column w-100 gap-3">
        <CustomEditor v-model="formFields.value" />
      </div>
    </template>
    <template v-slot:conditions>
      <div class="d-flex flex-column gap-1">
        <span class="fw-bold">Anforderungen:</span>
        <ul class="special">
          <li>Der Text muss eine Auflistung von mind. 5 Punkten enthalten</li>
          <li>Welche Interessen und Wünsche haben Ihre potenziellen Partner:innen gemeinsam?</li>
          <li>Der Text soll passende Interessent:innen darin bestärken, mit {{ currentBrand.Name }} zu gründen</li>
        </ul>
      </div>
    </template>
    <template v-slot:editButtonCaption>
      Stimmt etwas nicht?
    </template>
    <template v-slot:preview>
      <img class="img-fluid" src="@/assets/images/new-fair/9_Fitting.png" />
    </template>
  </FairFieldEditor>
</template>

<script>
import FairFieldEditor from '@/components/FairFieldEditor.vue'
import CustomEditor from '@/components/CustomEditor.vue'
import { useBrandStore } from '@/stores/brandStore'

export default {
  name: 'FairFormFitting',
  components: {
    FairFieldEditor,
    CustomEditor
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      isAdminModalOpen: false,
      formFields: {
        placeholder: 'Tragen Sie hier Ihren neuen Vorschlag ein.',
        value: ''
      }
    }
  },
  methods: {
    toggleEdit () {
      this.isEditing = !this.isEditing
    },
    toggleAdminEdit () {
      this.formFields.value = this.currentBrand.FitsYouIf
    },
    toggleContent () {
      this.isOpen = !this.isOpen
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Korrekturwünsche wurden erfolgreich gespeichert.')
        this.isModalOpen = false
        this.formFields.value = ''
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern deiner Korrekturwünsche ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async handleAdminSave () {
      this.isLoading = true
      try {
        const brandStore = useBrandStore()
        await brandStore.updateCurrentBrand({
          FitsYouIf: this.formFields.value
        })
        this.isAdminModalOpen = false
        this.formFields.value = ''
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async submitForm () {
      await this.app.APIService._post('mb/fair/update/9', {
        brandId: this.currentBrand.BrandID,
        value: this.formFields.value
      })
    }
  },
  computed: {
    computedShowStep () {
      return this.currentBrand.FitsYouIf !== null && this.currentBrand.FitsYouIf !== '' && String(this.currentBrand.FitsYouIf).length > 0
    },
    computedTitle () {
      return `Dieses ${this.computedExpansionFormCaption}-Unternehmen passt zu dir, wenn…`
    },
    computedIsValid () {
      return this.formFields.value.length > 0
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentBrand
    },
    currentFair: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentFair
    },
    computedFittingHtml () {
      return this.currentBrand.FitsYouIf ?? ''
    },
    computedExpansionFormCaption () {
      if (this.currentBrand) {
        if (this.currentBrand.ConceptExpansionFormFranchise) return 'Franchise'
        if (this.currentBrand.ConceptExpansionFormLicense) return 'Lizenz'
      }
      return ''
    }
  }

}
</script>

<style lang="scss">
.brand-fitting-html {

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  ul li {
    position: relative;
    padding-left: calc(24px + 8px);

    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

  }

  ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    /* Vertically center the icon */
    width: 24px;
    /* Adjust size as needed */
    height: 24px;
    /* Adjust size as needed */
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAMYSURBVHic7Zg7aBRRFIa/MzviKoZgUPBVaLKohWihYAohiVFELURRLCxUsNBOBYmxSmFjhAQLK/GBWIliIdjkZSzExk4ENZtG0Ea3MIKSzMyxmAxqQN1J7p3suPdr555z//tz7v7LBYfD4XA4HA5HfSLzufn3oVJLoaC9IK0oi4DRMIy6irvG32alYd4MmBwpnRLoB5bM+PQpjGgtdo6Vs9DhZ7HJr+iz0vIg5AZw4A9LlhU8rgCHs9CTqQHBcPO+IOQmsOKvC4Xd2SjKyAB9vGpx2LD4qipnqObaaXZX07oBk8MtWwOReygbU5QNWRM0A2sG6H0KwbJSF0IPsKDqQqESBlGXLV0zsWLAt9EN64Iougu6I1Wh8DYKOJhlDBZMNwyelo54qo+BDakKRZ74Guz1O8ffm9b0121NNdKB5sYp37sucCxtKdDrj45dkh4iU3qqxYgBU8PNnYh3B1iTsnRCRI/77eVHJnTMhjn9BujI2mKA34NyAfBSFU/f94W7yq/nomGuCOdvHUWlH1j5j7UfQc7Sf+I+wOTw+i0i0T1gk3WVZplAdCAUvVhsG38nnLtdAZZWWVzRxpPLg7aW8yCXgYUWhdpFqPiht9mn+sMDNAVtpUGgw5Ks7FCapgpRX7p7G5P/w08jyp7ZGPBfUe8GDNSvAUIlRLvrzwDhC/AgVN1e7CiPZf4iNAcmQAZD9bqLO9+8MdU0TxPQAHqw4IXPdaSU9i/3H8mTATFKUwB9ptrlzwAAYY+pVvk0QFFTrfJpAAyaapSnFIgRKqFE3aba5WcC4vx+GErUWmwbf2eqbR4mIM7/yGz+J+RhAqzkf0IeDIgxnP8J+TEAjOZ/Qr4MMJj/CfkywGD+J+QhBWIM539C7U+ApfxPqOUJ+Pl+327+4Am1PAENqBwqqPdCh9avtrVJLRsQM/1+b6t97RtA/H5vq3cuDLDJbAwYMK5iHvf0gEqK9Z/9hsb9oNeAwJKm35l+v7fV3gM5g/LhnyuVD6ielm0vpxZ0lM9GIVsQ+oBXwFfjyma83xvv73A4HA6Hw+Goc34AL9j0OqyTQRgAAAAASUVORK5CYII=');
    background-size: cover;
    /* Adjust to ensure the SVG icon is scaled correctly */
  }

}
</style>
