<script>
import { Bar, mixins } from 'vue-chartjs'
export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options', 'useDefaultLegendClick', 'totalText', 'plugins'],
  data () {
    return {
      onlyOnceAnimation: false
    }
  },
  mounted () {
    this.setTooltip()
    this.onlyOnceAnimation = false
    this.renderChart(this.chartData, this.options, this.plugins)
  },
  methods: {
    setTooltip () {
      if (
        this.options != null &&
        this.options.tooltips != null &&
        this.options.tooltips.custom == null
      ) {
        const _this = this
        this.options.tooltips.custom = function (tooltipModel) {
          // console.info(tooltipModel, tooltipModel.title, tooltipModel.body)
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip')

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div')
            tooltipEl.id = 'chartjs-tooltip'
            tooltipEl.innerHTML = '<div style="background-color: #fff; padding: 10px; border-radius: 20px; border: 1px solid #ccc"><table></table></div>'
            document.body.appendChild(tooltipEl)
          }

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0
            return
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform')
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign)
          } else {
            tooltipEl.classList.add('no-transform')
          }

          function getBody (bodyItem) {
            return bodyItem
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || []
            const bodyLines = tooltipModel.body.map(getBody)

            let innerHtml = '<thead>'

            titleLines.forEach(function (title) {
              innerHtml += '<tr><th>' + title + '</th></tr>'
            })
            innerHtml += '</thead><tbody>'

            let totalCount = 0
            let avgLine = ''
            let avgMinMax = ''
            bodyLines.forEach(function (body, i) {
              const line = body.lines
              const before = body.before
              const colors = tooltipModel.labelColors[i]
              let style = 'background:' + colors.backgroundColor
              style += '; border-color:' + colors.borderColor
              style += '; border-width: 2px;'
              style += 'display: block; width: 15px; height: 15px; border-radius: 50%; margin-right: 5px;'
              const span = '<span style="' + style + '"></span>'
              if (before.length > 0) {
                const beforeText = before[0].replace('FranchisePORTAL', 'FP').replace('Ø ', '')
                const spanbefore = '<span style="font-weight:700">' + beforeText + '</span>'
                if (line[0].startsWith('Ø')) avgMinMax += '<td style="padding-right: 10px">' + spanbefore + '</td>'
                else innerHtml += '<tr><td style="padding-top:10px">' + spanbefore + '</td></tr>'
              }
              if (line[0].startsWith('Ø')) {
                let text = line[0]
                text = text.replace(': ', ':&nbsp;<strong>') + '</strong>'
                text = text.replace('Ø ', '')
                avgLine += '<td><span style="display: flex; margin-right: 10px">' + span + text + '</span></td>'
                return
              }
              const tmpLine = line[0].split(': ')
              if (tmpLine.length > 1) totalCount += parseInt(tmpLine[1])
              line[0] = line[0].replace(': ', ':&nbsp;<strong>') + '</strong>'
              innerHtml += '<tr><td style="display: flex">' + span + line + '</td></tr>'
            })
            if (avgLine !== '') {
              innerHtml += '<tr><td style="padding-top: 10px; font-weight: 700">' + this.$t('page.statistics.average') + ':</td></tr>'
              innerHtml += '<tr><td style="display: flex"><table><tr>' + avgLine
              if (avgMinMax !== '') {
                innerHtml += '</tr>' + avgMinMax + '</td>'
              }
              innerHtml += '</tr></table></td></tr>'
            }
            if (_this.totalText != null) innerHtml += '<tr><td style="font-weight: 700; padding-top: 10px">' + _this.totalText + ': ' + totalCount + '</td></tr>'
            innerHtml += '</tbody>'

            const tableRoot = tooltipEl.querySelector('table')
            tableRoot.innerHTML = innerHtml
          }

          // `this` will be the overall tooltip
          const position = this._chart.canvas.getBoundingClientRect()

          let offset = 20
          if (this._chart.width / 2 < tooltipModel.caretX) {
            offset *= -1
            offset -= position.left / 2 - 80
          }
          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1
          tooltipEl.style.position = 'absolute'
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + offset + 'px'
          tooltipEl.style.top = position.top + window.pageYOffset + 'px'
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px'
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
          tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
          tooltipEl.style.pointerEvents = 'none'
        }
      }
      if (
        this.useDefaultLegendClick !== true &&
        this.options != null &&
        this.options.legend != null &&
        this.options.legend.onClick == null
      ) this.options.legend.onClick = this.legendWithQuickPreviewClick
      if (this.options.animation == null) this.options.animation = {}
      if (this.options.animation.onComplete == null) this.options.animation.onComplete = this.callOnlyOnceAnimation // initial values & prevent flickering by calling only once
      if (this.options.animation.onProgress == null) this.options.animation.onProgress = this.animationComplete
      this.chartData.options = this.options

      if (typeof this.plugins === 'undefined') return
      // if (this.plugins == null) this.plugins = []
      const legendMargin = {
        id: 'legendMargin',
        beforeInit (chart, legend, options) {
          const fitValue = chart.legend.fit
          chart.legend.fit = function fit () {
            fitValue.bind(chart.legend)()
            const newHeight = this.height += 20
            return newHeight
          }
        }
      }
      this.plugins.push(legendMargin)
    },
    legendWithQuickPreviewClick (evt, legendItem) { // Markenansicht & Schnellansicht Ein- & Ausblenden
      const index = legendItem.datasetIndex
      this.chartData.datasets[index].hidden = !this.chartData.datasets[index].hidden
      if (
        this.chartData.datasets.length > index &&
        (legendItem.text.startsWith('FranchisePORTAL') ||
        legendItem.text.startsWith('TOP-' + this.$t('page.statistics.brand'))) &&
        this.chartData.datasets[index].label.startsWith(this.$t('page.statistics.quickview'))
      ) this.chartData.datasets[index + 1].hidden = this.chartData.datasets[index].hidden
      if (this.chartData.datasets.length > index &&
                (legendItem.text.startsWith('FranchisePORTAL'))) {
        let portal = legendItem.text.replace('FranchisePORTAL.', '').toUpperCase()
        portal = portal.replace('   (X)', '')
        this.chartData.datasets.forEach(dataset => {
          if (dataset.label.startsWith('Ø ' + portal)) {
            dataset.hidden = this.chartData.datasets[index].hidden
          }
        })
      }
      this.renderChart(this.chartData, this.options, this.plugins)
    },
    callOnlyOnceAnimation () {
      if (this.onlyOnceAnimation) return
      this.onlyOnceAnimation = true
      this.animationComplete()
    },
    animationComplete () {
      let canvas = this.$refs.canvas
      if (canvas == null) {
        canvas = this.$el.children[0]
      }
      const ctx = canvas.getContext('2d')

      ctx.textAlign = 'center'
      ctx.textBaseline = 'bottom'
      ctx.fillStyle = '#29363d'

      const topLabels = []
      this.chartData.datasets.forEach(function (dataset, i) {
        if (dataset.hidden === true) return
        let meta = null
        for (let i = 0; i < 100; i++) {
          if (dataset._meta == null || dataset._meta[i] == null || dataset._meta[i].hidden === true || dataset.label.startsWith('Ø')) continue
          meta = dataset._meta[i]
          break
        }
        const posY = 0
        if (meta != null) {
          meta.data.forEach(function (bar, index) {
            if (bar.hidden === true) return
            if (!topLabels[index]) {
              topLabels[index] = {
                posX: bar._model.x,
                posY: posY < bar._model.y ? bar._model.y : posY
              }
            } else {
              topLabels[index].posY = posY < bar._model.y ? bar._model.y : posY
            }
            if (topLabels[index].totalSum == null) topLabels[index].totalSum = dataset.data[index]
            else topLabels[index].totalSum += dataset.data[index]
          })
        }
      })
      topLabels.forEach(topLabel => {
        ctx.fillText(topLabel.totalSum, topLabel.posX, topLabel.posY - 5)
      })
    }

  }
}
</script>
