<template>
  <div class="mb-bar-chart p-4 mb-4 card">
      <div class="d-flex align-items-center">
          <h2 v-if="title != ''">{{title}}</h2>
          <div class="ms-5" v-if="showAvgDataOpt">
              <b-form-checkbox v-model="showAvgData" :value="true" :unchecked-value="false">Durchschnitt anzeigen</b-form-checkbox>
          </div>
      </div>
      <div v-if="isLoading" class="mb-loading"><span v-t="'page.statistics.loading'"></span></div>
      <div v-if="!isLoading && showNoDataHint" class="mb-no-chart-data"><span v-t="'page.statistics.nodata'"></span></div>
      <bar-chart
        v-if="!isLoading && chartData.datasets != null && chartData.datasets.length > 0"
        :chartData="chartData"
        :options="chartData.options"
        :plugins="[]"
        :totalText="this.$t('page.statistics.total')"
      ></bar-chart>
  </div>
</template>

<script>
import BarChart from './Bar.vue'
import moment from 'moment'

export default {
  components: {
    BarChart
  },
  name: 'ImpressionsChartBar',
  props: ['startDate', 'endDate', 'groupby', 'title', 'currentBrand', 'scaleYstepSize', 'filterByBrandIDs', 'parent', 'loadCounter', 'API', 'showAvgDataOpt'],
  data: function () {
    return {
      chartData: {},
      chartLabels: [],
      barChartDatasetsPortal: [],
      isLoading: false,
      showNoDataHint: false,
      dataColCount: 0,
      avgBrands: {
        de: [],
        at: [],
        ch: []
      },
      membersData: [],
      filterBrandIDs: [],
      showAvgData: false
    }
  },
  computed: {
  },
  created: function () {
  },
  methods: {
    getChartData () {
      const momentStarDate = moment(this.startDate, 'DD.MM.YYYY')
      this.filterBrandIDs = this.filterByBrandIDs != null
        ? this.filterByBrandIDs
        : (this.currentBrand != null ? this.parent.SM.getBrandsBySpecialSectorGroup(this.currentBrand.SpecialSector.SpecialSectorGroup) : [])
      Promise.all([this.getDetailChartData(), this.getAvgChartData()])
        .then(async ([detailData, avgData]) => {
          if (detailData.body.Result.Data != null && detailData.body.Result.Data.length === 0) {
            this.showNoDataHint = true
          } else this.showNoDataHint = false
          const detailChartBarData = this.mapChartBarData(detailData.body.Result.Data.slice(0), this.dataColCount, momentStarDate)
          let avgLineChartData = []
          if (avgData != null) {
            const avgChartData = await this.mapLineChartData(avgData.body.Result.Data, this.dataColCount, momentStarDate)
            avgLineChartData = await avgChartData
          }
          this.chartData = this.getDefaultChartData()
          this.chartData.datasets = avgLineChartData.concat(detailChartBarData)
          this.chartData.options.tooltips.callbacks.beforeLabel = this.tooltipCallbackBeforeLabel
          this.chartData.options.tooltips.callbacks.label = this.tooltipCallbackLabel
          this.chartData.options.legend.labels.filter = this.getFilteredLabels
          this.chartData.options.legend.labels.generateLabels = this.changeLegendText
          this.chartData.labels = this.chartLabels
        }).finally(() => {
          this.isLoading = false
        })
    },
    getDetailChartData () {
      if (this.isLoading) return

      this.isLoading = true
      const momentStarDate = moment(this.startDate, 'DD.MM.YYYY')
      const momentEndDate = moment(this.endDate, 'DD.MM.YYYY')
      this.dataColCount = momentEndDate.diff(momentStarDate, this.groupby.toLowerCase() + 's') + 1
      if (this.dataColCount === 1 && (this.groupby === 'Week' || this.groupby === 'Month')) {
        if (momentStarDate.format('w') !== momentEndDate.format('w')) this.dataColCount++
      }
      const postData = {
        ResFrom: 1,
        ResTo: 10,
        FullSubEntities: false,
        Filter: [
          {
            Field: 'Created',
            Operator: 'GE',
            Value: momentStarDate.format('YYYY-MM-DD HH:m:s')
          },
          {
            Field: 'Created',
            Operator: 'LT',
            Value: momentEndDate.format('YYYY-MM-DD') + ' 23:59:59'
          }
        ],
        OrderBy: [
          { Field: 'Created', SortOrder: 'ASC' },
          { Field: 'SourceModule', SortOrder: 'ASC' }
        ],
        GroupBy: {
          Key: [
            { Field: 'Created', DatePart: this.groupby },
            { Field: 'SourceModule' }
          ],
          Calc: [
            { Field: '*', Func: 'CNT' }
          ]
        },
        Tablename: 'Lead'
      }
      if (this.currentBrand != null) {
        postData.Filter.push(
          {
            Field: 'BrandID',
            Operator: 'EQ',
            Value: this.currentBrand.BrandID
          }
        )
      }
      if (this.filterByBrandIDs != null && this.filterByBrandIDs.length > 0) {
        postData.Filter.push(
          {
            Field: 'BrandID',
            Operator: 'IN',
            Value: this.filterByBrandIDs.join(',')
          }
        )
      }
      return this.API._post('groupby/', postData)
    },
    async getAvgChartData () {
      if (!this.showAvgData) return null
      let brandIDs = this.filterByBrandIDs
      if (this.filterByBrandIDs == null && this.currentBrand != null) {
        this.parent.SM.getBrandsBySpecialSectorGroup(this.currentBrand.SpecialSector.SpecialSectorGroup)
      }
      const momentStarDate = moment(this.startDate, 'DD.MM.YYYY')
      const momentEndDate = moment(this.endDate, 'DD.MM.YYYY')
      this.dataColCount = momentEndDate.diff(momentStarDate, this.groupby.toLowerCase() + 's') + 1
      if (this.dataColCount === 1 && (this.groupby === 'Week' || this.groupby === 'Month')) {
        if (momentStarDate.format('w') !== momentEndDate.format('w')) this.dataColCount++
      }
      this.avgBrands.de = new Array(this.dataColCount).fill(0)
      this.avgBrands.at = new Array(this.dataColCount).fill(0)
      this.avgBrands.ch = new Array(this.dataColCount).fill(0)

      const postData = {
        ResFrom: 1,
        ResTo: 10,
        FullSubEntities: false,
        Filter: [
          {
            Field: 'Created',
            Operator: 'GE',
            Value: momentStarDate.format('YYYY-MM-DD HH:m:s')
          },
          {
            Field: 'Created',
            Operator: 'LT',
            Value: momentEndDate.format('YYYY-MM-DD') + ' 23:59:59'
          }
        ],
        OrderBy: [
          { Field: 'Created', SortOrder: 'ASC' }
        ],
        GroupBy: {
          Key: [
            { Field: 'Created', DatePart: this.groupby },
            { Field: 'SourceModule' },
            { Field: 'BrandID' }
          ],
          Calc: [
            { Field: '*', Func: 'CNT' }
          ]
        },
        Tablename: 'Lead'
      }
      let sourceModules = []
      if (this.currentBrand != null) {
        brandIDs = await this.parent.SM.getSpecialSectorGroupBrandIDs(this.currentBrand.SpecialSectorID)
        sourceModules = await this.getSourceModuleByPortal()
      }
      let currentPortals = [1, 2, 3]
      if (this.currentBrand != null &&
              this.currentBrand.BrandModules[0].Fair != null &&
              this.currentBrand.BrandModules[0].Fair.FairPortals.length > 0) {
        const fairPortals = this.currentBrand.BrandModules[0].Fair.FairPortals
        currentPortals = []
        fairPortals.forEach(fairPortal => {
          currentPortals.push(fairPortal.PortalID)
        })
      }
      sourceModules = await this.parent.SM.getSourceModuleByPortalIDs(currentPortals)
      if (sourceModules.length > 0) {
        postData.Filter.push(
          {
            Field: 'SourceModule',
            Operator: 'IN',
            Value: sourceModules.join(',')
          }
        )
      }
      if (brandIDs != null && brandIDs.length > 0) {
        postData.Filter.push(
          {
            Field: 'BrandID',
            Operator: 'IN',
            Value: brandIDs.join(',')
          }
        )
      }
      return this.API._post('groupby/', postData)
    },
    async getSourceModuleByPortal () {
      const postData = {
        ResFrom: 1,
        ResTo: 1000,
        FullSubEntities: false,
        QueryMode: 108,
        Filter: [
          {
            Field: 'BrandID',
            Operator: 'EQ',
            Value: this.currentBrand.BrandID
          }
        ]
      }
      return new Promise((resolve, reject) => {
        this.API._post('brands/', postData)
          .then(response => {
            const activePortals = response.body.Result.Data[0].ActivePortals
            resolve(this.parent.SM.getSourceModuleByPortalIDs(activePortals))
          })
      })
    },
    mapChartBarData (apiData, dataColCount, startDate) {
      this.chartLabels = []
      startDate = moment(this.startDate, 'DD.MM.YYYY')

      const dataBrand = {
        de: new Array(dataColCount).fill(0),
        at: new Array(dataColCount).fill(0),
        ch: new Array(dataColCount).fill(0),
        ug: new Array(dataColCount).fill(0),
        kpde: new Array(dataColCount).fill(0),
        kpat: new Array(dataColCount).fill(0),
        kpch: new Array(dataColCount).fill(0),
        kpug: new Array(dataColCount).fill(0),
        cbde: new Array(dataColCount).fill(0),
        cbat: new Array(dataColCount).fill(0),
        cbch: new Array(dataColCount).fill(0)
      }
      let currentDay = startDate
      let dayFormat = 'YYYY-MM-DD'
      let labelFormat = 'DD.MM'
      if (this.groupby === 'Week') {
        dayFormat = 'GGGG/WW'
        labelFormat = dayFormat
      }
      if (this.groupby === 'Month') {
        dayFormat = 'YYYY-MM'
        labelFormat = dayFormat
      }
      for (let i = 0; i < dataColCount; i++) {
        if (!this.chartLabels.includes(currentDay.format(labelFormat))) this.chartLabels.push(currentDay.format(labelFormat))
        if (apiData != null) {
          apiData.forEach(thisData => {
            let doContinue = false
            const splitKey = thisData.Key.split('~')
            if (splitKey[0] === currentDay.format(dayFormat)) {
              doContinue = true
              const portal = this.mapPortal(splitKey[1])
              if (!this.barChartDatasetsPortal.includes(portal)) this.barChartDatasetsPortal.push(portal)
              try {
                dataBrand[portal][i] += parseInt(thisData.Fields[0].Value)
              } catch (error) {
                console.error(error, 'portal: ' + portal)
              }
            } else if (doContinue) return true
          })
        }
        currentDay = startDate.add(1, this.groupby.toLowerCase() + 's')
      }

      return this.createDataSets(dataBrand)
    },
    mapPortal (portal) {
      if (portal == null || portal === '') return

      portal = portal.toLowerCase()
      let result = portal

      if (portal.startsWith('franchiseportal')) result = portal.replace('franchiseportal.', '')
      if (portal.startsWith('franchisekomparator')) result = 'kp' + portal.replace('franchisekomparator.', '')
      if (portal.startsWith('chatbot')) result = 'cb' + portal.replace('chatbot.', '')
      if (portal.startsWith('unternehmer')) result = 'ug'

      return result
    },
    createDataSets (dataBrand) {
      const datasets = []
      if (this.barChartDatasetsPortal.includes('de')) {
        datasets.push(
          {
            label: 'FranchisePORTAL.de',
            data: dataBrand.de,
            backgroundColor: '#005578',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('at')) {
        datasets.push(
          {
            label: 'FranchisePORTAL.at',
            data: dataBrand.at,
            backgroundColor: '#72bb53',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('ch')) {
        datasets.push(
          {
            label: 'FranchisePORTAL.ch',
            data: dataBrand.ch,
            backgroundColor: '#ff3823',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('ug')) {
        datasets.push(
          {
            label: 'UG.com',
            data: dataBrand.ug,
            backgroundColor: '#ffa834',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('kpde')) {
        datasets.push(
          {
            label: 'FranchiseKOMPARATOR DE',
            data: dataBrand.kpde,
            backgroundColor: '#9C29B7',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('kpat')) {
        datasets.push(
          {
            label: 'FranchiseKOMPARATOR AT',
            data: dataBrand.kpat,
            backgroundColor: '#B7298B',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('kpch')) {
        datasets.push(
          {
            label: 'FranchiseKOMPARATOR CH',
            data: dataBrand.kpch,
            backgroundColor: '#B72944',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('kpug')) {
        datasets.push(
          {
            label: 'FranchiseKOMPARATOR UG.com',
            data: dataBrand.kpug,
            backgroundColor: '#5E2BFF',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('cbde')) {
        datasets.push(
          {
            label: 'Chatbot DE',
            data: dataBrand.cbde,
            backgroundColor: '#00A4D3',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('cbat')) {
        datasets.push(
          {
            label: 'Chatbot AT',
            data: dataBrand.cbat,
            backgroundColor: '#E31610',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('cbch')) {
        datasets.push(
          {
            label: 'Chatbot CH',
            data: dataBrand.cbch,
            backgroundColor: '#FBF37D',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }

      return datasets
    },
    async mapLineChartData (apiData, dataColCount, startDate) {
      this.chartLabels = []

      const dataBrand = {
        de: new Array(dataColCount).fill(0),
        at: new Array(dataColCount).fill(0),
        ch: new Array(dataColCount).fill(0)
      }
      const brandsWithLeads = {
        de: new Array(dataColCount).fill(0).map(() => ([])),
        at: new Array(dataColCount).fill(0).map(() => ([])),
        ch: new Array(dataColCount).fill(0).map(() => ([]))
      }
      const avgBrandData = {
        de: new Array(dataColCount).fill(0).map(() => ({
          min: { BrandID: 0, Count: 1000 },
          max: { BrandID: 0, Count: 0 },
          BrandCount: this.avgBrands.de
        })),
        at: new Array(dataColCount).fill(0).map(() => ({
          min: { BrandID: 0, Count: 1000 },
          max: { BrandID: 0, Count: 0 },
          BrandCount: this.avgBrands.at
        })),
        ch: new Array(dataColCount).fill(0).map(() => ({
          min: { BrandID: 0, Count: 1000 },
          max: { BrandID: 0, Count: 0 },
          BrandCount: this.avgBrands.ch
        }))
      }
      const portalBrands = {
        de: new Array(dataColCount).fill(0),
        at: new Array(dataColCount).fill(0),
        ch: new Array(dataColCount).fill(0)
      }
      let currentDay = startDate
      let dayFormat = 'YYYY-MM-DD'
      let labelFormat = 'DD.MM'
      if (this.groupby === 'Week') {
        dayFormat = 'GGGG/WW'
        labelFormat = dayFormat
      }
      if (this.groupby === 'Month') {
        dayFormat = 'YYYY-MM'
        labelFormat = dayFormat
      }
      const barChartDatasetsPortal = []
      for (let i = 0; i < dataColCount; i++) {
        const currentDayFormat = currentDay.format(dayFormat)
        if (!this.chartLabels.includes(currentDay.format(labelFormat))) this.chartLabels.push(currentDay.format(labelFormat))
        const dateTo = moment(currentDay)
        dateTo.add(1, this.groupby + 's')
        portalBrands.de[i] = await this.parent.SM.getActiveMembersInPeriod(
          currentDay,
          dateTo,
          1,
          this.filterBrandIDs
        )
        portalBrands.at[i] = await this.parent.SM.getActiveMembersInPeriod(
          currentDay,
          dateTo,
          3,
          this.filterBrandIDs
        )
        portalBrands.ch[i] = await this.parent.SM.getActiveMembersInPeriod(
          currentDay,
          dateTo,
          2,
          this.filterBrandIDs
        )
        // console.info(currentDay.format('YYYY-MM-DD'), dateTo.format('YYYY-MM-DD'))
        if (apiData != null) {
          await Promise.all(apiData.map(async thisData => {
            let doContinue = false
            const splitKey = thisData.Key.split('~')
            // console.info(currentDay, currentDay.format(dayFormat), splitKey[0], thisData)
            if (splitKey[0] === currentDayFormat) {
              doContinue = true
              const portal = this.mapPortal(splitKey[1])
              const brandID = parseInt(splitKey[2])
              const count = parseFloat(thisData.Fields[0].Value)
              const combinedPortal = (portal.endsWith('de') || portal.endsWith('ug')
                ? 'de'
                : (portal.endsWith('at') ? 'at' : 'ch')
              )
              // console.info('portalID', combinedPortal, (combinedPortal === 'de' ? 1 : (combinedPortal === 'at' ? 3 : 2)), currentDayFormat)
              if (!barChartDatasetsPortal.includes(combinedPortal)) barChartDatasetsPortal.push(combinedPortal)
              this.avgBrands[combinedPortal][i] = portalBrands[combinedPortal][i].length
              if (portalBrands[combinedPortal][i].length === 0) return true
              try {
                if (portal.startsWith('cb')) return true
                // console.info('portal:', combinedPortal, 'current:', dataBrand[combinedPortal][i], count, 'portalBrands:', portalBrands, (count / portalBrands).toFixed(1), parseFloat(dataBrand[combinedPortal][i]) + (parseFloat(count) / portalBrands).toFixed(1))
                // dataBrand[combinedPortal][i] = (parseFloat(dataBrand[combinedPortal][i]) + (count / portalBrands)).toFixed(1)
                dataBrand[combinedPortal][i] += count
                if (!brandsWithLeads[combinedPortal][i].includes(brandID)) brandsWithLeads[combinedPortal][i].push(brandID)
                let brandName = ''
                if (avgBrandData[combinedPortal][i].min.Count >= count) {
                  avgBrandData[combinedPortal][i].min.Count = count
                  avgBrandData[combinedPortal][i].min.BrandID = brandID
                  brandName = this.parent.SM.lists.brands.find(thisbrand => thisbrand.BrandID === brandID)
                  if (brandName != null) avgBrandData[combinedPortal][i].min.Name = brandName.Name
                  else avgBrandData[combinedPortal][i].min.Name = brandID
                  // console.info('min', i, combinedPortal, count, brandID, avgBrandData[combinedPortal][i].min.Count, avgBrandData[combinedPortal][i].min.BrandID, avgBrandData[combinedPortal][0].min)
                }
                if (avgBrandData[combinedPortal][i].max.Count < count) {
                  // console.info('max', i, combinedPortal, count, brandID, avgBrandData[combinedPortal][i].max.Count, avgBrandData[combinedPortal][i].max.BrandID, avgBrandData[combinedPortal][0].max)
                  avgBrandData[combinedPortal][i].max.Count = count
                  avgBrandData[combinedPortal][i].max.BrandID = brandID
                  brandName = this.parent.SM.lists.brands.find(thisbrand => thisbrand.BrandID === brandID)
                  if (brandName != null) avgBrandData[combinedPortal][i].max.Name = brandName.Name
                  else avgBrandData[combinedPortal][i].max.Name = brandID
                }
              } catch (error) {
                console.error(error, 'portal: ' + combinedPortal + ' i: ' + i + ' avgBrandData: ' + JSON.stringify(avgBrandData[combinedPortal]))
              }
            } else {
              if (doContinue) return true
            }
          }))
        }
        let lastPortal = ''
        try { // Durchschnitt berechnen
          barChartDatasetsPortal.forEach(portal => {
            lastPortal = portal
            if (this.avgBrands[portal][i] > 0 && parseFloat(dataBrand[portal][i]) > 0) dataBrand[portal][i] = (parseFloat(dataBrand[portal][i]) / this.avgBrands[portal][i]).toFixed(1)
          })
          const allPortals = ['de', 'at', 'ch']
          allPortals.forEach(portal => {
            // Standardwerte setzen, wenn keine Daten in dem Portal für den Zeitraum
            avgBrandData[portal][i].BrandCount = portalBrands[portal][i].length
            if (avgBrandData[portal][i].min.Name == null || avgBrandData[portal][i].min.Count > 0) {
              for (let idx = 0; idx < portalBrands[portal][i].length; idx++) {
                const brand = portalBrands[portal][i][idx]
                if (!brandsWithLeads[portal][i].includes(brand.BrandID)) {
                  const curBrand = this.parent.SM.lists.brands.find(thisbrand => thisbrand.BrandID === brand.BrandID)
                  if (curBrand == null) continue
                  avgBrandData[portal][i].min.Count = 0
                  avgBrandData[portal][i].min.Name = curBrand.Name
                  break
                }
              }
            }
            if (avgBrandData[portal][i].max.Name == null) {
              avgBrandData[portal][i].max.Name = avgBrandData[portal][i].min.Name
            }
          })
        } catch (error) {
          console.error(error, 'portal: ' + lastPortal + ' i: ' + i)
        }
        currentDay = startDate.add(1, this.groupby.toLowerCase() + 's')
      }

      const datasets = []
      if (barChartDatasetsPortal.includes('de')) {
        datasets.push(
          {
            label: 'Ø DE',
            data: dataBrand.de,
            backgroundColor: '#2A6CAD',
            type: 'line',
            fill: false,
            yAxisID: 'yavg-line',
            avgBrandData: avgBrandData.de
          }
        )
      }
      if (barChartDatasetsPortal.includes('at')) {
        datasets.push(
          {
            label: 'Ø AT',
            data: dataBrand.at,
            backgroundColor: '#1F8C2A',
            type: 'line',
            fill: false,
            yAxisID: 'yavg-line',
            avgBrandData: avgBrandData.at
          }
        )
      }
      if (barChartDatasetsPortal.includes('ch')) {
        datasets.push(
          {
            label: 'Ø CH',
            data: dataBrand.ch,
            backgroundColor: '#EA9B9B',
            type: 'line',
            fill: false,
            yAxisID: 'yavg-line',
            avgBrandData: avgBrandData.ch
          }
        )
      }

      return datasets
    },
    getFilteredLabels (item, chart) { // Filtern der Legende
      return !item.text.includes(this.$t('page.statistics.quickview')) && !item.text.startsWith('Ø')
    },
    changeLegendText (chart) { // Textänderung der Legende
      const data = chart.data
      const legends = Array.isArray(data.datasets)
        ? data.datasets.map(function (dataset, i) {
          return {
            text: dataset.label + '   (X)',
            fillStyle: (!Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor : dataset.backgroundColor[0]),
            hidden: !chart.isDatasetVisible(i),
            lineCap: dataset.borderCapStyle,
            lineDash: dataset.borderDash,
            lineDashOffset: dataset.borderDashOffset,
            lineJoin: dataset.borderJoinStyle,
            lineWidth: dataset.borderWidth,
            strokeStyle: dataset.borderColor,
            pointStyle: dataset.pointStyle,
            datasetIndex: i
          }
        }, this)
        : []
      return legends
    },
    tooltipCallbackBeforeLabel (tooltipItem, data) {
      const current = data.datasets[tooltipItem.datasetIndex]
      const label = current.label
      const labelGroup = label.split(' ')
      if (label.endsWith(this.$t('page.statistics.brandpage'))) {
        let count = 0
        data.datasets.forEach(dataset => {
          if (
            dataset.label.startsWith(labelGroup[0]) &&
                      dataset.data[tooltipItem.index] != null
          ) {
            count += parseInt(dataset.data[tooltipItem.index])
          }
        })
        return labelGroup[0] + ' (' + this.$t('page.statistics.total') + ': ' + count + ')'
      }
      if (label.startsWith('Ø')) {
        const avgCurrent = current.avgBrandData[tooltipItem.index]
        const specialSectorGroup = (this.currentBrand != null ? this.currentBrand.SpecialSector.SpecialSectorGroup.Caption + '<br>' : '')
        if (avgCurrent != null) return 'Ø ' + specialSectorGroup + this.$t('page.statistics.nuberofbrands') + ': ' + avgCurrent.BrandCount + '<br>Max: ' + avgCurrent.max.Count + ' - ' + avgCurrent.max.Name + '<br>Min: ' + avgCurrent.min.Count + ' - ' + avgCurrent.min.Name
      }
      return ''
    },
    tooltipCallbackLabel (tooltipItem, data) {
      const label = data.datasets[tooltipItem.datasetIndex].label
      return label + ': ' + tooltipItem.yLabel
    },
    getDefaultChartData () {
      return {
        labels: [],
        datasets: [],
        options: {
          responsive: true,
          tooltips: {
            enabled: false,
            mode: 'label',
            callbacks: {}
          },
          scales: {
            xAxes: [{ id: 'xbar-stacked', stacked: true }],
            yAxes: [
              { id: 'ybar-stacked', stacked: true, ticks: { stepSize: this.scaleYstepSize ? this.scaleYstepSize : 1 } }
            ]
          },
          legend: {
            display: true,
            labels: {}
          },
          maintainAspectRatio: false
        }
      }
    }
  },
  watch: {
    'currentBrand.BrandID': function () {
      this.getChartData()
    },
    startDate: function () {
      if (typeof this.loadCounter !== 'undefined') return
      this.getChartData()
    },
    endDate: function () {
      if (typeof this.loadCounter !== 'undefined') return
      this.getChartData()
    },
    groupby: function () {
      if (typeof this.loadCounter !== 'undefined') return
      this.getChartData()
    },
    filterByBrandIDs: function () {
      this.getChartData()
    },
    showAvgData: function () {
      this.getChartData()
    },
    loadCounter: function () {
      this.getChartData()
    }
  },
  mounted: function () {
    this.getChartData()
  }
}
</script>

<style lang="less" scoped>
.mb-bar-chart {
  min-height: 400px;
  position: relative;
}
.mb-no-chart-data, .mb-loading {
  background-color: rgba(255,255,255,0.5);
  position: absolute;
  display: grid;
  place-items: center;
  height: calc(100% - (1.5rem * 2));
  width: calc(100% - (1.5rem * 2));
  font-weight: 700;
  font-size: 20px;
}
</style>
