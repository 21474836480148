import Vue from 'vue'
import moment from 'moment'

let _Api = null
let _ApiStats = null

export default class StatisticMethods extends Vue {
  lists = {
    specialsectorgroups: [],
    memberships: [],
    brands: []
  }

  constructor (Api, ApiStats) {
    super()
    if (Api) _Api = Api
    if (ApiStats) _ApiStats = ApiStats
  }

  async getActiveMembersInPeriod (dateFrom, dateTo, portalID, brandIDs) {
    const result = []
    // console.info('getActiveMembersInPeriod start', portalID, dateFrom.format('YYYY-MM-DD'), dateTo.format('YYYY-MM-DD'), this.lists.memberships.length, brandIDs)
    brandIDs = await brandIDs
    await Promise.all(this.lists.memberships.forEach(membership => {
      const startDate = moment(membership.StartDate, 'YYYY-MM-DDTHH:m:s')
      const endDate = moment(membership.EndDate, 'YYYY-MM-DDTHH:m:s')
      // console.info('getActiveMembersInPeriod start', startDate, membership.StartDate, endDate, membership.EndDate)
      if (
        membership.PortalID === portalID &&
                (brandIDs == null || brandIDs.length === 0 || brandIDs.includes(membership.BrandID)) &&
                (membership.EndDate == null ||
                    (endDate >= dateFrom && endDate <= dateTo)
                ) &&
                startDate <= dateTo
      ) {
        // console.info('getActiveMembersInPeriod OK', membership.PortalID, membership.BrandID, membership.StartDate, membership.EndDate)
        result.push(membership)
      } else {
        // console.info('getActiveMembersInPeriod includes', brandIDs.includes(membership.BrandID))
        // if (brandIDs.includes(membership.BrandID) && membership.PortalID === portalID) console.info('getActiveMembersInPeriod not ok', portalID, membership.startDate, membership.EndDate, (membership.EndDate == null || (endDate >= dateFrom && endDate <= dateTo)), startDate <= dateTo)
      }
    })
    )
    // console.info('getActiveMembersInPeriod end', result, portalID)
    return result
  }

  async getBrandBookings () {
    const params = {
      ResFrom: 1,
      ResTo: 999,
      FullSubEntities: false,
      Filter: [
        {
          Field: 'ProductName',
          Operator: 'EQ',
          Value: 'Membership'
        }
      ]
    }
    await _Api._post('brandbookings/', params).then((response) => {
      this.lists.memberships = response.body.Result.Data
      const brandIDs = []

      this.lists.memberships.forEach(membership => {
        if (!brandIDs.includes(membership.BrandID)) {
          brandIDs.push(membership.BrandID)
        }
      })

      this.getBrandList(brandIDs)
    })
    return this.lists.memberships
  }

  async getDetailBOChartData (portal, brandID, tileString, startDate, endDate) {
    const momentStarDate = moment(startDate, 'DD.MM.YYYY')
    const momentEndDate = moment(endDate, 'DD.MM.YYYY')
    const postData = {
      country: portal,
      tile: tileString,
      brandid: brandID,
      startdate: momentStarDate.format('YYYY-MM-DD'),
      enddate: momentEndDate.format('YYYY-MM-DD')
    }
    return _ApiStats._post('brand', postData).then((response) => {
      return response
    }).catch((error) => {
      console.error('error', error)
      return false
    })
  }

  async getBrandList (brandIDs) {
    const params = {
      ResFrom: 1,
      ResTo: 999,
      FullSubEntities: false,
      QueryMode: 108,
      Filter: [
        {
          Field: 'BrandID',
          Operator: 'IN',
          Value: brandIDs.join(',')
        }
      ]
    }
    await _Api._post('brands/', params).then((response) => {
      this.lists.brands = response.body.Result.Data
    })

    return this.lists.brands
  }

  async getBrandsBySpecialSectorGroup (specialSectorGroup) {
    const specialSectors = []
    if (specialSectorGroup.SpecialSectors == null) {
      if (this.lists.specialsectorgroups.length === 0) await this.getSpecialSectorGroups()
      const specialSectorGroups = this.lists.specialsectorgroups.filter((group) => {
        return (group.SpecialSectorGroupID === specialSectorGroup.SpecialSectorGroupID)
      })
      specialSectorGroup = specialSectorGroups[0]
    }
    specialSectorGroup.SpecialSectors.forEach(sector => {
      specialSectors.push(sector.SpecialSectorID)
    })
    const brandIDs = []
    const response = await this.getBrandsBySpecialSectors(specialSectors)
    const brands = response.body.Result.Data
    if (brands == null) return []

    brands.forEach(brand => {
      brandIDs.push(brand.BrandID)
    })

    return brandIDs
  }

  async getBrandsBySpecialSectors (specialSectors) {
    const postData = {
      ResFrom: 1,
      ResTo: 1000,
      FullSubEntities: false,
      QueryMode: 108,
      Filter: [
        {
          Field: 'SpecialSectorID',
          Operator: 'IN',
          Value: specialSectors.join(',')
        }
      ]
    }
    const result = await _Api._post('brands/', postData)

    return result
  }

  async getSpecialSectorGroupBrandIDs (specialSectorID) {
    if (specialSectorID == null) return

    let specialSectorGroupID = null
    let brandIDs = []
    const specialSector = await this.getSpecialSectorByID(specialSectorID)
    if (specialSector.body.Result.Data == null || specialSector.body.Result.Data.length === 0) return

    specialSectorGroupID = specialSector.body.Result.Data.SpecialSectorGroup.SpecialSectorGroupID
    const specialSectorGroup = await this.getSpecialSectorGroupByID(specialSectorGroupID)
    if (specialSectorGroup.body.Result.Data == null || specialSectorGroup.body.Result.Data.length === 0) return

    brandIDs = await this.getBrandsBySpecialSectorGroup(specialSectorGroup.body.Result.Data)

    return brandIDs
  }

  async getSpecialSectorByID (specialSectorID) {
    const postData = {
      SpecialSectorID: specialSectorID,
      FullSubEntities: true
    }
    return _Api._post('SpecialSector/', postData)
  }

  async getSpecialSectorGroupByID (specialSectorGroupID) {
    const postData = {
      SpecialSectorGroupID: specialSectorGroupID,
      FullSubEntities: true
    }
    return _Api._post('SpecialSectorGroup/', postData)
  }

  getSpecialSectorGroups () {
    return _Api._post('specialsectorgroups/', { ResFrom: 1, ResTo: 999, FullSubEntities: true }).then((response) => {
      if (response.body.Result) this.lists.specialsectorgroups = response.body.Result.Data
    })
  }

  getSourceModuleByPortalIDs (portalIDs) {
    const sourceModules = []
    portalIDs.forEach(portalID => {
      if (portalID === 1) {
        sourceModules.push('FranchisePORTAL.DE')
        sourceModules.push('FranchiseKomparator.DE')
        sourceModules.push('Unternehmer-Gesucht.com')
        sourceModules.push('FranchiseKomparator.UG')
      }
      if (portalID === 2) {
        sourceModules.push('FranchisePORTAL.CH')
        sourceModules.push('FranchiseKomparator.CH')
      }
      if (portalID === 3) {
        sourceModules.push('FranchisePORTAL.AT')
        sourceModules.push('FranchiseKomparator.AT')
      }
    })

    return sourceModules
  }
}
