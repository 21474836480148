<template>
  <div v-if="app.isLoggedIn">
    <div class="container mb-brand-select">
      <div class="mb-top-desc">
        <h1 v-t="'page.brandselection.title'"></h1>
        <div v-if="!isAdmin||1">
          <p v-t="'page.brandselection.whichbrand'"></p>
          <p v-t="'page.brandselection.description'"></p>
          <p v-if="loading" v-t="'page.brandselection.loading'"></p>
          <p
            v-if="availableBrands.length===0&&loading===false&&!isAdmin"
             v-t="'page.brandselection.nobrands'"
          ></p>
        </div>
      </div>
      <div v-if="isAdmin">
        <div class="row justify-content-center pt-3 gap-3">
          <form class="col-3 d-flex gap-3 flex-column" @submit.prevent="loadBrandFromInput">
            <div class="d-flex flex-column gap-1">
              <div class="text-center">
                <label class="white" for="brandIdInputField" v-t="'page.brandselection.adminbrandselection'"></label>
              </div>
              <input id="brandIdInputField" v-model="brandInput" type="text" class="form-control text-center" placeholder="Marken-ID" />
            </div>
            <button class="btn btn-primary flex-shrink-0" type="submit" :disabled="brandInput.length <= 0">Lade Marke</button>
          </form>
        </div>
      </div>
      <template v-else>
        <ul v-if="availableBrands.length" class="mb-brands">
          <li
            v-for="brand in availableBrands"
            :key="brand.BrandID"
            class="d-flex flex-column justify-content-between"
          >
            <a href="javascript:" @click.prevent="changeBrand(brand.BrandID)">
              <div class="mb-img-cont">
                <img :src="$parent.getImage(brand.Logo, brand.LogoID)" class="mb-logo" />
              </div>
            </a>
            <div class="text-center">
              <img
                v-show="brand.ComParamOriginCountryID>0"
                :src="$parent.getCountryIcon(brand)"
                class="mb-flag"
              />
            </div>

            <div class="mb-brand-name text-center">{{ brand.Name }}</div>

            <small v-if="brand.Hint" class="text-center">{{ brand.Hint }}</small>

            <a
              href="javascript:"
              class="btn"
              @click.prevent="changeBrand(brand.BrandID)"
              v-t="'page.brandselection.todashboard'"
            ></a>
          </li>
          <li class="mb-new d-flex flex-column">
            <a href="javascript:" @click="goToNewBrandPage">
              <div class="mb-img-cont">
                <label class="icon-plus" v-t="'page.brandselection.newbrand'"></label>
              </div>
            </a>
            <p v-t="'page.brandselection.newbrandquestion'"></p>
            <a
              href="javascript:"
              class="btn"
              @click.prevent="goToNewBrandPage()"
              v-t="'page.brandselection.addbrand'"
            ></a>
          </li>
        </ul>
        <ul v-else class="mb-brands">
          <li class="mb-new d-flex flex-column">
            <a href="javascript:" @click="goToNewBrandPage">
              <div class="mb-img-cont">
                <label class="icon-plus" v-t="'page.brandselection.newbrand'"></label>
              </div>
            </a>
            <p v-t="'page.brandselection.newbrandquestion'"></p>
            <a
              href="javascript:"
              class="btn"
              @click.prevent="goToNewBrandPage()"
              v-t="'page.brandselection.addbrand'"
            ></a>
          </li>
        </ul>
      </template>

    </div>
  </div>
</template>

<script>

import { useBrandStore } from '@/stores/brandStore'

export default {
  name: 'BrandSelection',
  components: {
  },
  props: {},
  data: function () {
    return {
      loading: false,
      loader: null,
      bodyClass: 'mb-login',
      brandInput: '',
      formDataErrors: {
        System: false,
        MainSectors: false
      },
      brandStore: useBrandStore()
    }
  },
  inject: ['changeBrand'],
  computed: {
    availableBrands: function () {
      return this.app.availableBrands
    },
    firstAvailableBrand () {
      if (this.availableBrands.length > 0) {
        const firstBrand = Object.assign({}, this.availableBrands[0])
        return firstBrand
      }
      return null
    },
    firstAvailableBrandName () {
      return this.firstAvailableBrand
        ? this.firstAvailableBrand.Company.Name
        : ''
    },
    newBrandModal () {
      return this.$refs.addBrandModal
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    isAdmin: function () {
      return this.app.isAdministrator
    },
    currentBrand: function () {
      return this.$parent.currentBrand || {}
    },
    preloads: function () {
      return this.$parent.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    },
    brandManagerRoleID: function () {
      const brandManagerRole = this.preloads.brandroles
        .filter(function (brandrole) {
          return brandrole.Caption === 'Markenverwalter'
        })
        .shift()
      return brandManagerRole ? brandManagerRole.BrandRoleID : false
    }
  },
  watch: {
    $route (to) {
      if (to.path === '/markenauswahl') {
        this.checkForBrand()
      }
    },
    'app.hasCurrentBrand': function (newValue) {
      newValue && this.checkForBrand()
    }
  },
  mounted: function () {
    this.loadAvailableBrands()
  },
  methods: {
    loadAvailableBrands: async function () {
      this.loading = true
      try {
        if (this.isAdmin && typeof this.$route.params.brandId !== 'undefined') {
          const brandId = Number(this.$route.params.brandId)
          await this.changeBrand(brandId)
          this.$router.push('/profil')
        } else {
          await this.app.loadAvailableBrandsIfEmpty()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    checkForBrand () {
      if (this.brandStore.hasCurrentBrand && this.$route.name !== 'Dashboard') {
        this.brandStore.setCurrentBrandID(this.app.currentBrand.BrandID)
        this.$router.push('/dashboard')
      }
    },
    goToNewBrandPage () {
      this.$router.push('/markeanlegen')
    },
    showErrorOnNoBrands () {
      if (this.isAdmin || this.$route.name === 'Login') return
      this.$swal(
        this.$t('popupmessage.error.title'),
        this.$t('popupmessage.error.nobrands'),
        'error'
      )
      this.$router.push('/login')
    },
    loadBrandFromInput () {
      if (this.isAdmin && this.brandInput) {
        this.changeBrand(this.brandInput)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.white {
  color: white;
}
.mb-brand-select {
  margin-bottom: 100px;

  .mb-top-desc {
    text-align: center;
    margin-top: 70px;
    color: white;
  }

  h1 {
    font-size: 30px;
    margin-bottom: 40px;
  }

  .mb-brands {
    display: flex;
    margin-top: 100px;
    justify-content: space-evenly;

    li {
      width: 238px;
      position: relative;
      padding-bottom: 60px;
      color: white;

      .btn {
        width: 100%;
        background-color: transparent;
        border-color: white;
        color: white;
        text-transform: uppercase;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 30px;
        bottom: 0px;
        cursor: pointer;
      }

      .mb-brand-name {
        font-weight: 700;
        font-size: 18px;
      }

      .mb-flag {
        height: 14px;
        margin-top: 15px;
        margin-bottom: 15px;
      }
      .mb-logo {
        max-width: 239px;
        width: 100%;
      }
    }

    li.mb-new {
      a {
        text-decoration: none;
      }

      .mb-img-cont {
        width: 238px;
        height: 163px;
        border: 1px solid white;
        text-align: center;
        display: flex;
        align-items: center;
        color: white;
        cursor: pointer;

        label {
          cursor: pointer;
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          position: relative;
          padding-left: 30px;

          &::after {
            position: absolute;
            left: 22px;
            top: 12px;
            align-items: center;
            font-size: 30px;
          }
        }
      }

      p {
        margin-top: 30px;
      }
    }
  }
}
.errorMessage {
  color: red;
}
.mb-brand-select .mb-brands li {
  padding: 0.33rem;
}
.mb-brand-select .mb-brands li a {
  display: block;
}
</style>
