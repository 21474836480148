<template>
  <div class="booking-content" v-if="hasCurrentBrand">
    <div class="mb-main-content">
      <div class="row mb-nl-top">
        <div class="col-lg-6 mb-nl-leftcol">
          <div class="mb-nl-prev">
            <img :src="require('../../assets/images/icons/newsletter-mail.png')">
            <h4 v-t="'page.newsletter.title'"></h4>
            <div v-t="'page.newsletter.description'"></div>
            <img :src="require('../../assets/images/nl/mb-screenshot-newsletter.png')">
          </div>
        </div>
        <div class="col-lg-6 mb-nl-rightcol">
          <div
            id="miniextensions-iframe-embed-R3OmO2Vb6iibJSoK2uYU"
            class="mb-ext-lst"
            ref="nlFrame"
            v-if="app.isActiveMember && $i18n.locale == 'de'"
          ></div>
          <div class="card" v-if="!app.isActiveMember">
            <div class="card-body">
              <span v-t="'page.newsletter.membershiprequiredpart1'"></span>
              <router-link to="/mitgliedschaft-buchen">{{ $t('page.newsletter.membership') }}</router-link>
              <span v-t="'page.newsletter.membershiprequiredpart2'"></span>
            </div>
          </div>
          <div style="display: grid; place-items: center; height: 100%" v-if="app.isActiveMember && $i18n.locale != 'de'">
            <h3>
              <p>Feel free to contact us and find out which<br>mailing slots are still available:</p>
              <p><a href="mailto:contact@franchiseportal.de">contact@franchiseportal.de</a><br>Tel.: +49 (0) 22 41 / 2 55 09-0</p>
            </h3>
          </div>
        </div>
      </div>
      <div v-if="app.isActiveMember && $i18n.locale == 'de'" class="mt-5 me-3 d-flex justify-content-end align-items-center">
      <div class="me-4 d-flex align-items-center"><i style="color: #005578; font-size: 30px;" class="fa fa-info-circle me-2"></i><span v-t="'page.newsletter.standalonebookinginfo'"></span></div>
        <a class="blue-btn" :href="computedNewsletterOverviewLink" target="_blank">
          <i class="fa fa-calendar me-2" aria-hidden="true"></i>
          <span v-t="'page.newsletter.booked'">Material-Upload / Buchungsübersicht</span>
        </a>
      </div>
    </div>
    <NewsletterShowcase />
  </div>
</template>

<script>
import Config from '../../config'
import NewsletterShowcase from './NewsletterShowcase'
import * as Ladda from 'ladda'

export default {
  name: 'TopBrand',
  props: [],
  data: function () {
    return {
      scriptIsLoaded: false,
      scriptInterval: null
    }
  },
  components: {
    NewsletterShowcase
  },
  computed: {
    hasCurrentBrand () {
      return typeof this.currentBrand.BrandID !== 'undefined'
    },
    currentBrand () {
      const current = this.$parent.currentBrand || {}
      return current
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    computedNewsletterOverviewLink () {
      const loginFromStorage = localStorage.getItem('FPMB_Login')
      const login = loginFromStorage ? JSON.parse(loginFromStorage) : {}
      const userId = login.user.UserID
      const brandId = String(this.currentBrand.BrandID)
      const username = login.user.Name
      return Config.API_STRIPE + 'getNewsletterOverviewLink' + '?' + `UserID=${userId}&BrandID=${brandId}&Username=${username}&token=${login.token}`
    }
  },
  methods: {
    startLoader () {
      if (
        typeof this.$refs.nlFrame !== 'undefined' &&
        typeof this.$refs.nlFrame.loader !== 'undefined'
      ) {
        this.$refs.nlFrame.loader.start()
      }
    },
    setNewsletterFrame () {
      if (!this.app.isActiveMember || this.$i18n.locale !== 'de') return
      const nlElm = document.getElementById('miniextensions-iframe-embed-R3OmO2Vb6iibJSoK2uYU')
      if (nlElm == null || this.scriptIsLoaded) {
        if (this.scriptInterval != null) return
        this.scriptInterval = setInterval(() => {
          this.setNewsletterFrame()
        }, 1000)
        return
      }
      const nlScript = document.createElement('script')
      nlScript.setAttribute('src', 'https://api.miniextensions.com/v1/iframe-embed/R3OmO2Vb6iibJSoK2uYU.js?absoluteShareUrl=https%3A%2F%2Fapp.miniextensions.com%2Fgrid-editor%2FR3OmO2Vb6iibJSoK2uYU&height=580')
      nlElm.appendChild(nlScript)
      this.scriptIsLoaded = true
      clearInterval(this.scriptInterval)
    }
  },
  mounted: function () {
    this.setNewsletterFrame()
    this.$refs.nlFrame.loader = Ladda.create(this.$refs.nlFrame)
    this.startLoader()
  },
  watch: {
    $route (to) {
      if (to.path === '/newsletter-buchen') this.setNewsletterFrame()
    }
  }
}
</script>

<style lang="less" scoped>
.mb-first-container {
  margin-top: 80px;
}
.dashboard-logo {
  max-width: 350px;
}
.blueCard {
  background-color: #019FE0;
  border-radius: 10px;
  display: block;
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 1rem;
}
.blueCard span {
  text-transform: uppercase;
  font-weight: bold;
}

.mb-main-content {
  background-color: #f3f3f3;
  padding-bottom: 60px !important;
  min-height: inherit !important;
}

.mb-nl-prev {
  background: url("../../assets/images/waves-bg.svg") no-repeat;
  background-size: cover;
  text-align: center;
  padding-top: 40px;
  color: #fff;
  font-size: 20px;
  min-height: 600px;

  h4 {
    font-weight: 700;
  }

  img:first-child {
    width: 60px;
    margin-bottom: 30px;
  }

  img:last-child {
    margin-top: 20px;
    width: 600px;
  }
}

.mb-nl-leftcol {
  padding-right: 0px;
  h4 {
    color: white;
  }
}

.mb-nl-rightcol {
  padding-left: 0px;

  .card {
    border: none;
    border-radius: 0;
    height: 100%;
  }
}

.mb-ext-lst {
  background-color: #fff;
  width: 100% !important;
  padding-top: 20px;
  min-height: 600px;

  iframe {
    width: 100% !important;
  }
}

.blue-btn {
  background-color: rgba(0,85,120,1.0);
  border: 0;
  box-shadow: 0 0 4px #d7d7d6;
  color: white;
  padding: 13px;
  text-decoration: none;
}
.blue-btn:hover {
  background-color: rgba(0,85,120,.75);
}
</style>
