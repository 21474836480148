<template>
  <div class="d-flex justify-content-between align-items-center p-4">
    <router-link to="/" class="fu-logo-link">
      <img :src="computedLogo" alt="FranchiseUNIVERSUM" />
    </router-link>
    <div class="d-flex justify-content-end align-items-center gap-4">
      <LocaleSwitcher />
      <router-link
        v-if="app.isLoggedIn"
        to="/logout"
        class="nav-link d-flex align-items-center gap-2"
      >
        <span>{{ $t('menue.logout') }}</span>
        <i class="fa fa-sign-out" aria-hidden="true"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
import LocaleSwitcher from '@/components/LocaleSwitcher'
export default {
  name: 'PublicHeader',
  components: {
    LocaleSwitcher
  },
  computed: {
    app () {
      return this.$root.getApp() || {}
    },
    computedLogo () {
      return require('@/assets/images/fu-logo-white.png')
    }
  }
}
</script>

<style lang="scss" scoped>

.nav-link {
  color: white;
  font-weight: bold;
}

</style>
