import Vue from 'vue'
import VueResource from 'vue-resource'
import Config from '../config/index'
import CryptoJS from 'crypto-js'
import moment from 'moment'

const param = require('jquery-param')

Vue.use(VueResource)

Vue.http.options.root = Config.FPSERVICE_URL

// Add an "after" method to Vue.$http
Vue.http.interceptors.push(function (request, next) {
  next(response => {
    if (request.after) {
      request.after.call(this, response)
    }
  })
})

export default class ApiStats extends Vue {
  activeCalls = 0
  apiUrl = Config.FPDE_API_URL + 'stats/'

  _call (method, action, data, headers) {
    Vue.http.options.root = this.apiUrl
    if (!headers) headers = {}
    if (method.toLowerCase() === 'get' || method.toLowerCase() === 'delete') {
      const qstrg = data === undefined ? '' : param(data)
      if (qstrg && qstrg !== '') { action += (action.indexOf('?') >= 0 ? '&' : '?') + param(data) }
      data = null
    } else if (headers['Content-Type'] === 'application/json') {
      const randomString = CryptoJS.lib.WordArray.random(16).toString()
      const datetime = moment().toISOString()
      const hash = CryptoJS.SHA256(data.brandid + randomString + datetime).toString()
      data.hash = hash
      data.randomString = randomString
      data.datetime = datetime
      data = JSON.stringify(data)
    }

    const httpArgs = {
      headers,
      before: () => {
        this.activeCalls++
      },
      after: () => {
        this.activeCalls--
      }
    }
    if (
      method.toLowerCase() === 'post' &&
      headers['Content-Type'] !== 'application/json'
    ) { httpArgs.emulateJSON = true }

    let promise
    switch (method.toLowerCase()) {
      case 'get':
        promise = Vue.http.get(action, httpArgs)
        break
      case 'post':
        promise = Vue.http.post(action, data, httpArgs)
        break
      case 'put':
        promise = Vue.http.put(action, data, httpArgs)
        break
      case 'delete':
        promise = Vue.http.delete(action, httpArgs)
        break
    }

    promise
      .then(response => {
        const errMsg = Vue.Utils.getObjVal(response, 'body.Error') || ''
        const error = Vue.Utils.getObjVal(response, 'body.Error') || ''
        if (error !== 0 && error !== false && error !== '') {
          console.error(errMsg)
        } else if (
          errMsg !== '' &&
          errMsg !== false &&
          errMsg !== 'No records selected.' &&
          errMsg !== 'No records found.'
        ) {
          console.error(errMsg)
        }
        return response
      })
      .catch(err => {
        console.error('API Stats Error:', err)
      })
    return promise
  }

  _post (action, data) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    return this._call('POST', action, data, headers)
  }

  _get (action, data) {
    const headers = {
      Accept: 'application/json'
    }
    return this._call('GET', action, data, headers)
  }

  _delete (action, data) {
    const headers = {
      Accept: 'application/json'
    }
    return this._call('DELETE', action, data, headers)
  }

  _put (action, data) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    return this._call('PUT', action, data, headers)
  }
}
