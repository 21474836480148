<template>
  <FairFieldEditor title="Teaser" :index="2" buttonSaveText="Korrekturwunsch absenden"
    :showContentOnEdit="true" :loading="isLoading" @save="handleSave" @adminSave="handleAdminSave" :isEditing="isEditing"
    :saveDisabled="!computedIsValid" :is-modal-open.sync="isModalOpen" :is-admin-modal-open.sync="isAdminModalOpen" @toggleEdit="toggleEdit" @toggle="toggleContent" :usePageStore="true" @update:isAdminModalOpen="toggleAdminEdit"
    :showOpenButton="false" v-if="computedShowStep">
    <div class="d-flex flex-column">
      {{ currentFair.Teaser }}
    </div>
    <template v-slot:edit>
      <hr />
      <div class="d-flex flex-column w-100 gap-3">
        <input type="text" class="form-control w-100" :placeholder="formFields.placeholder"
          v-model="formFields.value" maxlength="255" />
      </div>
    </template>
    <template v-slot:admin>
      <div class="d-flex flex-column w-100 gap-3">
        <textarea type="text" class="form-control w-100 p-3 h-100" placeholder="Neuer Teaser"
          v-model="formFields.value"></textarea>
      </div>
    </template>

    <template v-slot:conditions>
      <div class="d-flex flex-column gap-1">
        <span class="fw-bold">Anforderungen:</span>
        <ul class="special">
          <li>Der Teaser soll direkt nach der Überschrift beschreiben, was {{ currentBrand.Name }} anbietet</li>
          <li>2-3 Sätze sind optimal</li>
        </ul>
      </div>
    </template>

    <template v-slot:editButtonCaption>
      Stimmt etwas nicht?
    </template>
    <template v-slot:preview>
      <img class="img-fluid" src="@/assets/images/new-fair/2_Teaser.png" />
    </template>
  </FairFieldEditor>
</template>

<script>
import FairFieldEditor from '@/components/FairFieldEditor.vue'
import { useBrandStore } from '@/stores/brandStore'

export default {
  name: 'FairFormTeaser',
  components: {
    FairFieldEditor
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      isAdminModalOpen: false,
      formFields: {
        placeholder: 'Tragen Sie hier Ihren neuen Vorschlag ein.',
        value: ''
      }
    }
  },
  methods: {
    toggleEdit () {
      this.isEditing = !this.isEditing
    },
    toggleAdminEdit () {
      this.formFields.value = this.currentFair.Teaser
    },
    toggleContent () {
      this.isOpen = !this.isOpen
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Korrekturwünsche wurden erfolgreich gespeichert.')
        this.isModalOpen = false
        this.formFields.value = ''
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern deiner Korrekturwünsche ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async handleAdminSave () {
      this.isLoading = true
      try {
        const brandStore = useBrandStore()
        await brandStore.updateCurrentFair({
          Teaser: this.formFields.value
        })
        this.isAdminModalOpen = false
        this.formFields.value = ''
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async submitForm () {
      await this.app.APIService._post('mb/fair/update/2', {
        brandId: this.currentBrand.BrandID,
        value: this.formFields.value
      })
    }
  },
  computed: {
    computedShowStep () {
      const currentTeaser = this.currentFair.Teaser
      return currentTeaser !== null && currentTeaser !== '' && String(currentTeaser).length > 0
    },
    computedIsValid () {
      return this.formFields.value.length > 0
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentFair: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentFair
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    },
    computedExpansionFormCaption () {
      if (this.currentBrand) {
        if (this.currentBrand.ConceptExpansionFormFranchise) return 'Franchise'
        if (this.currentBrand.ConceptExpansionFormLicense) return 'Lizenz'
      }
      return ''
    }
  }

}
</script>
