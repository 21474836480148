<template>
  <div class="mb-chatbot-showcase">
    <section class="mb-testimonials">
      <div class="container">
        <ul>
          <li v-for="(testimonial, index) in app.chatbotTestimonials" :key="index">
            <testimonial :testimonial="testimonial"></testimonial>
          </li>
        </ul>
      </div>
    </section>
    <section class="mb-chat-whatyouget">
        <div class="container">
            <div class="mb-wyg-cont">
                <h2 v-t="'page.chatbot.payonlyforleads'"></h2>
                <div class="mb-flags">
                    <img :src="require('../../assets/images/flags/de.svg')">
                    <img :src="require('../../assets/images/flags/at.svg')">
                    <img :src="require('../../assets/images/flags/ch.svg')">
                </div>
                <ul>
                    <li><i class="fa fa-check-circle"></i><span v-t="'page.chatbot.feature6'"></span></li>
                    <li><i class="fa fa-check-circle"></i><span v-t="'page.chatbot.feature7'"></span></li>
                    <li><i class="fa fa-check-circle"></i><span v-t="'page.chatbot.feature1'"></span></li>
                    <li><i class="fa fa-check-circle"></i><span v-t="'page.chatbot.feature3'"></span></li>
                    <li><i class="fa fa-check-circle"></i><span v-t="'page.chatbot.feature13'"></span></li>
                    <li><i class="fa fa-check-circle"></i><span v-t="'page.chatbot.feature5'"></span></li>
                    <li><i class="fa fa-check-circle"></i><span v-t="'page.chatbot.feature2'"></span></li>
                    <li><i class="fa fa-check-circle"></i><span v-t="'page.chatbot.feature8'"></span></li>
                    <li><i class="fa fa-check-circle"></i><span v-t="'page.chatbot.feature10'"></span></li>
                    <li><i class="fa fa-check-circle"></i><span v-t="'page.chatbot.feature12'"></span></li>
                </ul>
            </div>
        </div>
    </section>
    <section class="mb-chatbot-test">
        <div class="container">
            <div class="mb-cb-cont">
                <h2 v-t="'page.chatbot.testit'"></h2>
                <div id="myLandbot" style="width: 100%; height: 500px;"></div>
            </div>
        </div>
    </section>
    <section class="mb-six-rules" id="chatbot-spielregeln">
        <div class="container">
            <div class="mb-rule-cont">
                <h2 v-t="'page.chatbot.rules.title'"></h2>
                <b-card no-body class="mb-acc">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block href="javascript:" v-b-toggle.cb-acc-1 variant="info">{{ $t('page.chatbot.rules.rule1title') }}<i class="icon-pfeilrunter"></i></b-button>
                    </b-card-header>
                    <b-collapse id="cb-acc-1" accordion="cb-accordion" role="tabpanel">
                        <b-card-body>
                        <b-card-text>
                            <ul v-html="$t('page.chatbot.rules.rule1desc1')"></ul>
                            <h4 v-t="'page.chatbot.rules.rule1desc2'"></h4>
                            <p v-t="'page.chatbot.rules.rule1desc3'"></p>

                            <h4 v-t="'page.chatbot.rules.rule1desc4'"></h4>
                            <ul v-html="$t('page.chatbot.rules.rule1desc5')"></ul>
                        </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-acc">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block href="javascript:" v-b-toggle.cb-acc-2 variant="info">{{ $t('page.chatbot.rules.rule2title') }}<i class="icon-pfeilrunter"></i></b-button>
                    </b-card-header>
                    <b-collapse id="cb-acc-2" accordion="cb-accordion" role="tabpanel">
                        <b-card-body>
                        <b-card-text>
                            <p><strong v-t="'page.chatbot.rules.rule2desc1'"></strong></p>

                            <h4 v-t="'page.chatbot.rules.rule2desc2'"></h4>
                            <p v-html="$t('page.chatbot.rules.rule2desc3', {
                              costLimit: computedConfig.CHATBOT_MINIMUM_COST
                            })"></p>
                            <p v-t="'page.chatbot.rules.rule2desc4'"></p>
                            <p v-html="$t('page.chatbot.rules.rule2desc5')"></p>
                            <p></p><div class="su-spacer" style="height:10px"></div><p></p>
                            <h4 v-t="'page.chatbot.rules.rule2desc6'"></h4>
                            <p v-t="'page.chatbot.rules.rule2desc7'"></p>
                        </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-acc">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block href="javascript:" v-b-toggle.cb-acc-3 variant="info">{{ $t('page.chatbot.rules.rule3title') }}<i class="icon-pfeilrunter"></i></b-button>
                    </b-card-header>
                    <b-collapse id="cb-acc-3" accordion="cb-accordion" role="tabpanel">
                        <b-card-body>
                        <b-card-text>
                            <p v-html="$t('page.chatbot.rules.rule3desc1')"></p>

                            <h4 v-t="'page.chatbot.rules.rule3desc2'"></h4>
                            <p v-t="'page.chatbot.rules.rule3desc3'"></p>
                            <p v-html="$t('page.chatbot.rules.rule3desc4')"></p>

                            <h4 v-t="'page.chatbot.rules.rule3desc5'"></h4>
                            <p v-t="'page.chatbot.rules.rule3desc6'"></p>
                            <p v-html="$t('page.chatbot.rules.rule3desc7')"></p>

                            <h4 v-t="'page.chatbot.rules.rule3desc8'"></h4>
                            <p v-t="'page.chatbot.rules.rule3desc9'"></p>

                            <h4 v-t="'page.chatbot.rules.rule3desc10'"></h4>
                            <p v-t="'page.chatbot.rules.rule3desc11'"></p>
                            <p v-t="'page.chatbot.rules.rule3desc12'"></p>
                        </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-acc">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block href="javascript:" v-b-toggle.cb-acc-4 variant="info">{{ $t('page.chatbot.rules.rule4title') }}<i class="icon-pfeilrunter"></i></b-button>
                    </b-card-header>
                    <b-collapse id="cb-acc-4" accordion="cb-accordion" role="tabpanel">
                        <b-card-body>
                        <b-card-text>
                            <p v-t="'page.chatbot.rules.rule4desc1'"></p>
                            <p v-t="'page.chatbot.rules.rule4desc2'"></p>
                        </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-acc">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block href="javascript:" v-b-toggle.cb-acc-5 variant="info">{{ $t('page.chatbot.rules.rule5title') }}<i class="icon-pfeilrunter"></i></b-button>
                    </b-card-header>
                    <b-collapse id="cb-acc-5" accordion="cb-accordion" role="tabpanel">
                        <b-card-body>
                        <b-card-text>
                            <p><strong v-t="'page.chatbot.rules.rule5desc1'"></strong></p>
                            <p v-t="'page.chatbot.rules.rule5desc2'"></p>
                            <p><strong v-t="'page.chatbot.rules.rule5desc3'"></strong></p>
                            <p v-t="'page.chatbot.rules.rule5desc4'"></p>
                        </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-acc">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block href="javascript:" v-b-toggle.cb-acc-6 variant="info">{{ $t('page.chatbot.rules.rule6title') }}<i class="icon-pfeilrunter"></i></b-button>
                    </b-card-header>
                    <b-collapse id="cb-acc-6" accordion="cb-accordion" role="tabpanel">
                        <b-card-body>
                        <b-card-text>
                            <p v-t="'page.chatbot.rules.rule6desc1'"></p>
                            <p v-t="'page.chatbot.rules.rule6desc2'"></p>
                            <p v-t="'page.chatbot.rules.rule6desc3'"></p>
                        </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
import Testimonial from '@/components/Testimonial'
import Config from '@/config'

export default {
  name: 'ChatbotShowcase',
  props: [],
  components: {
    Testimonial
  },
  data: function () {
    return {
      scriptIsLoaded: false,
      chatbotInitialized: false,
      scriptInterval: null,
      chatInitInterval: null
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    computedConfig: function () {
      return Config
    }
  },
  methods: {
    setChatbotFrame () {
      const cbElm = document.getElementById('myLandbot')
      if (cbElm == null || this.scriptIsLoaded) {
        if (this.scriptIsLoaded) {
          clearInterval(this.scriptInterval)
          return
        }
        if (this.scriptInterval != null) return
        this.scriptInterval = setInterval(() => {
          this.setChatbotFrame()
        }, 1000)
        return
      }
      this.scriptIsLoaded = true
      clearInterval(this.scriptInterval)
      const nlScript = document.createElement('script')
      nlScript.setAttribute('src', 'https://static.landbot.io/landbot-3/landbot-3.0.0.js')
      document.head.appendChild(nlScript)
      this.initChatbot()
    },
    initChatbot () {
      if (typeof Landbot === 'undefined' || this.chatbotInitialized) {
        if (this.chatInitInterval != null) return
        this.chatInitInterval = setInterval(() => {
          this.initChatbot()
        }, 1000)

        return
      }
      clearInterval(this.chatInitInterval)
      this.chatbotInitialized = true
      // eslint-disable-next-line no-undef
      const myLandbot = new Landbot.Container({
        container: '#myLandbot',
        configUrl: 'https://chats.landbot.io/v3/H-772961-QYPY3ZXO2VSKU1CW/index.json'
      })
      console.info(myLandbot)
    }
  },
  mounted: function () {
    this.setChatbotFrame()
  },
  watch: {
    $route (to) {
      if (to.path === this.$route.path) this.setChatbotFrame()
    }
  }
}
</script>

<style lang="less">
.mb-chatbot-showcase {
  .mb-testimonials {
    background-color: #f3f3f3;
    padding-top: 1px;
    padding-bottom: 35px;

    .mb-testimonial {
      width: 355px;
      padding: 10px;
    }

    ul {
      padding-left: 0px;
      display: flex;
      justify-content: space-evenly;
      list-style: none;
    }
  }

  .mb-chat-whatyouget {
      padding-bottom: 60px;
      background-color: #f3f3f3;

      .mb-wyg-cont {
          background-color: #fff;
          padding: 40px 40px 10px;

          h2 {
              font-weight: 800;
              font-size: 30px;
              text-transform: uppercase;
              text-align: center;
              margin-bottom: 40px;
          }

          .mb-flags {
              display: flex;
              justify-content: space-between;
              padding-bottom: 40px;
              border-bottom: 2px solid #000;
              margin-bottom: 40px;
          }

          img {
              width: 100px;
          }

          ul {
              padding-left: 0px;
              list-style: none;
              column-count: 2;

              li {
                  position: relative;
                  padding-left: 30px;
                  margin-bottom: 15px;
                  font-size: 14px;
              }

              i {
                  color: #72bb53;
                  position: absolute;
                  left: 0;
                  font-size: 20px;
              }
          }
      }
  }

  .mb-chatbot-test {
      background-color: #f3f3f3;
      padding-bottom: 60px;

      h2 {
          font-size: 30px;
          font-weight: 800;
          text-align: center;
          margin-bottom: 40px;
      }

      .mb-cb-cont {
          background-color: #fff;
          padding: 20px;
      }
  }

  .mb-six-rules {
      padding: 20px 0 80px;
      background-color: #f3f3f3;

      h2 {
          font-size: 30px;
          font-weight: 800;
          margin-bottom: 60px;
          color: #fff;
          text-align: center;
      }

      .mb-rule-cont {
        background-color: #444950;
        padding: 20px;
      }

      .btn {
          text-align: left;
          background-color: transparent;
          border: none;
          box-shadow: none;
          color: #444950;
          border-radius: 5px;
          display: flex;
          justify-content: space-between;

        &.not-collapsed {
            i::after {
                content: '\e625';
            }
        }
      }

      .card-header {
          border-radius: 10px;
      }

      .card {
          margin-bottom: 40px;
      }

      .card-text {
          ul {
              padding-left: 20px;

              li {
                  margin-bottom: 10px;
              }
          }

          h4 {
              margin-top: 40px;
              margin-bottom: 20px;
              font-size: 18px;
              font-weight: 700;
          }
      }
  }
}
</style>
