import { render, staticRenderFns } from "./VideoEditButton.vue?vue&type=template&id=96054ec2&scoped=true"
import script from "./VideoEditButton.vue?vue&type=script&lang=js"
export * from "./VideoEditButton.vue?vue&type=script&lang=js"
import style0 from "./VideoEditButton.vue?vue&type=style&index=0&id=96054ec2&prod&lang=scss"
import style1 from "./VideoEditButton.vue?vue&type=style&index=1&id=96054ec2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96054ec2",
  null
  
)

export default component.exports