import { render, staticRenderFns } from "./Investment.vue?vue&type=template&id=e0847502&scoped=true"
import script from "./Investment.vue?vue&type=script&lang=js"
export * from "./Investment.vue?vue&type=script&lang=js"
import style0 from "./Investment.vue?vue&type=style&index=0&id=e0847502&prod&lang=scss&scoped=true"
import style1 from "./Investment.vue?vue&type=style&index=1&id=e0847502&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0847502",
  null
  
)

export default component.exports