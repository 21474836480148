<template>
  <div class="mb-main-content">

    <div class="main-form">

      <div class="d-flex justify-content-between align-items-center">
        <h1>Meine Präsentation</h1>
        <button class="btn btn-secondary d-flex justify-content-center align-items-center preview-button" @click="openPreviewWindow">
          Vorschau anzeigen
        </button>
      </div>

      <div v-if="currentBrand">

        <b-card>

          <div class="d-flex flex-column gap-32px">
            <AdminFormSeoAttributes v-if="isAdmin" />
            <Form1Headline v-if="isSdStateComplete" />
            <Form2Teaser />
            <Form3Gallery />
            <Form4MembershipAssociation />
            <Form5Welcome />
            <Form6FoundationPurpose />
            <Form7WhatFor />
            <Form8ConecptVideo />
            <Form9Fitting v-if="isSdStateComplete" />
            <Form10Experiences />
            <Form11Conditions v-if="isSdStateComplete" />
            <Form12GoodReasons v-if="isSdStateComplete" />
            <Form13MoreVideo />
            <Form14WhatYouGet v-if="isSdStateComplete" />
            <Form15RPS v-if="0" />
            <Form16FAQ v-if="isSdStateComplete" />
            <Form17NextSteps v-if="0" />
            <Form18Videos />
            <Form19UGDescription />
          </div>

        </b-card>

      </div>
      <div class="d-flex flex-column gap-3" v-else>
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
        <b-skeleton width="80%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
      </div>

    </div>

  </div>
</template>

<script>
import Config from '@/config'
import Vue from 'vue'

import { useBrandStore } from '@/stores/brandStore'

import Form10Experiences from '@/components/fair/10_Experiences.vue'
import Form11Conditions from '@/components/fair/11_Conditions.vue'
import Form12GoodReasons from '@/components/fair/12_GoodReasons.vue'
import Form13MoreVideo from '@/components/fair/13_MoreVideo.vue'
import Form14WhatYouGet from '@/components/fair/14_WhatYouGet.vue'
import Form15RPS from '@/components/fair/15_RPS.vue'
import Form16FAQ from '@/components/fair/16_FAQ.vue'
import Form17NextSteps from '@/components/fair/17_NextSteps.vue'
import Form18Videos from '@/components/fair/18_Videos.vue'
import Form19UGDescription from '@/components/fair/19_UGDescription.vue'
import Form1Headline from '@/components/fair/1_Headline.vue'
import Form2Teaser from '@/components/fair/2_Teaser.vue'
import Form3Gallery from '@/components/fair/3_Gallery.vue'
import Form4MembershipAssociation from '@/components/fair/4_MembershipAssociation.vue'
import Form5Welcome from '@/components/fair/5_Welcome.vue'
import Form6FoundationPurpose from '@/components/fair/6_FoundationPurpose.vue'
import Form7WhatFor from '@/components/fair/7_WhatFor.vue'
import Form8ConecptVideo from '@/components/fair/8_ConceptVideo.vue'
import Form9Fitting from '@/components/fair/9_Fitting.vue'

import AdminFormSeoAttributes from '@/components/fair/Admin/SeoAttributes.vue'

export default {
  name: 'FairPresentationForm',
  components: {
    Form1Headline,
    Form2Teaser,
    Form3Gallery,
    Form4MembershipAssociation,
    Form5Welcome,
    Form6FoundationPurpose,
    Form7WhatFor,
    Form8ConecptVideo,
    Form9Fitting,
    Form10Experiences,
    Form11Conditions,
    Form12GoodReasons,
    Form13MoreVideo,
    Form14WhatYouGet,
    Form15RPS,
    Form16FAQ,
    Form17NextSteps,
    Form18Videos,
    Form19UGDescription,
    AdminFormSeoAttributes
  },
  data () {
    return {
      brandStore: useBrandStore(),
      websiteUrl: Vue.Utils.trim(Config.WEBSITE_URL, '/') + '/'
    }
  },
  methods: {
    getBrandPreviewUrl: function (preview) {
      let resUrl = this.websiteUrl
      if (this.currentBrand.ComParamOriginCountryID === 2) {
        resUrl = this.websiteUrl.replace('.de', '.at')
      } else if (this.currentBrand.ComParamOriginCountryID === 5) {
        resUrl = this.websiteUrl.replace('.de', '.ch')
      }

      return resUrl + this.currentBrand.BrandID + '?preview=' + preview
    },
    openPreviewWindow () {
      window.open(this.getBrandPreviewUrl('fair'), '_blank')
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    isAdmin () {
      return this.app.isAdministrator
    },
    currentBrand: function () {
      return this.brandStore.getCurrentBrand
    },
    currentFair: function () {
      return this.brandStore.getCurrentFair
    },
    isSdStateComplete: function () {
      return this.currentBrand.SDStatus === 5
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 0;
}

.mb-main-content {
  padding-top: 64px;
  padding-bottom: 64px;
}

.main-form {
  max-width: 939px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .preview-button {
    max-width: 335px;
    width: 100%;
  }

}
</style>

<style lang="scss">

.new-fair-modal {
  .error {
    span {
      color: var(--Error-500, #E53A14);
      font-family: "Nunito Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.main-form {
  .error {
    span {
      color: var(--Error-500, #E53A14);
      font-family: "Nunito Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  hr {
    background: #D0D5DD;
    width: 100%;
    height: 1px;
    margin: 0;
  }
}
</style>
