<!-- component template -->
<template>
  <div>
    <a href="javascript:" class="product mb-5" :class="computedAvailableClass" @click="changeSelected">

      <span :class="computedCheckboxClass">

        <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>

      </span>

      <span :class="flagClass"></span>

      <div class="prices">
        <strong class="mb-0">{{ $t('page.chatbot.productprice', { price: currentPrice.email }) }}</strong>
        <small v-if="available">{{$t('page.chatbot.productinfo', { price: currentPrice.email, telprice: currentPrice.tel })}}</small>
        <small v-if="!available" class="mb-warn">{{ $t('page.topbrand.membershiprequired', { country: countryNames[country] }) }}</small>
        <small v-if="alreadyBooked" class="mb-success">{{ $t('page.chatbot.alreadybooked', { country: countryNames[country] }) }}</small>
      </div>

    </a>
    <div v-if="value" class="mb-deckel">
      <label>{{ $t('page.chatbot.monthlyexpensecover', {
        costLimit: computedConfig.CHATBOT_MINIMUM_COST
      }) }}</label>
      <currency-input
          class="form-control"
          v-model="costLimit"
          currency="EUR"
          locale="de"
          @keyup="costLimitChange"
          :placeholder="$t('page.chatbot.expensecoverplaceholder')"
          :allow-negative=false
          :value-range={propCurrencyPrecission}
          required
        />
    </div>

  </div>
</template>

<script>
import { CurrencyInput } from 'vue-currency-input'
import Config from '@/config'

const samplePrices = {
  de: {
    email: 25,
    tel: 45
  },
  at: {
    email: 25,
    tel: 45
  },
  ch: {
    email: 25,
    tel: 45
  }
}

const currencyPrecision = {
  min: Config.CHATBOT_MINIMUM_COST
}

export default {
  name: 'NewsletterContainer',
  components: {
    CurrencyInput
  },
  props: ['value', 'price', 'country', 'available', 'alreadyBooked'],
  data: function () {
    const countryNames = {
      de: this.$t('country.de'),
      at: this.$t('country.at'),
      ch: this.$t('country.ch')
    }

    return {
      countryNames,
      costLimit: 0
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    changeSelected () {
      if (!this.available || this.alreadyBooked) return
      this.$emit('input', !this.value)
    },
    costLimitChange () {
      this.$emit('costlimit', this.costLimit)
    }
  },
  computed: {
    computedConfig () {
      return Config
    },
    currentPrice () {
      return samplePrices[this.country]
    },
    flagClass () {
      return `flag flag-${this.country}`
    },
    computedCheckboxClass () {
      const classes = ['checkbox']
      if (this.value === true) {
        classes.push('active')
      }
      return classes.join(' ')
    },
    computedAvailableClass () {
      if (!this.available || this.alreadyBooked) return 'unavailable'
      return ''
    },
    propCurrencyPrecission () {
      return currencyPrecision
    }
  }
}
</script>

<style lang="less" scoped>
a.product {

  .mb-warn {
    color:darkorange !important;
  }
  .mb-success {
    color:green !important;
  }

  small {
    font-size: 14px;
    color: #242E3D;
  }

  .prices {
    display: flex;
    flex-direction: column;

    strong {
      line-height: 1.25em;
      margin-bottom: 5px;
    }
  }
}

.mb-deckel {
  display: flex;
  flex-direction: column;
  margin-left: 168px;
  margin-bottom: 30px;
  margin-top: -40px;

  label {
    font-size: 14px;
    font-weight: 700;
  }

  input {
    width: 300px;
  }
}
</style>
