import { render, staticRenderFns } from "./5_Welcome.vue?vue&type=template&id=502817a7&scoped=true"
import script from "./5_Welcome.vue?vue&type=script&lang=js"
export * from "./5_Welcome.vue?vue&type=script&lang=js"
import style0 from "./5_Welcome.vue?vue&type=style&index=0&id=502817a7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "502817a7",
  null
  
)

export default component.exports