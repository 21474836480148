<template>
  <section>
    <div href="javascript:" class="header" v-if="!isEditing">
      <a href="javascript:" class="headline d-flex gap-2 align-items-center" @click="toggleContent">
        <span>{{ this.title }}</span>
        <span class="badge text-bg-warning" v-if="adminOnly">Admin-Modus</span>
      </a>

      <div class="d-flex gap-2 align-items-center">

        <a v-if="showPreview" v-show="computedIsOpen&&!previewDisabled" href="javascript:" class="example-link d-flex gap-1 align-items-center" @click="isPreviewModalOpened=true">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
            <path
              d="M1.66699 8.00002C1.66699 8.00002 4.33366 2.66669 9.00033 2.66669C13.667 2.66669 16.3337 8.00002 16.3337 8.00002C16.3337 8.00002 13.667 13.3334 9.00033 13.3334C4.33366 13.3334 1.66699 8.00002 1.66699 8.00002Z"
              stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
              stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <span class="blue-headline text-underline">Beispiel</span>

        </a>

        <a href="javascript:" class="headline" @click="toggleContent">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" v-if="computedIsOpen">
            <path d="M24 20L16 12L8 20" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" v-else>
            <path d="M8 12L16 20L24 12" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </a>

      </div>

    </div>
    <template v-if="computedIsOpen">

      <slot />

      <div class="d-flex gap-2 justify-content-between align-items-center w-100">
        <div>
          <button v-if="showEditButton && !isEditing" class="btn btn-secondary btn-220" type="button" @click="toggleEdit">
            <slot name="editButtonCaption">
              Stimmt etwas nicht?
            </slot>
          </button>
        </div>

        <template v-if="enableAdminEdit && isAdmin">

          <button class="btn btn-secondary d-flex align-items-center gap-2" type="button" @click="toggleAdminEdit" v-b-tooltip.hover.left title="Nur für Admins sichtbar!">
            <i class="fa fa-cog" style="line-height: 0"></i>
            <span>Bearbeiten</span>
          </button>

          <b-modal v-model="computedIsAdminModalOpen" modal-class="new-fair-modal">
            <Loading :active.sync="computedIsLoading" :can-cancel="false" :is-full-page="false" />
            <slot name="admin" />
            <div class="mt-3">
              <slot name="conditions" />
            </div>

            <template #modal-title>
              <div class="d-flex justify-content-start align-items-center gap-2">
                <span class="modal-title">{{ title }}</span>
                <span class="badge text-bg-warning">Admin-Modus</span>
              </div>
            </template>
            <template #modal-header-close>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path d="M24 8L8 24" stroke="#3F4957" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 8L24 24" stroke="#3F4957" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </template>
            <template #modal-footer>
              <button type="submit" class="btn btn-primary btn-220" @click="emitAdminSave">Speichern</button>
            </template>
          </b-modal>
        </template>
      </div>

    </template>
    <div v-if="showPreview" v-show="computedIsOpen&&!previewDisabled" class="preview flex-column gap-2">
      <span class="blue-headline">Beispiel:</span>
      <slot name="preview" />
    </div>

    <b-modal v-model="isPreviewModalOpened" title="Vorschau" hide-footer hide-header>
      <div class="d-flex flex-column gap-2">
        <span class="blue-headline">Beispiel:</span>
        <slot name="preview" />
      </div>
    </b-modal>

    <b-modal v-model="computedIsModalOpen" :title="this.title" modal-class="new-fair-modal">

      <Loading :active.sync="computedIsLoading" :can-cancel="false" :is-full-page="false" />

      <slot v-if="showContentOnEdit" />
      <slot name="edit" />
      <div class="mt-3">
        <slot name="conditions" />
      </div>

      <template #modal-header-close>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path d="M24 8L8 24" stroke="#3F4957" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8 8L24 24" stroke="#3F4957" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </template>

      <template #modal-footer>
        <button type="submit" class="btn btn-primary btn-220" @click="emitSave" :disabled="computedIsDisabled">{{ buttonSaveText
          }}</button>
      </template>

    </b-modal>

  </section>
</template>

<script>
import Loading from 'vue-loading-overlay'
import { usePageStore } from '../stores/pageStore'

export default {
  name: 'FairFieldEditor',
  components: {
    Loading
  },
  data: function () {
    return {
      isPreviewModalOpened: false,
      pageStore: usePageStore()
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 1
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    showOpenButton: {
      type: Boolean,
      default: true
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    showEditButton: {
      type: Boolean,
      default: true
    },
    buttonSaveText: {
      type: String,
      default: 'Korrekturwunsch absenden'
    },
    isModalOpen: {
      type: Boolean,
      default: false
    },
    isAdminModalOpen: {
      type: Boolean,
      default: false
    },
    enableAdminEdit: {
      type: Boolean,
      default: true
    },
    showContentOnEdit: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    saveDisabled: {
      type: Boolean,
      default: false
    },
    previewDisabled: {
      type: Boolean,
      default: false
    },
    usePageStore: {
      type: Boolean,
      default: false
    },
    adminOnly: {
      type: Boolean,
      default: false
    },
    showPreview: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    toggleContent () {
      const pageStore = usePageStore()
      if (this.usePageStore === true) {
        pageStore.setCurrentOpenedFairEditTab(this.index)
      } else {
        this.$emit('toggle', this.index)
      }
    },
    toggleEdit () {
      this.$emit('update:isModalOpen', !this.isModalOpen)
    },
    toggleAdminEdit () {
      this.$emit('update:isAdminModalOpen', !this.isAdminModalOpen)
    },
    emitSave () {
      this.$emit('save')
    },
    async emitAdminSave () {
      await this.$emit('adminSave')
      this.$toast.success('Änderungen wuden gespeichert.')
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    isAdmin () {
      return this.app.isAdministrator
    },
    computedIsModalOpen: {
      get () {
        return this.isModalOpen
      },
      set (val) {
        this.$emit('update:isModalOpen', val)
      }
    },
    computedIsAdminModalOpen: {
      get () {
        return this.isAdminModalOpen
      },
      set (val) {
        this.$emit('update:isAdminModalOpen', val)
      }
    },
    computedShowOpenButton () {
      return this.showOpenButton
    },
    computedShowContent () {
      return this.showContentOnEdit || !this.isEditing
    },
    computedIsDisabled () {
      return this.saveDisabled || this.loading
    },
    computedIsLoading () {
      return this.loading
    },
    computedIsOpen () {
      if (this.usePageStore === true) {
        return this.pageStore.getCurrentOpenedFairEditTab === this.index
      } else {
        return this.isOpen
      }
    }
  }
}

</script>

<style lang="scss" scoped>
section {
  position: relative;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: #F7F8F9;
}

.example-link {

  @media (min-width: 1600px) {
    display: none !important;
  }

}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  .headline {
    flex: 1 0 0;
    color: #00719F;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-decoration: none;
  }
}

.btn-220 {
  width: 220px;
}

.preview {
  width: 634px;
  padding: 16px;
  position: absolute;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
  right: -700px;
  top: 0;

  display: none;

  @media (min-width: 1600px) {
    display: flex;
  }

}
</style>

<style>
.blue-headline {
  color: #00719F;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.new-fair-modal {

  ul.special {
    list-style: none;
    padding: 0;
    margin: 0;

    /* Set up Flexbox on list items for vertical alignment */
    >li {
      /* display: flex; */
      vertical-align: middle;
      margin-bottom: 4px;
      gap: 4px;

      li {
        list-style-type: disc;
      }

    }

    /* Use ::before to insert the SVG icon */
    >li {
      &::before {
        content: url('../assets/images/icons/3.0/alert.svg');
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }

      &.error {
        color: #E53A14;

        &::before {
          content: url('../assets/images/icons/3.0/error-x.svg');
        }
      }

      &.success {
        color: #91C124;

        &::before {
          content: url('../assets/images/icons/3.0/check-green.svg');
        }
      }

    }

    >li.inner-list::before {
      margin-right: 4px;
    }

  }

  hr {
    background: #D0D5DD;
    width: 100%;
    height: 1px;
    margin: 16px 0 !important;

    &.vertical {
      width: 1px;
      height: 44px;
      margin: 0 !important;
      background-color: #D0D5DD;
    }
  }

  .modal-content {

    display: flex;
    max-width: 939px;
    width: 90%;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #F7F8F9;
    margin: 0 auto;

    input[type="checkbox"] {
      width: 20px;
      height: 20px;
    }

    .new-text-area {
      padding: 16px;
      border-radius: 8px;
      opacity: 0.8;
      background: #EFF1F4;

      .headline {
        color: #3F4957;
        font-family: "Nunito Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }

    }

    .mb-edit-img {
      width: 176px;

      >a {
        display: flex;
        flex-direction: column;
        justify-content: start;
      }

    }

    header.modal-header {
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border: 0;

      .close {
        width: 32px;
        height: 32px;
        font-size: 24px;
        width: auto;
        line-height: 0;
        padding: 0;
      }

      h5.modal-title {
        color: #3F4957;
        font-family: "Nunito Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
      }

    }

    footer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      border: 0;
      padding: 0;
    }

    .modal-body {
      padding: 0;
      width: 100%;
    }

  }
}
</style>
