<template>
  <FairFieldEditor title="Mitgliedschaft im deutschen Franchiseverband" :index="4" buttonSaveText="Änderungen speichern"
    :showContentOnEdit="false" :loading="isLoading" @save="handleSave" :isEditing="isEditing"
    :saveDisabled="!computedIsValid" :is-modal-open.sync="isModalOpen" @update:isModalOpen="updateFormFields"
    :usePageStore="true" @toggle="toggleContent" :showOpenButton="false" :enableAdminEdit="false">
    <div class="d-flex flex-column">
      <div class="d-flex gap-10px">
        <img class="dfv-siegel" src="@/assets/images/new-fair/DFV_Siegel_RGB.png" alt="Franchiseverband Siegel" :style="{
          filter: currentBrand.ComParamMemberDFV ? 'none' : 'grayscale(100%)'
        }" />
        <div class="d-flex flex-column justify-content-center">
          <strong>Zertifiziertes Vollmitglied im deutschen Franchiseverband?</strong>
          <span v-if="currentBrand.ComParamMemberDFV">Ja, Siegel wird am Messestand angezeigt</span>
          <span v-else>Nein, Siegel wird <strong>nicht</strong> am Messestand angezeigt</span>
        </div>
      </div>
    </div>
    <template v-slot:edit>

      <div class="d-flex gap-3">

        <div class="main-image">
          <img :src="computedMainPictureSource" class="img-fluid" />
          <img v-show="computedIsActive" class="dfv-siegel" src="@/assets/images/new-fair/DFV_Siegel_RGB.png"
            alt="Franchiseverband Siegel" />
        </div>

        <div class="d-flex flex-column justify-content-center gap-2">
          <span class="blue-headline">Zertifiziertes Vollmitglied im deutschen Franchiseverband?</span>
          <div class="radio-group">

            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio v-model="formFields.value" :aria-describedby="ariaDescribedby" name="some-radios"
                :value="true">
                Ja, Siegel am Messestand anzeigen
              </b-form-radio>
              <b-form-radio v-model="formFields.value" :aria-describedby="ariaDescribedby" name="some-radios"
                :value="false">
                Nein, kein Siegel am Messestand anzeigen
              </b-form-radio>
            </b-form-group>
          </div>

        </div>

      </div>

    </template>
    <template v-slot:editButtonCaption>
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-pencil"></i>
        <span>Bearbeiten</span>
      </div>
    </template>
    <template v-slot:preview>
      <img class="img-fluid" src="@/assets/images/new-fair/4_MembershipAssociation.png" />
    </template>
  </FairFieldEditor>
</template>

<script>
import FairFieldEditor from '@/components/FairFieldEditor.vue'
import { useBrandStore } from '../../stores/brandStore'
import Config from '@/config'

export default {
  name: 'FairFormMembershipAssociation',
  components: {
    FairFieldEditor
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      formFields: {
        placeholder: 'Tragen Sie hier Ihren neuen Vorschlag ein.',
        value: false
      }
    }
  },
  methods: {
    updateFormFields () {
      this.formFields.value = this.currentBrand.ComParamMemberDFV === 1
    },
    toggleContent () {
      this.isOpen = !this.isOpen
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Änderungen erfolgreich gespeichert.')
        this.isModalOpen = false
        this.formFields.value = false
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern deiner Änderungen ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async submitForm () {
      const brandStore = useBrandStore()
      await brandStore.updateCurrentBrand({
        ComParamMemberDFV: this.formFields.value ? 1 : 0
      })
    }
  },
  computed: {
    computedIsValid () {
      return typeof this.formFields.value !== 'undefined'
    },
    computedIsActive () {
      return this.formFields.value === true
    },
    currentBrand: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentBrand
    },
    currentFair: function () {
      return this.currentBrand.BrandModules[0]?.Fair || {}
    },
    computedMainPictureSource () {
      if (this.currentFair && this.currentFair.MainPicture) {
        return `${Config.MEDIA_API_URL}${this.currentFair.MainPicture.URL}`
      }
      return ''
    }
  }

}
</script>

<style lang="scss" scoped>
.dfv-siegel {
  width: 62px;
  height: 62px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
}

.main-image {
  width: 360px;
  height: 203px;
  border-radius: 8px;
  overflow: hidden;

  img:first-child {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img:last-child {
    position: absolute;
    top: 10px;
    left: 10px;
  }

}
</style>

<style>
.radio-group {
  .form-group {
    >div {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.custom-control.custom-radio {

  .custom-control-input {
    width: 20px;
    height: 20px;
  }
}
</style>
