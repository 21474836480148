<template>
  <div class="video-preview-container flex-shrink-0">
    <VideoPreviewImageContainer :video="video" />
    <a class="btn btn-primary" rel="noopener noreferrer" @click="openModal">
      <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.8889 9.35996L2.3911 1.11102C2.27741 1.04154 2.14726 1.0036 2.01404 1.00111C1.88083 0.998613 1.74935 1.03165 1.63314 1.09683C1.51692 1.162 1.42017 1.25696 1.35283 1.37193C1.2855 1.4869 1.25 1.61774 1.25 1.75098V18.2488C1.25 18.3821 1.2855 18.5129 1.35283 18.6279C1.42017 18.7429 1.51692 18.8378 1.63314 18.903C1.74935 18.9682 1.88083 19.0012 2.01404 18.9987C2.14726 18.9962 2.27741 18.9583 2.3911 18.8888L15.8889 10.6399C15.9985 10.5729 16.0891 10.4788 16.152 10.3668C16.2148 10.2547 16.2478 10.1284 16.2478 9.99991C16.2478 9.87143 16.2148 9.74511 16.152 9.63305C16.0891 9.52099 15.9985 9.42695 15.8889 9.35996Z"
          stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <span>Video ansehen</span>
    </a>
    <b-modal v-if="video" v-model="isModalOpen" title="Video" size="lg" dialog-class="modal-100w" hide-footer
      hide-header>
      <div class="responsive-video-container">
        <iframe class="sproutvideo-player" :src="computedVideoLink" frameborder="0" allowfullscreen
          referrerpolicy="no-referrer-when-downgrade" title="Video Player">
        </iframe>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { useBrandStore } from '@/stores/brandStore'
import VideoPreviewImageContainer from './VideoPreviewImageContainer.vue'

export default {
  name: 'VideoPreviewLink',
  components: {
    VideoPreviewImageContainer
  },
  props: ['video'],
  data: function () {
    return {
      isModalOpen: false
    }
  },
  methods: {
    openModal () {
      this.isModalOpen = true
    },
    async loadMediumIfNeeded () {
      // Check if Medium needs to be loaded
      if (!this.video.Medium && this.video.MediumID) {
        try {
          const retrievedMedium = await this.API._post('Medium', {
            FullSubEntities: true,
            MediumID: this.video.MediumID
          })
          this.$set(this.video, 'Medium', retrievedMedium.body.Result.Data) // Ensure reactivity
        } catch (error) {
          console.error('Error loading Medium:', error)
        }
      }
    }
  },
  computed: {
    computedFrameStyles () {
      return {
        width: '100%',
        height: '100%',
        border: 'none'
      }
    },
    isReady () {
      return typeof this.video !== 'undefined' && (typeof this.video.Medium !== 'undefined' || typeof this.video.MediumID !== 'undefined')
    },
    computedVideoLink: function () {
      if (!this.isReady) {
        return ''
      }
      const brandStore = useBrandStore()
      return brandStore.getVideoLink(this.video.Medium)
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    API: function () {
      return this.app.API || {}
    }
  },
  watch: {
    video: {
      handler: function (newValue, oldValue) {
        // When `video` prop changes, check if data needs to be loaded
        if (newValue && newValue.MediumID && !newValue.Medium) {
          this.loadMediumIfNeeded()
        }
      },
      immediate: true, // Run the handler immediately when the component is mounted
      deep: true // Ensure changes within `video` are also watched
    }
  },
  async mounted () {
    if (!this.isReady) {
      await this.loadMediumIfNeeded()
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-100w {
  max-width: 100% !important;
}

.responsive-video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio (height / width = 9 / 16 = 0.5625 or 56.25%) */
  height: 0;
  overflow: hidden;
  border-radius: 8px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
