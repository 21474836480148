<template>
  <FairFieldEditor :title="computedTitle" :index="10" buttonSaveText="Änderungen speichern" :showContentOnEdit="false"
    :loading="isLoading" @save="handleSave" :isEditing="isEditing" :saveDisabled="!computedIsValid"
    :is-modal-open.sync="isModalOpen" @toggleEdit="toggleEdit" @toggle="toggleContent"
    @update:isModalOpen="updateFormFields" :showOpenButton="false" :usePageStore="true" :enableAdminEdit="false">
    <div class="d-flex flex-column gap-3 w-100">
      <FairExperienceContainer v-for="experience in computedExperiences" :key="experience.FairExperienceID"
        :experience="experience" />
    </div>
    <template v-slot:edit>

      <div class="d-flex flex-column gap-3 align-items-start">

        <FairExperienceEditContainer v-for="fairExperience of formFields.FairExperiences"
          :key="fairExperience.FairExperienceID" :experience="fairExperience"
          :amountOfExperiences="computedAmountOfExperiences" @updateSortOrderUp="handleSortOrderUp"
          @updateSortOrderDown="handleSortOrderDown" @removeExperience="handleRemoveFairExperience" @updatedExperience="handleUpdatedExperience" />

        <button class="btn btn-secondary new-experience-button" @click="addNewFairExperience">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8 3.3335V12.6668" stroke="#00719F" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M3.33398 8H12.6673" stroke="#00719F" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <span>Neue Erfahrung hinzufügen</span>
        </button>

      </div>

      <hr />
      <div class="d-flex flex-column w-100 gap-3">
        <div class="d-flex flex-column gap-1">
          <span class="fw-bold">Anforderungen:</span>
          <ul class="special">
            <li :class="{
              success: computedIsValid,
              error: !computedIsValid
            }">Alle Textfelder müssen ausgefüllt sein
            </li>
            <li><strong class="orange-text">Dringende Empfehlung:</strong> Fügen Sie den Erfahrungen unbedingt ein
              sympathisches Portraitfoto oder ein Video des Partners / der Partnerin hinzu!</li>
            <li><strong class="orange-text">Dringende Empfehlung:</strong> Sie sollten mindestens 3 Erfahrungsberichte
              veröffentlichen: Zufriedene Partner:innen sind Ihre besten Verkäufer:innen!</li>
            <li>Erfahrungsberichte sollten nach Möglichkeit aus mindestens 5 Sätzen bestehen</li>
          </ul>
        </div>
      </div>
    </template>
    <template v-slot:editButtonCaption>
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-pencil"></i>
        <span>Bearbeiten</span>
      </div>
    </template>
    <template v-slot:preview>
      <img class="img-fluid" src="@/assets/images/new-fair/10_Experiences.png" />
    </template>
  </FairFieldEditor>
</template>

<script>
import FairFieldEditor from '@/components/FairFieldEditor.vue'
import FairExperienceContainer from './FairExperienceContainer.vue'
import FairExperienceEditContainer from './FairExperienceEditContainer.vue'
import { useBrandStore } from '@/stores/brandStore'

function sanitizeContent (htmlString) {
  // Replace <br> tags with newline
  let sanitizedString = htmlString.replace(/<br\s*\/?>/gi, '\n')

  // Replace <p> tags with double newlines
  sanitizedString = sanitizedString.replace(/<\/?p[^>]*>/gi, '\n\n')

  // Decode HTML entities like &nbsp;
  const entityMap = { '&nbsp;': ' ' }
  sanitizedString = sanitizedString.replace(/&[a-z]+;/gi, match => entityMap[match] || '')

  // Strip all remaining HTML tags
  sanitizedString = sanitizedString.replace(/<[^>]+>/gi, '')

  // Trim spaces and extra newlines
  sanitizedString = sanitizedString.trim()

  // Remove multiple consecutive newlines
  sanitizedString = sanitizedString.replace(/\n{2,}/g, '\n\n')

  return sanitizedString
}

export default {
  name: 'FairFormExperiences',
  components: {
    FairFieldEditor,
    FairExperienceContainer,
    FairExperienceEditContainer
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      formFields: {
        FairExperiences: []
      }
    }
  },
  methods: {
    toggleEdit () {
      this.isEditing = !this.isEditing
    },
    toggleContent () {
      this.isOpen = !this.isOpen
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Änderungen erfolgreich gespeichert.')
        this.isModalOpen = false
        this.formFields.value = ''
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern deiner Änderungen ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async submitForm () {
      const brandStore = useBrandStore()

      await Promise.all(
        this.formFields.FairExperiences.map(async (FairExperience) => {
          if (FairExperience.Video) {
            await brandStore.fpApi._put('Medium', {
              MediumID: FairExperience.Video.MediumID,
              Title: FairExperience.Video.Title
            })
          }

          const fairExperienceBefore = this.computedExperiences.find(
            (exp) => exp.FairExperienceID === FairExperience.FairExperienceID
          )

          if (fairExperienceBefore) {
            if (FairExperience.MediumID === null && fairExperienceBefore.MediumID !== null) {
              await brandStore.fpApi._delete(`Medium/${fairExperienceBefore.MediumID}`)
            }
            if (FairExperience.VideoID === null && fairExperienceBefore.VideoID !== null) {
              await brandStore.safeDeleteVideo(fairExperienceBefore.VideoID)
              await brandStore.fpApi._delete(`Medium/${fairExperienceBefore.VideoID}`)
            }
          }

          return await brandStore.fpApi._put('FairExperience', {
            FairExperienceID: FairExperience.FairExperienceID ?? -1,
            FairID: this.computedFair.FairID,
            AuthorData: FairExperience.AuthorData,
            Location: FairExperience.Location,
            Text: sanitizeContent(FairExperience.Text),
            MediumID: FairExperience.MediumID,
            VideoID: FairExperience.VideoID,
            SortOrder: FairExperience.SortOrder,
            NullFields: ['MediumID', 'VideoID'].filter((field) => FairExperience[field] === null)
          })
        })
      )

      const currentFairExperiences = this.computedFair.FairExperiences
      const formFairExperiences = this.formFields.FairExperiences
      const fairExperiencesToDelete = currentFairExperiences.filter(
        (currentFairExperience) => {
          return !formFairExperiences.some(
            (formFairExperience) =>
              formFairExperience.FairExperienceID === currentFairExperience.FairExperienceID
          )
        }
      )

      await Promise.all(
        fairExperiencesToDelete.map(async (FairExperience) => {
          if (FairExperience.MediumID !== null) {
            await brandStore.fpApi._delete(`Medium/${FairExperience.MediumID}`)
          }
          if (FairExperience.VideoID !== null) {
            await brandStore.safeDeleteVideo(FairExperience.VideoID)
            await brandStore.fpApi._delete(`Medium/${FairExperience.VideoID}`)
          }
          return await brandStore.fpApi._delete(`FairExperience/${FairExperience.FairExperienceID}`)
        })
      )

      brandStore.setCurrentFairUpdate({
        FairExperiences: this.formFields.FairExperiences
      })
    },
    updateFormFields () {
      this.formFields.FairExperiences = JSON.parse(JSON.stringify(this.computedExperiences))
    },
    handleSortOrderUp (experience) {
      const index = this.formFields.FairExperiences.findIndex((exp) => exp.FairExperienceID === experience.FairExperienceID)
      if (index > 0) {
        this.formFields.FairExperiences.splice(index - 1, 2, this.formFields.FairExperiences[index], this.formFields.FairExperiences[index - 1])
        this.resetSortOrder()
      }
    },
    handleSortOrderDown (experience) {
      const index = this.formFields.FairExperiences.findIndex((exp) => exp.FairExperienceID === experience.FairExperienceID)
      if (index < this.formFields.FairExperiences.length - 1) {
        this.formFields.FairExperiences.splice(index, 2, this.formFields.FairExperiences[index + 1], this.formFields.FairExperiences[index])
        this.resetSortOrder()
      }
    },
    resetSortOrder () {
      this.formFields.FairExperiences.forEach((exp, i) => {
        exp.SortOrder = i
      })
    },
    addNewFairExperience () {
      this.formFields.FairExperiences.push({
        FairExperienceID: null,
        AuthorData: null,
        Location: null,
        Text: null,
        Medium: null,
        MediumID: null,
        Video: null,
        VideoID: null,
        SortOrder: this.formFields.FairExperiences.length
      })
    },
    handleRemoveFairExperience (experience) {
      const index = this.formFields.FairExperiences.findIndex((exp) => exp.FairExperienceID === experience.FairExperienceID)
      this.formFields.FairExperiences.splice(index, 1)
      this.resetSortOrder()
    },
    handleUpdatedExperience (experience) {
      const index = this.formFields.FairExperiences.findIndex((exp) => exp.FairExperienceID === experience.FairExperienceID)
      this.formFields.FairExperiences.splice(index, 1, experience)
    }
  },
  computed: {
    computedTitle () {
      return `Erfahrungen: ${this.computedExpansionFormCaption}-Partner:innen berichten`
    },
    computedAmountOfExperiences () {
      return this.formFields.FairExperiences.length
    },
    computedExpansionFormCaption () {
      if (this.currentBrand) {
        if (this.currentBrand.ConceptExpansionFormFranchise) return 'Franchise'
        if (this.currentBrand.ConceptExpansionFormLicense) return 'Lizenz'
      }
      return ''
    },
    computedIsValid () {
      return this.formFields.FairExperiences.every((fairExperience) => {
        return (
          fairExperience.AuthorData !== null && String(fairExperience.AuthorData).length > 0 &&
          fairExperience.Location !== null && String(fairExperience.Location).length > 0 &&
          fairExperience.Text !== null && String(fairExperience.Text).length > 0
        )
      })
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentBrand
    },
    computedFair: function () {
      return this.currentBrand.BrandModules[0].Fair
    },
    computedExperiences () {
      return [...this.computedFair.FairExperiences].sort((a, b) => a.SortOrder - b.SortOrder)
    }
  }

}
</script>

<style lang="scss" scoped>
.experience-container {
  padding: 8px 16px;
  background-color: #EFF1F4;
  border-radius: 8px;
  width: 100%;
}

.new-experience-button {
  display: flex;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #00719F;
  background: #FFF;
  flex-grow: 0;
}
</style>
