<template>
  <div class="w-100">
    <button class="btn btn-secondary w-100" @click="handleUploadButtonClick">
      <slot name="buttonCaption"></slot>
    </button>
    <b-modal v-model="isModalOpen" no-close-on-backdrop>

      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false" />

      <template v-slot:modal-title>
        Neues Video
      </template>

      <template v-slot:modal-footer>
        <button class="btn btn-secondary" @click="handleCancel" :disabled="getCurrentUploadState !== 'idle'">
          Abbrechen
        </button>
        <button class="btn btn-primary" @click="handleSaveVideo" :disabled="!computedIsVideoUploadReady">
          <template v-if="isAdmin">
            <span v-if="!newVideoFile">Speichern</span>
            <span v-else>Hochladen</span>
          </template>
          <span v-else>Hochladen</span>
        </button>
      </template>

      <div class="quote-box d-flex flex-row align-items-center p-2 gap-3">
        <div class="empty-video flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
            <path d="M30.6663 9.93701L21.333 16.6037L30.6663 23.2703V9.93701Z" stroke="#64748B" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M18.6663 7.27051H3.99967C2.52692 7.27051 1.33301 8.46441 1.33301 9.93717V23.2705C1.33301 24.7433 2.52692 25.9372 3.99967 25.9372H18.6663C20.1391 25.9372 21.333 24.7433 21.333 23.2705V9.93717C21.333 8.46441 20.1391 7.27051 18.6663 7.27051Z"
              stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>

        <div class="d-flex flex-column gap-3 flex-grow-1">

          <div class="d-flex flex-column gap-2">
            <span class="blue-headline">Titel des Videos:</span>
            <input type="text" class="form-control" v-model="newVideoTitle" />
          </div>

          <template v-if="isAdmin">
            <div class="d-flex flex-column gap-2">
              <div class="d-flex justify-content-between align-items-center">
                <span class="blue-headline">URL des Videos:</span>
                <span class="badge text-bg-warning">Admin-Modus</span>
              </div>
              <input :disabled="newVideoFile !== null" type="text" class="form-control" v-model="newVideoURL" />
            </div>
            <div class="d-flex flex-column gap-2">
              <div class="d-flex justify-content-between align-items-center">
                <span class="blue-headline">Dateiname des Videos:</span>
                <span class="badge text-bg-warning">Admin-Modus</span>
              </div>
              <input :disabled="newVideoFile !== null" type="text" class="form-control" v-model="newVideoFilename" />
            </div>
          </template>

          <div v-if="getCurrentVideoIsUploading" class="upload-state d-flex flex-column gap-2">
            <div class="d-flex align-items-center justify-content-between gap-2">
              <span>{{ getCurrentUploadStateCaption }}</span>
            </div>
            <b-progress v-if="getCurrentUploadState === 'uploading'" :value="computedCurrentVideoUploadProgress" :max="100" :striped="true" height="2rem" show-progress animated></b-progress>
            <b-skeleton v-else animation="wave" width="100%" height="2rem"></b-skeleton>
          </div>
          <div v-else class="d-flex flex-column">
            <span class="blue-headline">Video-Datei auswählen:</span>
            <div class="d-flex align-items-center justify-content-between">
              <input ref="videoInputField" type="file" @change="handleFileSelect" accept="video/*" />
            </div>
          </div>

        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { useBrandStore } from '@/stores/brandStore'
import { usePageStore } from '@/stores/pageStore'
import Loading from 'vue-loading-overlay'

export default {
  name: 'VideoUploadButton',
  components: {
    Loading
  },
  emits: ['newVideoSaved'],
  data: function () {
    return {
      isModalOpen: false,
      newVideoTitle: '',
      newVideoURL: '',
      newVideoFilename: '',
      newVideoFile: null,
      isLoading: false,
      isUploading: false,
      progressInPercent: 0
    }
  },
  methods: {
    handleUploadButtonClick () {
      const pageStore = usePageStore()
      pageStore.endVideoUpload()
      this.isModalOpen = true
    },
    handleCancel () {
      this.isModalOpen = false
    },
    handleFileSelect (event) {
      this.newVideoFile = event.target.files[0]
    },
    async handleSaveVideo () {
      try {
        if (!this.isAdmin) {
          await this.uploadVideo()
        } else {
          if (this.newVideoFile !== null) {
            await this.uploadVideo()
            return
          }

          const brandStore = useBrandStore()
          this.isLoading = true

          const newMediumRequestBody = {
            MediumID: -1,
            Title: this.newVideoTitle,
            URL: this.newVideoURL,
            Filename: this.newVideoFilename,
            State: 0,
            TypeID: 1,
            FolderID: 5
          }

          const newMediumResponse = await brandStore.fpApi._put('Medium', newMediumRequestBody)
          const newMediumId = newMediumResponse.body.Result.NewID
          const createdMediumResponse = await brandStore.fpApi._post('Medium', {
            MediumID: newMediumId
          })
          const createdMedium = createdMediumResponse.body.Result.Data
          this.$emit('newVideoSaved', createdMedium)
          this.isModalOpen = false
        }
      } catch (error) {
        console.error('Error at Video Upload', error)
        this.$toast.error('Beim Hochladen des Videos ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async uploadVideo () {
      const brandStore = useBrandStore()
      const pageStore = usePageStore()
      pageStore.startVideoUpload(this.newVideoFile.size)

      if (!this.newVideoFile) {
        this.$toast.error('Bitte wählen Sie eine Datei aus, bevor Sie hochladen.')
        return
      }
      try {
        this.isLoading = true

        pageStore.startVideoUpload(this.newVideoFile.size)
        const uploadedFairVideo = await brandStore.uploadVideo({
          Title: this.newVideoTitle,
          File: this.newVideoFile
        }, 'SystemRoot/Fair/FairVideos')

        if (!uploadedFairVideo) {
          throw new Error('Error uploading Video')
        }

        this.$emit('newVideoSaved', uploadedFairVideo)
      } catch (error) {
        console.error('Error uploading video', error)
        this.$toast.error('Beim Hochladen des Videos ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
        this.isUploading = false
        this.isModalOpen = false
        this.newVideoTitle = ''
        this.newVideoFile = null
        if (this.$refs.videoInputField) {
          this.$refs.videoInputField.value = ''
        }
      }
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    isAdmin: function () {
      return this.app.isAdministrator
    },
    currentFair: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentFair
    },
    computedIsVideoUploadReady () {
      if (this.isAdmin) {
        if (this.newVideoFile === null) {
          return (
            this.newVideoTitle.length > 0 &&
            this.newVideoURL.length > 0 &&
            this.newVideoFilename.length > 0
          )
        }
      }

      return this.newVideoTitle.length > 0 && this.newVideoFile !== null && this.getCurrentUploadState === 'idle'
    },
    getCurrentVideoIsUploading () {
      const pageStore = usePageStore()
      return pageStore.getCurrentVideoIsUploading
    },
    getCurrentVideoUploadState () {
      const pageStore = usePageStore()
      return pageStore.getCurrentVideoUploadState
    },
    getCurrentUploadState () {
      const pageStore = usePageStore()
      return pageStore.getCurrentUploadState
    },
    computedCurrentVideoUploadProgress () {
      const pageStore = usePageStore()
      return pageStore.getCurrentVideoUploadProgress
    },
    computedCurrentVideoUploadProgressCaption () {
      return `${this.computedCurrentVideoUploadProgress}%`
    },
    getCurrentUploadStateCaption () {
      switch (this.getCurrentUploadState) {
        case 'uploading':
          return 'Video wird in die Cloud geladen...'
        case 'transcoding':
          return 'Video wird auf Hochglanz poliert...'
        case 'generatingThumbnail':
          return 'Vorschaubild wird erstellt...'
        case 'idle':
          return 'Warte auf Upload...'
        default:
          return 'Warte auf Upload...'
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.upload-state {
  background-color: #F7F8F9;
  padding: 8px;
}

.upload-action-cancel {
  width: 24px;
  height: 24px;
  background-color: #FCE1DB;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}
</style>
