// stores/counter.js
import { defineStore } from 'pinia'
import axios from 'axios'
import { useUserStore } from './userStore'
import Config from '@/config'

export const usePageStore = defineStore('page', {
  state: () => {
    return {
      runningLoaders: [],
      appClassess: [],
      currentOpenedFairEditTab: 1,
      currentVideoEdit: null,
      currentVideoUploadState: {
        progress: 0,
        total: null,
        uploadingVideo: false,
        transcodingVideo: false,
        generatingThumbnail: false
      }
    }
  },
  getters: {
    getVideoEdit () {
      return this.currentVideoEdit
    },
    getCurrentOpenedFairEditTab () {
      return this.currentOpenedFairEditTab
    },
    getLoadingState () {
      return this.runningLoaders.length > 0
    },
    getComputedAppClasses () {
      return this.appClassess.join(' ')
    },
    getCurrentVideoUploadState () {
      return this.currentVideoUploadState
    },
    getCurrentVideoIsUploading () {
      return this.currentVideoUploadState.uploadingVideo
    },
    getCurrentVideoUploadProgress () {
      return this.currentVideoUploadState.progress
    },
    getCurrentUploadState () {
      if (this.currentVideoUploadState.generatingThumbnail) {
        return 'generatingThumbnail'
      }

      if (this.currentVideoUploadState.transcodingVideo) {
        return 'transcoding'
      }

      if (this.currentVideoUploadState.uploadingVideo) {
        return 'uploading'
      }

      return 'idle'
    }
  },
  actions: {
    addVideoEdit (video) {
      this.currentVideoEdit = video
    },
    startLoading (loaderName) {
      this.runningLoaders.push(loaderName)
    },
    stopLoading (loaderName) {
      const index = this.runningLoaders.indexOf(loaderName)
      if (index >= 0) {
        this.runningLoaders.splice(index, 1)
      }
    },
    isLoading (loaderName) {
      return this.runningLoaders.indexOf(loaderName) >= 0
    },
    setCurrentOpenedFairEditTab (tab) {
      if (tab === this.currentOpenedFairEditTab) {
        this.currentOpenedFairEditTab = null
      } else {
        this.currentOpenedFairEditTab = tab
      }
    },
    async uploadImage (File, Filename, Folder) {
      const formData = new FormData()
      formData.append('File', File)
      formData.append('Filename', Filename)
      formData.append('Folder', Folder)

      const userStore = useUserStore()

      const uploadUrl = [Config.FPSERVICE_URL, 'mb/fair/image/upload'].join('')
      const uploadHeaders = {
        Accept: 'application/json',
        'User-Token': userStore.getToken,
        'Content-Type': 'multipart/form-data'
      }

      return await axios
        .post(uploadUrl, formData, {
          headers: uploadHeaders
        })
    },
    startVideoUpload (fileSize) {
      this.currentVideoUploadState.uploadingVideo = true
      this.currentVideoUploadState.total = fileSize
    },
    endVideoUpload () {
      this.currentVideoUploadState.uploadingVideo = false
      this.currentVideoUploadState.transcodingVideo = false
      this.currentVideoUploadState.generatingThumbnail = false
      this.currentVideoUploadState.progress = 0
      this.currentVideoUploadState.total = null
    },
    setVideoUploadProgress (progress) {
      this.currentVideoUploadState.progress = progress
      if (progress === 100) {
        this.setVideoTranscodingState(true)
      }
    },
    setVideoTranscodingState (state) {
      this.currentVideoUploadState.transcodingVideo = state
    },
    setVideoThumbnailState (state) {
      this.currentVideoUploadState.generatingThumbnail = state
    }
  }
})
