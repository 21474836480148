<script>
import ConditionsGeneratedText from '@/components/fair/ConditionsGeneratedText.vue'
import { CurrencyInput } from 'vue-currency-input'

export default {
  name: 'GeneralFormConditions',
  components: {
    CurrencyInput,
    ConditionsGeneratedText
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      debouncedEmitUpdate: null,
      formFields: {
        CondInvestSumType: this.currentBrand.CondInvestSumType,
        CondInvestSumFrom: this.currentBrand.CondInvestSumFrom,
        CondInvestSumTo: this.currentBrand.CondInvestSumTo,
        CondEntryFeeType: this.currentBrand.CondEntryFeeType,
        CondEntryFeeFrom: this.currentBrand.CondEntryFeeFrom,
        CondEntryFeeTo: this.currentBrand.CondEntryFeeTo,
        CondInitialCapitalType: this.currentBrand.CondInitialCapitalType,
        CondInitialCapitalFrom: this.currentBrand.CondInitialCapitalFrom,
        CondInitialCapitalTo: this.currentBrand.CondInitialCapitalTo,
        CondRequiredCapitalType: this.currentBrand.CondRequiredCapitalType,
        CondRequiredCapitalFrom: this.currentBrand.CondRequiredCapitalFrom,
        CondRequiredCapitalTo: this.currentBrand.CondRequiredCapitalTo,
        CondDescription: this.currentBrand.CondDescription
      }
    }
  },
  methods: {
  },
  props: {
    currentBrand: {
      type: Object,
      required: true
    }
  },
  computed: {
    computedCondInvestSumMinimum () {
      return this.formatCurrency(this.currentBrand.CondInvestSumFrom)
    },
    computedCondInvestSumMinimumInEditForm () {
      return this.formatCurrency(this.formFields.CondInvestSumFrom)
    },
    formatCurrency () {
      return (value) => {
        return (
          new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 0, // No decimal places for whole numbers
            maximumFractionDigits: 0 // No decimal places for whole numbers
          }).format(value) + ' €'
        )
      }
    },
    computedHasCondError () {
      return {
        RequiredCapital:
          this.currentBrand.CondInvestSumFrom <
          this.currentBrand.CondRequiredCapitalFrom,
        EntryFee:
          this.currentBrand.CondInvestSumFrom <
          this.currentBrand.CondEntryFeeFrom,
        InitialCapital:
          this.currentBrand.CondInvestSumFrom <
          this.currentBrand.CondInitialCapitalFrom
      }
    },
    computedHasCondErrorInEditForm () {
      return {
        RequiredCapital:
          this.formFields.CondInvestSumFrom <
          this.formFields.CondRequiredCapitalFrom,
        EntryFee:
          this.formFields.CondInvestSumFrom < this.formFields.CondEntryFeeFrom,
        InitialCapital:
          this.formFields.CondInvestSumFrom <
          this.formFields.CondInitialCapitalFrom
      }
    },

    computedCondFinanceTypes () {
      return [
        { value: 1, text: 'von / bis' },
        { value: 2, text: 'fix' },
        { value: 3, text: 'ab' }
      ]
    },

    computedShowSumFieldsForIniticalCapital () {
      return this.formFields.CondInitialCapitalType !== -1
    },

    computedCondFinanceTypesInitialCapital () {
      return [
        ...this.computedCondFinanceTypes,
        { value: -1, text: 'Keine Kosten für Erstausstattung' }
      ]
    }
  },
  watch: {
    formFields: {
      deep: true,
      handler () {
        this.$emit('update', this.formFields)
      }
    }
  }
}

</script>

<template>
  <div class="new-fair-modal">
    <div class="quote-box cond-box">
      <div class="d-flex flex-column gap-2 w-100">
        <span class="blue-headline">Wie hoch ist die Gesamtinvestitionssumme für eine Gründung mit
          {{ currentBrand.Name }}?</span>

        <div class="d-flex flex-sm-row flex-column justify-content-start align-items-center gap-3">
          <!-- input dropdown (von/bis; fix; ab) -->

          <b-form-select class="form-control flex-grow-0 w-100" v-model="formFields.CondInvestSumType"
            :options="computedCondFinanceTypes"></b-form-select>
          <hr class="vertical d-none d-sm-block" />

          <currency-input class="form-control flex-grow-0" v-model="formFields.CondInvestSumFrom" currency="EUR"
            locale="de" :allow-negative="false" required />
          <template v-if="formFields.CondInvestSumType === 1">
            <span>bis</span>
            <currency-input class="form-control flex-grow-0" v-model="formFields.CondInvestSumTo" currency="EUR"
              locale="de" :allow-negative="false" required />
          </template>
        </div>

        <hr />

        <div class="d-flex flex-column gap-3 ps-3">
          <div class="d-flex flex-column gap-2">
            <div class="d-flex align-items-center justify-content-start gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path d="M3.3335 8.79639H12.6668" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M8 4.12964L12.6667 8.79631L8 13.463" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>

              <span class="blue-headline">Wievel davon muss als Eigenkapital vorhanden sein?</span>
            </div>

            <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center gap-3" :style="{
              paddingLeft: '24px',
            }">
              <b-form-select class="form-control flex-grow-0" v-model="formFields.CondRequiredCapitalType"
                :options="computedCondFinanceTypes"></b-form-select>
              <hr class="vertical d-none d-sm-block" />

              <currency-input class="form-control flex-grow-0" v-model="formFields.CondRequiredCapitalFrom"
                currency="EUR" locale="de" :allow-negative="false" required />

              <template v-if="formFields.CondRequiredCapitalType === 1">
                <span>bis</span>
                <currency-input class="form-control flex-grow-0" v-model="formFields.CondRequiredCapitalTo"
                  currency="EUR" locale="de" :allow-negative="false" required />
              </template>
            </div>

            <div class="error d-flex align-items-center gap-2" v-if="computedHasCondErrorInEditForm.RequiredCapital"
              :style="{
                paddingLeft: '24px',
              }">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M12 4L4 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4 4L12 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <span>darf das Minimum der Gesamtinvestition ({{
                computedCondInvestSumMinimumInEditForm
              }}) <strong>nicht überschreiten</strong></span>
            </div>
          </div>

          <div class="d-flex flex-column gap-2">
            <div class="d-flex align-items-center justify-content-start gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path d="M3.3335 8.79639H12.6668" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M8 4.12964L12.6667 8.79631L8 13.463" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>

              <span class="blue-headline">Wievel davon beträgt die Eintrittsgebühr?</span>
            </div>

            <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center gap-3" :style="{
              paddingLeft: '24px',
            }">
              <b-form-select class="form-control flex-grow-0" v-model="formFields.CondEntryFeeType"
                :options="computedCondFinanceTypes"></b-form-select>
              <hr class="vertical d-none d-sm-block" />

              <currency-input class="form-control flex-grow-0" v-model="formFields.CondEntryFeeFrom" currency="EUR"
                locale="de" :allow-negative="false" required />

              <template v-if="formFields.CondEntryFeeType === 1">
                <span>bis</span>
                <currency-input class="form-control flex-grow-0" v-model="formFields.CondEntryFeeTo" currency="EUR"
                  locale="de" :allow-negative="false" required />
              </template>
            </div>

            <div class="error d-flex align-items-center gap-2" v-if="computedHasCondErrorInEditForm.EntryFee" :style="{
              paddingLeft: '24px',
            }">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M12 4L4 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4 4L12 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <span>darf das Minimum der Gesamtinvestition ({{
                computedCondInvestSumMinimumInEditForm
              }}) <strong>nicht überschreiten</strong></span>
            </div>
          </div>

          <div class="d-flex flex-column gap-2">
            <div class="d-flex align-items-center justify-content-start gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path d="M3.3335 8.79639H12.6668" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M8 4.12964L12.6667 8.79631L8 13.463" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>

              <span class="blue-headline">Wievel davon wird für die Erstausstattung benötigt?</span>
            </div>

            <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center gap-3" :style="{
              paddingLeft: '24px',
            }">
              <b-form-select class="form-control flex-grow-0" v-model="formFields.CondInitialCapitalType"
                :options="computedCondFinanceTypesInitialCapital"></b-form-select>
              <hr class="vertical d-none d-sm-block" v-if="computedShowSumFieldsForIniticalCapital" />

              <currency-input class="form-control flex-grow-0" v-if="computedShowSumFieldsForIniticalCapital"
                v-model="formFields.CondInitialCapitalFrom" currency="EUR" locale="de" :allow-negative="false"
                required />

              <template v-if="formFields.CondInitialCapitalType === 1">
                <span>bis</span>
                <currency-input class="form-control flex-grow-0" v-model="formFields.CondInitialCapitalTo"
                  currency="EUR" locale="de" :allow-negative="false" required />
              </template>
            </div>

            <div class="error d-flex align-items-center gap-2" v-if="computedHasCondErrorInEditForm.InitialCapital"
              :style="{
                paddingLeft: '24px',
              }">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M12 4L4 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4 4L12 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <span>darf das Minimum der Gesamtinvestition ({{
                computedCondInvestSumMinimumInEditForm
              }}) <strong>nicht überschreiten</strong></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="d-flex flex-column gap-3">
      <div class="d-flex flex-column gap-2">
        <span class="blue-headline">Daraus generiert sich am Messestand der folgende Text:</span>
        <ConditionsGeneratedText :currentBrand="formFields" />
      </div>

      <div class="d-flex flex-column gap-2">
        <span class="blue-headline">Hier können Sie individuelle Details hinzufügen:</span>

        <textarea class="form-control w-100" v-model="formFields.CondDescription" :style="{
          height: '140px',
          minHeight: '200px',
        }"></textarea>
      </div>
    </div>

    <hr />

    <div class="d-flex flex-column w-100 gap-3">
      <div class="d-flex flex-column gap-1">
        <span class="fw-bold">Anforderungen an die individuellen Details zu den
          Konditionen:</span>
        <ul class="special">
          <li :class="{
            success: formFields.CondDescription !== null && formFields.CondDescription.length <= 500,
            error: formFields.CondDescription !== null && formFields.CondDescription.length > 500,
          }">
            Die individuellen Details dürfen max. 500 Zeichen haben
          </li>
          <li>
            Der Text soll etwaige Besonderheiten beschreiben, die nicht durch
            die Standard-Angaben ersichtlich sind
          </li>
          <li>
            Der Texte sollte hohe Erstinvestitionen begründen bzw.
            "rechtfertigen", finanzielle Sicherheit bzw. ein geringes Risiko
            vermitteln oder besonders positive finanzielle Anreize
            hervorheben, z. B. “Keine Lizenzgebühren” o.ä.
          </li>
          <li>
            Der Text sollte positiv formuliert sein und darstellen, dass sich
            die Investition lohnen wird
          </li>
          <li class="d-flex align-items-start inner-list">
            <div class="d-flex flex-column align-items-start gap-1">
              <strong>Beispiel:</strong>
              <span>“Dank der relativ geringen Erstinvestition kannst du meistens
                recht schnell deinen ersten Gewinn machen. Zudem verspricht
                dir eine Franchisepartnerschaft mit
                {{ currentBrand.Name }} eine überdurchschnittlich hohe
                Umsatzrendite.”</span>
            </div>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>
