<template>
  <div class="w-100">
    <button class="btn btn-secondary w-100" @click="handleUploadButtonClick" :disabled="isUploadButtonDisabled">
      <slot>
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
          <path
            d="M13.0417 2.875H3.70833C2.97195 2.875 2.375 3.47195 2.375 4.20833V13.5417C2.375 14.278 2.97195 14.875 3.70833 14.875H13.0417C13.778 14.875 14.375 14.278 14.375 13.5417V4.20833C14.375 3.47195 13.778 2.875 13.0417 2.875Z"
            stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14.3757 10.8748L11.0423 7.5415L3.70898 14.8748" stroke="#00719F" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M6.04102 7.5415C6.5933 7.5415 7.04102 7.09379 7.04102 6.5415C7.04102 5.98922 6.5933 5.5415 6.04102 5.5415C5.48873 5.5415 5.04102 5.98922 5.04102 6.5415C5.04102 7.09379 5.48873 7.5415 6.04102 7.5415Z"
            stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span>Bild hochladen</span>
      </slot>
      <input ref="fileInput" class="d-none" type="file" accept="image/*" @change="handleNewImageSelected" />
    </button>
    <b-modal v-model="isCropModalOpen" no-close-on-backdrop>

      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false" />

      <template v-slot:modal-title>
        Neues Bild
      </template>

      <template v-slot:modal-footer>

        <button class="btn btn-secondary" @click="handleAbortCrop">
          Abbrechen
        </button>
        <button class="btn btn-primary" @click="handleSaveImageCrop">
          Speichern
        </button>

      </template>

      <Cropper :src="uploadedImageString" @change="handleCropChange" defaultBoundaries="fill" :stencil-props="{
        aspectRatio
      }" class="position-relative" />

    </b-modal>
  </div>
</template>

<script>
import { useBrandStore } from '@/stores/brandStore'
import { Cropper } from 'vue-advanced-cropper'
import Loading from 'vue-loading-overlay'

export default {
  name: 'ImageUploadButton',
  components: {
    Cropper,
    Loading
  },
  props: ['folder', 'aspectRatio', 'title'],
  emits: ['newImageSaved'],
  data: function () {
    return {
      isLoading: false,
      isCropModalOpen: false,
      uploadedImageString: null,
      uploadedImageCanvas: null
    }
  },
  methods: {
    handleUploadButtonClick () {
      this.$refs.fileInput.click()
    },
    handleCropChange ({ coordinates, canvas }) {
      this.uploadedImageCanvas = canvas
    },
    handleAbortCrop () {
      this.isLoading = false
      this.isCropModalOpen = false
      this.uploadedImageString = null
      this.uploadedImageCanvas = null
      this.$refs.fileInput.value = ''
    },
    async handleSaveImageCrop () {
      try {
        this.isLoading = true
        const brandStore = useBrandStore()
        const fileBlob = await new Promise(resolve => this.uploadedImageCanvas.toBlob(resolve))
        const uploadedImage = await brandStore.uploadImage(fileBlob, this.folder)
        if (uploadedImage) {
          this.$emit('newImageSaved', uploadedImage)
        }
        this.isCropModalOpen = false
        this.uploadedImageString = null
        this.uploadedImageCanvas = null
        this.$refs.fileInput.value = ''
      } catch (error) {
        console.error('Error at Image Upload', error)
        this.$toast.error('Beim Hochladen des Bildes ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async handleNewImageSelected (event) {
      try {
        const file = event.target.files[0]
        if (!file) {
          return
        }
        this.isLoading = true
        const reader = new FileReader()
        reader.onload = (e) => {
          this.uploadedImageString = e.target.result
          this.isLoading = false
        }
        reader.readAsDataURL(file)
        this.isCropModalOpen = true
      } catch (error) {
        console.error('Error at Image Upload', error)
        this.$toast.error('Beim Hochladen des Bildes ist ein Fehler aufgetreten.')
        this.isLoading = false
      }
    }
  },
  computed: {
    isUploadButtonDisabled () {
      return this.isLoading
    }
  }
}

</script>

<style lang="scss" scoped></style>
