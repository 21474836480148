<template>
  <div v-if="video">
    <button v-if="video.MediumID" :id="computedButtonId" class="btn btn-secondary d-flex gap-2 w-100" @click="startVideoEdit">
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.833 2.00004C12.0081 1.82494 12.216 1.68605 12.4447 1.59129C12.6735 1.49653 12.9187 1.44775 13.1663 1.44775C13.414 1.44775 13.6592 1.49653 13.8879 1.59129C14.1167 1.68605 14.3246 1.82494 14.4997 2.00004C14.6748 2.17513 14.8137 2.383 14.9084 2.61178C15.0032 2.84055 15.052 3.08575 15.052 3.33337C15.052 3.58099 15.0032 3.82619 14.9084 4.05497C14.8137 4.28374 14.6748 4.49161 14.4997 4.66671L5.49967 13.6667L1.83301 14.6667L2.83301 11L11.833 2.00004Z"
          stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <span>Bearbeiten</span>
    </button>
    <b-modal v-model="computedIsModalOpen" modal-class="new-fair-modal">
      <Loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false" />

      <section class="quote-box d-flex flex-column gap-5">

        <div class="d-flex gap-4 w-100">
          <div v-if="video.Medium" class="video-image-container overflow-hidden">
            <VideoPreviewImageContainer :video.sync="video.Medium" />
          </div>
          <div class="d-flex flex-column gap-1 flex-grow-1">
            <strong>Videotitel:</strong>
            <div class="d-flex gap-1 align-items-center">
              <input :disabled="!editTitle" type="text" class="form-control" v-model="formData.title" maxlength="75" placeholder="Neuer Video Titel (maximal 75 Zeichen)" />
              <button v-if="!editTitle" class="d-flex gap-1 btn btn-secondary flex-shrink-0 min-button-width"
                :disabled="isLoadingGeneratedThumbnails" @click="handleEditTitleClick">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.833 2.00004C12.0081 1.82494 12.216 1.68605 12.4447 1.59129C12.6735 1.49653 12.9187 1.44775 13.1663 1.44775C13.414 1.44775 13.6592 1.49653 13.8879 1.59129C14.1167 1.68605 14.3246 1.82494 14.4997 2.00004C14.6748 2.17513 14.8137 2.383 14.9084 2.61178C15.0032 2.84055 15.052 3.08575 15.052 3.33337C15.052 3.58099 15.0032 3.82619 14.9084 4.05497C14.8137 4.28374 14.6748 4.49161 14.4997 4.66671L5.49967 13.6667L1.83301 14.6667L2.83301 11L11.833 2.00004Z"
                    stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span>Titel ändern</span>
              </button>
              <button v-else class="d-flex gap-1 btn btn-secondary flex-shrink-0 min-button-width"
                :disabled="isLoadingGeneratedThumbnails" @click="handleEditTitleClick">
                <inline-svg width="16" height="16" :src="require('@/assets/images/icons/3.0/create-thumbnails.svg')" />
                <span v-if="computedIsSproutVideo">Speichern / Thumbnails generieren</span>
                <span v-else>Speichern</span>
              </button>
            </div>
            <div v-if="titleIsTooLong" class="d-flex gap-1 align-items-center text-danger">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4L4 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4 4L12 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span>Der Titel darf maximal 75 Zeichen enthalten</span>
            </div>
          </div>
        </div>

        <b-card class="w-100" v-if="isAdmin">
          <template #header>
            <div class="d-flex justify-content-between align-items-center gap-3">
              <strong>Weitere Felder</strong>
              <span class="badge text-bg-warning">Admin-Modus</span>
            </div>
          </template>

          <div class="d-flex flex-column gap-3">
            <b-form-group
              label="URL"
              label-for="videoUrlInputField"
            >
              <b-form-input id="videoUrlInputField" v-model="formData.url" trim></b-form-input>
            </b-form-group>

            <b-form-group
              label="Dateiname"
              label-for="videoFilenameInputField"
            >
              <b-form-input id="videoFilenameInputField" v-model="formData.filename" trim></b-form-input>
            </b-form-group>
          </div>

        </b-card>

        <div class="d-flex flex-column gap-3">
          <strong>Thumbnail auswählen:</strong>
          <template v-if="isLoadingGeneratedThumbnails || generatedThumbnails.length">
            <div class="d-flex flex-column gap-3" v-if="isLoadingGeneratedThumbnails">
              <div class="d-flex justift-start gap-3" v-for="(chunk, rowIndex) in chunkedLoadingArray" :key="rowIndex">
                <div v-for="(item, colIndex) in chunk" :key="colIndex">
                  <b-skeleton class="generated-thumbnail-loader" animation="wave"></b-skeleton>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column gap-3" v-else>
              <div class="d-flex justift-start gap-3" v-for="(chunk, rowIndex) in chunkedArray" :key="rowIndex">
                <a href="javascript:" class="video-image-container-active position-relative" :class="{
                  active: selectedThumbnail && selectedThumbnail.image_url === generatedThumbnail.image_url
                }" v-for="(generatedThumbnail, colIndex) in chunk" :key="colIndex"
                  @click="selectThumbnail(generatedThumbnail)">
                  <img class="img-fluid preview-image" :src="generatedThumbnail.image_url" />
                  <svg v-if="selectedThumbnail && selectedThumbnail.image_url === generatedThumbnail.image_url"
                    width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1.25" width="30" height="30" rx="9" fill="#A4D82E" />
                    <rect x="1" y="1.25" width="30" height="30" rx="9" stroke="white" stroke-width="2" />
                    <path d="M24 10.25L13 21.25L8 16.25" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </a>
              </div>
            </div>
          </template>

          <div class="d-flex gap-3" v-if="video.Medium">
            <a href="javascript:" class="video-image-container-active position-relative" @click="selectDefaultThumbnail"
              :class="{
                active: isDefaultSelected
              }">
              <VideoPreviewImageContainer :video.sync="video.Medium" />
              <svg v-if="isDefaultSelected" width="32" height="33" viewBox="0 0 32 33" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1.25" width="30" height="30" rx="9" fill="#A4D82E" />
                <rect x="1" y="1.25" width="30" height="30" rx="9" stroke="white" stroke-width="2" />
                <path d="M24 10.25L13 21.25L8 16.25" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </a>
            <div class="d-flex flex-column gap-3 justify-content-center">
              <button class="btn btn-secondary d-flex align-items-center justify-content-start gap-2"
                v-if="computedIsSproutVideo && generatedThumbnails.length === 0" @click="generateThumbnails"
                :disabled="isLoadingGeneratedThumbnails">
                <inline-svg width="16" height="16" :src="require('@/assets/images/icons/3.0/create-thumbnails.svg')" />
                <span>Neue Thumbnails generieren</span>
              </button>
              <ImageUploadButton @newImageSaved="handleNewUploadedImage" folder="SystemRoot/Fair/FairVideos" :aspectRatio="16/9">
                <inline-svg width="16" height="16" :src="require('@/assets/images/icons/3.0/upload.svg')" />
                <span>Neues Thumbnail hochladen</span>
              </ImageUploadButton>
            </div>
          </div>
        </div>

      </section>

      <template #modal-title>
        <div class="d-flex justify-content-start align-items-center gap-2">
          <span class="modal-title">Video bearbeiten</span>
        </div>
      </template>
      <template #modal-header-close>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path d="M24 8L8 24" stroke="#3F4957" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8 8L24 24" stroke="#3F4957" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </template>
      <template #modal-footer>
        <button type="button" @click="handleSave" class="btn btn-primary btn-220" :disabled="isLoadingGeneratedThumbnails">Änderungen speichern</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import Loading from 'vue-loading-overlay'
import { useBrandStore } from '@/stores/brandStore'
import VideoPreviewImageContainer from '@/components/fair/VideoPreviewImageContainer.vue'
import ImageUploadButton from '@/components/fair/ImageUploadButton.vue'
import { usePageStore } from '@/stores/pageStore'

export default {
  name: 'VideoEditButton',
  props: ['video'],
  components: {
    Loading,
    InlineSvg,
    VideoPreviewImageContainer,
    ImageUploadButton
  },
  data () {
    return {
      pageStore: usePageStore(),
      isLoading: false,
      isModalOpen: false,
      editTitle: false,
      isDefaultSelected: true,
      selectedThumbnail: null,
      isLoadingGeneratedThumbnails: false,
      generatedThumbnails: [],
      formData: {
        title: '',
        url: '',
        filename: ''
      }
    }
  },
  methods: {
    async handleNewUploadedImage (newImage) {
      const brandStore = useBrandStore()
      await brandStore.fpApi._put('Medium', {
        MediumID: this.video.Medium.MediumID,
        StillImageID: newImage.MediumID
      })
      this.video.StillImageID = newImage.MediumID
      this.video.StillImage = newImage
      this.video.Title = this.formData.title

      this.video.Medium.StillImageID = newImage.MediumID
      this.video.Medium.StillImage = newImage
      this.video.Medium.Title = this.formData.title

      this.$toast.success('Thumbnail erfolgreich hochgeladen!')
      this.isModalOpen = false
      this.$emit('updated', this.video)
    },
    async handleSave () {
      try {
        this.isLoading = true

        this.video.Title = this.formData.title
        this.video.Medium.Title = this.formData.title

        if (this.isDefaultSelected === true) {
          const brandStore = useBrandStore()

          if (this.isAdmin) {
            await brandStore.fpApi._put('Medium', {
              MediumID: this.video.Medium.MediumID,
              Title: this.formData.title,
              URL: this.formData.url,
              Filename: this.formData.filename
            })
            this.video.Medium.URL = this.formData.url
            this.video.Medium.Filename = this.formData.filename
          } else {
            await brandStore.fpApi._put('Medium', {
              MediumID: this.video.Medium.MediumID,
              Title: this.formData.title
            })
          }

          this.isModalOpen = false
          this.$emit('updated', this.video)
          return
        }

        if (!this.isDefaultSelected && this.selectedThumbnail) {
          const brandStore = useBrandStore()
          const blob = await fetch(this.selectedThumbnail.image_url).then(r => r.blob())
          const uploadedMedium = await brandStore.uploadImage(blob)
          await brandStore.fpApi._put('Medium', {
            MediumID: this.video.Medium.MediumID,
            StillImageID: uploadedMedium.MediumID,
            Title: this.formData.title
          })
          this.video.Medium.StillImageID = uploadedMedium.MediumID
          this.video.Medium.StillImage = uploadedMedium

          this.video.StillImageID = uploadedMedium.MediumID
          this.video.StillImage = uploadedMedium

          if (this.isAdmin) {
            this.video.Medium.URL = this.formData.url
            this.video.Medium.Filename = this.formData.filename
          }

          this.selectedThumbnail = null
          this.isDefaultSelected = true
          this.generatedThumbnails = []

          this.isModalOpen = false
          this.$emit('updated', this.video)
        }
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern deiner Korrekturwünsche ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async startVideoEdit () {
      if (typeof this.video.Medium === 'undefined') {
        const brandStore = useBrandStore()
        const loadedMedium = await brandStore.fpApi._post('Medium', {
          MediumID: this.video.MediumID
        })
        this.video.Medium = loadedMedium.body.Result.Data
      }

      this.isModalOpen = true
      this.formData.title = this.video.Medium.Title
      if (this.isAdmin) {
        this.formData.url = this.video.Medium.URL
        this.formData.filename = this.video.Medium.Filename
      }
      this.editTitle = false
      this.isDefaultSelected = true
      this.isLoadingGeneratedThumbnails = false
      this.generatedThumbnails = []
    },
    selectThumbnail (selectedThumbnail) {
      this.isDefaultSelected = false
      this.selectedThumbnail = selectedThumbnail
    },
    selectDefaultThumbnail () {
      this.isDefaultSelected = true
      this.selectedThumbnail = null
    },
    handleEditTitleClick () {
      if (this.editTitle === false) {
        this.editTitle = true
      } else {
        this.video.Title = this.formData.title
        this.video.Medium.Title = this.formData.title
        if (this.computedIsSproutVideo) {
          this.generateThumbnails()
        } else {
          this.editTitle = false
        }
        this.$emit('updated', this.video)
      }
    },
    async generateThumbnails () {
      try {
        this.isLoadingGeneratedThumbnails = true
        const generatedThumbnailsResponse = await this.app.APIService._post('thumbnail/generate', {
          BrandID: this.currentBrand.BrandID,
          MediumID: this.video.Medium.MediumID,
          Title: this.formData.title || this.video.Medium.Title
        })
        const generatedThumbnails = generatedThumbnailsResponse.body
        this.generatedThumbnails = [...generatedThumbnails.variant1, ...generatedThumbnails.variant2]

        if (typeof generatedThumbnails.usageCount === 'undefined') {
          this.$toast.warning('Sie haben heute bereits zu viele Thumbnails generiert. Bitte versuchen Sie es morgen erneut.')
        }
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern deiner Bearbeitung ist ein Fehler aufgetreten.')
      } finally {
        this.isLoadingGeneratedThumbnails = false
      }
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    isAdmin: function () {
      return this.app.isAdministrator
    },
    computedButtonId: function () {
      return `edit-video-button-${this.video.MediumID}`
    },
    titleIsTooLong: function () {
      return this.formData.title.length > 75
    },
    currentBrand: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentBrand
    },
    computedIsModalOpen: {
      get () {
        return this.isModalOpen
      },
      set (val) {
        this.isModalOpen = val
      }
    },
    computedIsSproutVideo () {
      return this.video.Medium && String(this.video.Medium.Filename).trim().toLocaleLowerCase() === 'sproutvideo'
    },
    computedShowGeneratedThumbnails () {
      return this.isLoadingGeneratedThumbnails || this.generatedThumbnails.length > 0
    },
    chunkedLoadingArray () {
      const chunkSize = 2
      return [...Array(8).keys()].reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize)
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []
        }
        resultArray[chunkIndex].push(item)
        return resultArray
      }, [])
    },
    chunkedArray () {
      const chunkSize = 2
      return this.generatedThumbnails.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize)
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []
        }
        resultArray[chunkIndex].push(item)
        return resultArray
      }, [])
    }
  },
  watch: {
    'pageStore.getVideoEdit': async function (newValue) {
      if (newValue && newValue.MediumID === this.video.MediumID) {
        await this.startVideoEdit()
        if (this.computedIsSproutVideo) {
          this.generateThumbnails()
        }
      }
    }
  }
}
</script>

<style lang="scss">
.video-image-container-active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 146px;

  img {
    max-width: 260px;
    outline: 4px solid transparent;
    border-radius: 8px;
  }

  svg {
    position: absolute;
    right: 16px;
    bottom: 8px;
  }

  &.active {

    img {
      outline-color: #00719F;
    }

  }

}
</style>

<style lang="scss" scoped>
section {
  background-color: #EFF1F4;
}

.video-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 72px;

  img {
    border-radius: 8px;
  }
}

.min-button-width {
  width: 300px;
  height: 44px;
  text-align: center;
}

.generated-thumbnail-loader {
  display: flex;
  width: 260px;
  height: 146.25px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: #F7F8F9;
}

.preview-image {
  max-width: 260px;
  border-radius: 8px;
}
</style>
