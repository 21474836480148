import packageInfo from '../../package'
import sanitizeHtml from 'sanitize-html'
const cfg = {
  API_URL: 'https://members.franchiseuniversum.de/api/',
  API_APP: 'MB',
  API_VERSION: 'v1/',
  MEDIA_API_URL: 'https://members.franchiseuniversum.de/drive/',
  CLIENT_VERSION: packageInfo.version,
  FROALA_KEY:
    '3AC6eD4A5E4B3D3D3C-22VKOG1FGULVKHXDXNDXc2a1Kd1SNdF3H3A8D6D4A3C3E3B2A15==',
  FPSERVICE_URL: 'https://members.franchiseuniversum.de/service/api/',
  PRODUCTION: false,
  FPDE_API_URL: 'https://www.franchiseportal.de/api/',
  PWRESET_FUNCTION: 'https://members.franchiseuniversum.de/pwreset/',
  IMAGE_UPLOAD_FUNCTION: 'https://members.franchiseuniversum.de/service/image/upload/',
  IMAGE_RESIZE_FUNCTION: 'https://members.franchiseuniversum.de/service/image/resize/',
  API_STRIPE: 'https://service.franchiseportal.de/api/stripe/',
  API_SQL: 'https://service.franchiseportal.de/api/sql/',
  WEBSITE_URL: 'https://www.franchiseportal.de/',
  DEFAULT_HTML_ALLOWS: {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['br', 'img', 'h2']).filter(tag => {
      return !['div', 'table', 'thead', 'tbody', 'tr', 'th', 'pre'].includes(tag)
    }),
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      img: ['src', 'style', 'class'],
      p: ['style']
    },
    allowedSchemes: ['http', 'https', 'mailto', 'tel']
  },
  GOOGLE_RECAPTCHA_SITEKEY: '6LdPZKkaAAAAADPOPs9YRRz21hlkTtv53yeOU-Dd',
  STRIPE_PUBLIC_KEY: 'pk_live_51IIakBAIBz8zc47o6GHCdGF2k8XhQObxLRCSwg19NC4SVsT7B26Hl3yKO3q1ex5EnhhQBXbf8B4CzFX0WPHHhI1i00B3aR39QO',
  MANDATORY_VAT_COUNTRIES: [
    'BE', 'BG', 'DK', 'EE', 'FI', 'FR', 'GR', 'IE', 'IT', 'HR', 'LV', 'LT', 'LU', 'MT', 'NL', 'AT', 'PL', 'PT', 'RO', 'SM', 'SE', 'SK', 'SI', 'ES', 'CZ', 'HU', 'XI', 'CY', 'CH'
  ],
  GOOGLE_MAPS_API_KEY: 'AIzaSyDsL2cxFaOtwLm7TCO7u_CpRx8pIMQgpDs',
  CHATBOT_MINIMUM_COST: 750
}

const hostName = window.location.hostname

switch (hostName) {
  case 'members.franchiseuniversum.de':
    cfg.PRODUCTION = true
    break
}

if (cfg.PRODUCTION !== true) {
  cfg.API_URL = 'https://test.franchiseportal.link/'
  cfg.MEDIA_API_URL = 'https://members-dev.franchiseuniversum.de/drive/'
  cfg.FPSERVICE_URL = 'https://members-dev.franchiseuniversum.de/service/api/'
  cfg.PWRESET_FUNCTION = 'https://members-dev.franchiseuniversum.de/pwreset/'
  cfg.IMAGE_UPLOAD_FUNCTION = 'https://service-dev.franchiseportal.de/image/upload/'
  cfg.IMAGE_RESIZE_FUNCTION = 'https://service-dev.franchiseportal.de/image/resize/'
  cfg.FPDE_API_URL = 'https://staging.franchiseportal.de/api/'
  cfg.API_STRIPE = 'https://service-dev.franchiseportal.de/api/stripe/'
  cfg.API_SQL = 'https://service-dev.franchiseportal.de/api/sql/'
  cfg.STRIPE_PUBLIC_KEY = 'pk_test_51IIakBAIBz8zc47o1QCnC6cMi3JpNIuecYp4OmCyfgbcKLbqR0euTdtJLAtTUqpqoV9UKKXEmXVmMgAsdv7FcooF00xiiyv49j'
  cfg.WEBSITE_URL = 'https://staging.franchiseportal.de/'
}

// if (window.location.hostname === 'localhost') {
//   cfg.API_STRIPE = 'http://localhost/api/stripe/'
//   cfg.FPSERVICE_URL = 'http://localhost/api/'
//   cfg.API_SQL = 'http://localhost/api/sql/'
//   cfg.IMAGE_RESIZE_FUNCTION = 'http://localhost/image/resize/'
//   cfg.MEDIA_API_URL = 'https://staging.franchiseportal.de/api/drive/'
// }

export default cfg
