<template>
  <div class="video-preview-image-container">
    <b-skeleton v-if="isLoading" width="100%" height="100%" />
    <img v-else :src="computedImageUrl" class="img-fluid" />
  </div>
</template>

<script>
import { useBrandStore } from '@/stores/brandStore'
import Config from '@/config'

export default {
  name: 'VideoPreviewImageContainer',
  props: ['video'],
  data: function () {
    return {
      FileID: null,
      isLoading: false
    }
  },
  methods: {
    async getPreviewImage () {
      const brandStore = useBrandStore()
      try {
        const previewImageResponse = await brandStore.fpApi._post('Medium', {
          MediumID: this.video.StillImageID
        })
        if (previewImageResponse) {
          this.FileID = previewImageResponse.body.Result.Data.FileID
          this.isLoading = false
        } else {
          throw new Error('Image Not Found')
        }
      } catch (error) {
        console.error('Error at setting preview image', error)
      }
    }
  },
  computed: {
    computedImageUrl: function () {
      if (!this.FileID) return ''
      return `${Config.MEDIA_API_URL}id/${this.FileID}`
    }
  },
  watch: {
    video: {
      handler: function (newValue) {
        if (newValue.StillImage === null) {
          this.isLoading = true
          this.getPreviewImage()
        } else {
          this.FileID = newValue.StillImage.FileID
          this.isLoading = false
        }
      },
      immediate: true
    }
  }

}

</script>

<style lang="css" scoped>
.video-preview-image-container {
  flex-grow: 1;
  height: inherit;
}
</style>
