<template>
  <div class="mb-bar-chart p-4 mb-4 card">
      <h2 v-if="title != ''">{{title}}</h2>
      <div v-if="isLoading" class="mb-loading"><span v-t="'page.statistics.loading'"></span></div>
      <div v-if="!isLoading && showNoDataHint" class="mb-no-chart-data"><span v-t="'page.statistics.nodata'"></span></div>
      <bar-chart
        v-if="!isLoading && chartData.datasets != null && chartData.datasets.length > 0"
        :chartData="chartData"
        :options="chartData.options"
        :plugins="[]"
        :totalText="this.$t('page.statistics.total')"
      ></bar-chart>
  </div>
</template>

<script>
import BarChart from './Bar.vue'
import moment from 'moment'

export default {
  components: {
    BarChart
  },
  name: 'TopBrandChartBar',
  props: ['startDate', 'endDate', 'groupby', 'title', 'currentBrand', 'API'],
  data: function () {
    return {
      chartData: {},
      chartLabels: [],
      barChartDatasetsPortal: [],
      isLoading: false,
      showNoDataHint: false
    }
  },
  computed: {
    parentPage: function () {
      if (this.parent == null) return this.$parent
      return this.parent
    }
  },
  created: function () {
  },
  methods: {
    getChartData (groupByChanged = false) {
      if (this.isLoading || this.currentBrand == null || this.currentBrand.BrandID == null) return

      this.isLoading = true
      const promiseAll = []
      if (this.currentBrand != null &&
              this.currentBrand.BrandModules[0].Fair != null &&
              this.currentBrand.BrandModules[0].Fair.FairPortals.length > 0) {
        const fairPortals = this.currentBrand.BrandModules[0].Fair.FairPortals
        this.currentPortals = []
        if (!groupByChanged) {
          fairPortals.forEach(fairPortal => {
            let portalAdded = false
            if (fairPortal.PortalID === 1) {
              this.currentPortals.push('DE')
              promiseAll.push(this.getDetailBOChartData(fairPortal.PortalID))
              portalAdded = true
            }
            if (fairPortal.PortalID === 2) {
              this.currentPortals.push('CH')
              promiseAll.push(this.getDetailBOChartData(fairPortal.PortalID))
              portalAdded = true
            }
            if (fairPortal.PortalID === 3) {
              this.currentPortals.push('AT')
              promiseAll.push(this.getDetailBOChartData(fairPortal.PortalID))
              portalAdded = true
            }
            if (!portalAdded) {
              promiseAll.push(Promise.resolve(null))
            }
          })
        } else {
          promiseAll.push(this.chartDataDE)
          promiseAll.push(this.chartDataCH)
          promiseAll.push(this.chartDataAT)
        }
      }

      this.setColumnCount()
      Promise.all(promiseAll)
        .then(async (results) => {
          const [detailBODataDE, detailBODataCH, detailBODataAT] = results
          let detailBOChartBarData = null
          // let avgLineChartData = []
          if ((detailBODataDE != null && detailBODataDE !== false) || (detailBODataCH != null && detailBODataCH !== false) || (detailBODataAT != null && detailBODataAT !== false)) {
            this.chartDataDE = detailBODataDE
            this.chartDataCH = detailBODataCH
            this.chartDataAT = detailBODataAT
            detailBOChartBarData = this.mapChartBOBarData(detailBODataDE, detailBODataCH, detailBODataAT, this.dataColCount, momentStarDate)
            this.showNoDataHint = false
          } else {
            this.showNoDataHint = true
          }

          this.chartData = this.getDefaultChartData()
          this.chartData.datasets = detailBOChartBarData
          this.chartData.options.tooltips.callbacks.beforeLabel = this.tooltipCallbackBeforeLabel
          this.chartData.options.tooltips.callbacks.label = this.tooltipCallbackLabel
          this.chartData.options.legend.labels.filter = this.getFilteredLabels
          this.chartData.options.legend.labels.generateLabels = this.changeLegendText
          this.chartData.labels = this.chartLabels
        }).finally(() => {
          this.isLoading = false
        })
      const momentStarDate = moment(this.startDate, 'DD.MM.YYYY')
      const momentEndDate = moment(this.endDate, 'DD.MM.YYYY')
      let dataColCount = momentEndDate.diff(momentStarDate, this.groupby.toLowerCase() + 's') + 1
      if (dataColCount === 1 && (this.groupby === 'Week' || this.groupby === 'Month')) {
        if (momentStarDate.format('w') !== momentEndDate.format('w')) dataColCount++
      }
    },
    setColumnCount () {
      const momentStarDate = moment(this.startDate, 'DD.MM.YYYY')
      const momentEndDate = moment(this.endDate, 'DD.MM.YYYY')
      this.dataColCount = momentEndDate.diff(momentStarDate, this.groupby.toLowerCase() + 's') + 1
      if (this.dataColCount === 1 && (this.groupby === 'Week' || this.groupby === 'Month')) {
        if (momentStarDate.format('w') !== momentEndDate.format('w')) this.dataColCount++
      }
    },
    getDetailBOChartData (portalID) {
      this.showRefreshButton = false
      return this.parentPage.SM.getDetailBOChartData(portalID, this.currentBrand.BrandID, 'TOP Brand geklickt', this.startDate, this.endDate).then(result => {
        if (result === false) {
          this.showErrorMessage = true
          this.showRefreshButton = true
          this.showNoDataHint = false
          return false
        }
        return result
      })
    },
    mapChartBOBarData (apiDataDE, apiDataCH, apiDataAT, dataColCount, startDate) {
      // if (this.isLoading) return

      this.isLoading = true
      this.chartLabels = []
      startDate = moment(this.startDate, 'DD.MM.YYYY')

      const dataBrand = {
        de: new Array(dataColCount).fill(0),
        at: new Array(dataColCount).fill(0),
        ch: new Array(dataColCount).fill(0),
        ug: new Array(dataColCount).fill(0)
      }
      const dataPrev = {
        de: new Array(dataColCount).fill(0),
        at: new Array(dataColCount).fill(0),
        ch: new Array(dataColCount).fill(0),
        ug: new Array(dataColCount).fill(0)
      }
      let currentDay = startDate
      let dayFormat = 'YYYY-MM-DD'
      let labelFormat = 'DD.MM'
      if (this.groupby === 'Week') {
        dayFormat = 'GGGG/WW'
        labelFormat = dayFormat
      }
      if (this.groupby === 'Month') {
        dayFormat = 'YYYY-MM'
        labelFormat = dayFormat
      }
      const portals = ['de', 'ch', 'at']
      for (let i = 0; i < dataColCount; i++) {
        if (!this.chartLabels.includes(currentDay.format(labelFormat))) this.chartLabels.push(currentDay.format(labelFormat))
        portals.forEach(portal => {
          let apiData = null
          if (portal === 'de') apiData = apiDataDE
          if (portal === 'ch') apiData = apiDataCH
          if (portal === 'at') apiData = apiDataAT
          if (apiData != null && apiData !== false) {
            if (!this.barChartDatasetsPortal.includes(portal)) this.barChartDatasetsPortal.push(portal)
            apiData.body.forEach(thisData => {
              if (moment(thisData.date).format(dayFormat) === currentDay.format(dayFormat)) {
                dataBrand[portal][i] += parseInt(thisData.nb_uniq_visitors)
              }
            })
          }
        })
        currentDay = startDate.add(1, this.groupby.toLowerCase() + 's')
      }

      return this.createDataSets(dataBrand, dataPrev)
    },
    createDataSets (dataBrand, dataPrev) {
      const datasets = []
      if (this.barChartDatasetsPortal.includes('de')) {
        datasets.push(
          {
            label: 'TOP-' + this.$t('page.statistics.brand') + ' DE ' + this.$t('page.statistics.brandpage'),
            data: dataBrand.de,
            backgroundColor: '#005578'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('at')) {
        datasets.push(
          {
            label: 'TOP-' + this.$t('page.statistics.brand') + ' AT ' + this.$t('page.statistics.brandpage'),
            data: dataBrand.at,
            backgroundColor: '#72bb53'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('ch')) {
        datasets.push(
          {
            label: 'TOP-' + this.$t('page.statistics.brand') + ' CH ' + this.$t('page.statistics.brandpage'),
            data: dataBrand.ch,
            backgroundColor: '#ff3823'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('rps')) {
        datasets.push(
          {
            label: this.$t('page.statistics.rps'),
            data: dataBrand.rps,
            backgroundColor: '#ffa834'
          }
        )
      }

      return datasets
    },
    getFilteredLabels (item, chart) { // Filtern der Legende
      return !item.text.includes(this.$t('page.statistics.quickview'))
    },
    changeLegendText (chart) { // Textänderung der Legende
      const data = chart.data
      const legends = Array.isArray(data.datasets)
        ? data.datasets.map(function (dataset, i) {
          return {
            text: dataset.label.replace(' ' + this.$t('page.statistics.brandpage'), '') + '   (X)',
            fillStyle: (!Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor : dataset.backgroundColor[0]),
            hidden: !chart.isDatasetVisible(i),
            lineCap: dataset.borderCapStyle,
            lineDash: dataset.borderDash,
            lineDashOffset: dataset.borderDashOffset,
            lineJoin: dataset.borderJoinStyle,
            lineWidth: dataset.borderWidth,
            strokeStyle: dataset.borderColor,
            pointStyle: dataset.pointStyle,
            datasetIndex: i
          }
        }, this)
        : []
      return legends
    },
    tooltipCallbackBeforeLabel (tooltipItem, data) {
      const label = data.datasets[tooltipItem.datasetIndex].label
      const labelGroup = label.split(' ')
      if (label.endsWith(this.$t('page.statistics.brandpage'))) {
        return labelGroup[0] + ' ' + labelGroup[1]
      }
      if (label === this.$t('page.statistics.rps')) return ' '
      return ''
    },
    tooltipCallbackLabel (tooltipItem, data) {
      const label = data.datasets[tooltipItem.datasetIndex].label
      if (label.startsWith(this.$t('page.statistics.regional'))) return label + ': ' + tooltipItem.yLabel

      const splitLabel = label.split(' ')
      return splitLabel[2] + ': ' + tooltipItem.yLabel
    },
    getDefaultChartData () {
      return {
        labels: [],
        datasets: [],
        options: {
          responsive: true,
          tooltips: {
            enabled: false,
            mode: 'label',
            callbacks: {}
          },
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{ stacked: true, ticks: { stepSize: (this.groupby === 'Month' ? 10 : 5) } }]
          },
          legend: {
            display: true,
            labels: {}
          },
          maintainAspectRatio: false
        }
      }
    }
  },
  watch: {
    'currentBrand.BrandID': function () {
      this.getChartData()
    },
    startDate: function () {
      this.getChartData()
    },
    endDate: function () {
      this.getChartData()
    },
    groupby: function () {
      this.getChartData(true)
    }
  },
  mounted: function () {
    this.getChartData()
  }
}
</script>

<style lang="less" scoped>
.mb-bar-chart {
  min-height: 400px;
  position: relative;
}
.mb-no-chart-data, .mb-loading {
  background-color: rgba(255,255,255,0.5);
  position: absolute;
  display: grid;
  place-items: center;
  height: calc(100% - (1.5rem * 2));
  width: calc(100% - (1.5rem * 2));
  font-weight: 700;
  font-size: 20px;
}
</style>
