<template>
  <FairFieldEditor title="SEO Einstellungen" :index="50" buttonSaveText="Korrekturwunsch absenden" :adminOnly="true" :showEditButton="false" :showPreview="false"
    :showContentOnEdit="true" :loading="isLoading" @adminSave="handleAdminSave"
    :is-modal-open.sync="isModalOpen" :is-admin-modal-open.sync="isAdminModalOpen"
    @update:isAdminModalOpen="toggleAdminEdit" @toggle="toggleContent" :usePageStore="true" :showOpenButton="false">
    <div class="d-flex flex-column gap-2">
      <div class="row">
        <div class="col-3 fw-bold">SEO Titel</div>
        <div class="col-9">
          {{ currentBrand.SEOTitle }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 fw-bold">SEO Beschreibung</div>
        <div class="col-9">
          {{ currentBrand.SEOMetaDescription }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 fw-bold">Kurzer Markenname</div>
        <div class="col-9">
          {{ currentBrand.ShortBrandName }}
        </div>
      </div>
    </div>

    <template v-slot:admin>
      <div class="d-flex flex-column w-100 gap-3">
        <div class="d-flex flex-column gap-1">
          <label for="seoTitleInputField">SEO Titel</label>
          <input id="seoTitleInputField" type="text" class="form-control w-100" placeholder="SEO Titel"
            v-model="formFields.SEOTitle" />
        </div>
        <div class="d-flex flex-column gap-1">
          <label for="seoMetaDescriptionInputField">SEO Beschreibung</label>
          <input id="seoMetaDescriptionInputField" type="text" class="form-control w-100" placeholder="SEO Beschreibung"
            v-model="formFields.SEOMetaDescription" />
        </div>
        <div class="d-flex flex-column gap-1">
          <label for="shortBrandNameInputField">Kurzer Markenname</label>
          <input id="shortBrandNameInputField" type="text" class="form-control w-100" placeholder="Kurzer Markenname (z.B Maloa statt MALOA Poké Bowl)"
            v-model="formFields.ShortBrandName" />
        </div>
      </div>
    </template>
  </FairFieldEditor>
</template>

<script>
import FairFieldEditor from '@/components/FairFieldEditor.vue'
import { useBrandStore } from '@/stores/brandStore'
import { usePageStore } from '@/stores/pageStore'

export default {
  name: 'FairFormHeadline',
  components: {
    FairFieldEditor
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isModalOpen: false,
      isAdminModalOpen: false,
      formFields: {
        SEOTitle: '',
        SEOMetaDescription: '',
        ShortBrandName: ''
      }
    }
  },
  methods: {
    toggleAdminEdit () {
      this.formFields.SEOTitle = this.currentBrand.SEOTitle
      this.formFields.SEOMetaDescription = this.currentBrand.SEOMetaDescription
      this.formFields.ShortBrandName = this.currentBrand.ShortBrandName
    },
    toggleContent () {
      const pageStore = usePageStore()
      if (pageStore.getCurrentOpenedFairEditTab === 1) {
        pageStore.setCurrentOpenedFairEditTab(null)
      } else {
        pageStore.setCurrentOpenedFairEditTab(1)
      }
    },
    async handleAdminSave () {
      this.isLoading = true
      try {
        const brandStore = useBrandStore()
        await brandStore.updateCurrentBrand({
          SEOTitle: this.formFields.SEOTitle,
          SEOMetaDescription: this.formFields.SEOMetaDescription,
          ShortBrandName: this.formFields.ShortBrandName
        })
        this.isAdminModalOpen = false
        this.formFields.value = ''
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentBrand
    },
    currentFair: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentFair
    },
    computedCurrentIsOpen () {
      const pageStore = usePageStore()
      return pageStore.getCurrentOpenedFairEditTab === 1
    }
  }

}
</script>
