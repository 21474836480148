// stores/counter.js
import { defineStore } from 'pinia'
import Api from '@/modules/Api'
import ApiStripe from '@/modules/Api_Stripe'
import Config from '@/config'
import axios from 'axios'
import { useUserStore } from './userStore'
import { usePageStore } from './pageStore'
import Vue from 'vue'
import i18n from '../i18n'
import Swal from 'sweetalert2'

export const useBrandStore = defineStore('brand', {
  state: () => {
    return {
      currentBrand: null,
      currentBrandID: null,
      isLoadingBrand: false,
      currentMembership: null,
      isLoadingMembership: false,
      currentLeads: [],
      markedContacts: [],
      leadsCount: 0,
      fpApi: new Api(),
      stripeApi: new ApiStripe(),
      faq: {
        default: [],
        brand: []
      }
    }
  },
  getters: {
    getCurrentBrand () {
      return this.currentBrand
    },
    getCurrentBrandID () {
      return this.currentBrandID
    },
    getDefaultFaqs () {
      return this.faq.default
    },
    getBrandFaqs () {
      return this.faq.brand
    },
    getBrandFaqIds () {
      return this.faq.brand.map(faq => faq.FaqID)
    },
    getCurrentBrandModule () {
      return this.currentBrand.BrandModules[0] ?? {}
    },
    getCurrentFair () {
      return this.getCurrentBrandModule.Fair
    },
    getCurrentFairID () {
      return this.getCurrentFair.FairID
    },
    getCurrentMembership () {
      return this.currentMembership
    },
    getCurrentLeads () {
      // make unique by LeadID
      return this.currentLeads.filter((v, i, a) => a.findIndex(t => (t.LeadID === v.LeadID)) === i)
    },
    getLoadingBrand () {
      return this.isLoadingBrand
    },
    getLoadingMembership () {
      return this.isLoadingMembership
    },
    getCurrentLeadsCount () {
      return this.leadsCount
    },
    getMarkedContacts () {
      return this.markedContacts
    }
  },
  actions: {
    setCurrentBrand (brand) {
      if (brand === null) {
        this.currentBrandID = null
        this.currentBrand = null
        this.currentMembership = null
        this.currentLeads = []
        this.leadsCount = 0
        return
      }
      this.currentBrand = brand
      this.currentBrandID = brand.BrandID
      this.loadCurrentMembership()
    },
    async loadFaqData () {
      if (this.currentBrand === null) {
        return
      }
      const [
        defaultFaqs,
        brandFaqs
      ] = await Promise.all([
        this.fpApi._post('faqs', {
          ResFrom: 1,
          ResTo: 100,
          FullSubEntities: true,
          Filter: [
            {
              Field: 'BrandID',
              Operator: 'ISNULL'
            },
            {
              Field: 'VisibleAt',
              Operator: 'ISNULL'
            }
          ]
        }),
        this.fpApi._post('faqs', {
          ResFrom: 1,
          ResTo: 100,
          FullSubEntities: true,
          Filter: [
            {
              Field: 'BrandID',
              Operator: 'EQ',
              Value: this.currentBrand.BrandID
            }
          ]
        })
      ])

      this.faq.default = defaultFaqs.body.Result.Data ?? []
      this.faq.brand = brandFaqs.body.Result.Data ?? []
    },
    setBrandsFaqs (faqs) {
      this.faq.brand = faqs.brand
    },

    async updateBrandFaqs (updatedFaqs) {
      const faqIds = updatedFaqs.map(faq => faq.FaqID)
      const brandFaqIds = this.faq.brand.map(faq => faq.FaqID)
      const faqIdsToRemove = brandFaqIds.filter(faqId => !faqIds.includes(faqId))

      await Promise.all(faqIdsToRemove.map(async (faqId) => {
        await this.fpApi._delete(`faq/${faqId}`)
      }))

      const updatedFaqPromises = updatedFaqs.map(async (faq) => {
        if (faq.FaqID === -1) {
          const newFaq = await this.fpApi._put('faq', {
            BrandID: this.currentBrand.BrandID,
            ExternalID: faq.ExternalID,
            VisibleAt: faq.VisibleAt,
            Answer: faq.Answer,
            Question: faq.Question,
            SortOrder: faq.SortOrder
          })
          const loadedFaq = await this.fpApi._post('faq', {
            FaqID: newFaq.body.Result.NewID
          })
          return loadedFaq.body.Result.Data
        } else {
          await this.fpApi._put('faq', {
            FaqID: faq.FaqID,
            Answer: faq.Answer
          })
          return faq
        }
      })
      const updatedFaqsData = await Promise.all(updatedFaqPromises)
      this.faq.brand = updatedFaqsData
    },
    setCurrentBrandID (brandID) {
      this.currentBrandID = brandID
    },
    setCurrentMembership (membership) {
      this.currentMembership = membership
    },
    async loadCurrentMembership () {
      this.isLoadingMembership = true
      const result = await this.stripeApi._post(
        'getBrandMembershipValues', {
          BrandID: this.getCurrentBrandID
        }
      )
      this.currentMembership = result.body
      this.isLoadingMembership = false
    },
    setCurrentLeads (leads) {
      this.currentLeads = leads
    },
    addCurrentLeads (leads) {
      this.currentLeads = this.currentLeads.concat(leads)
    },
    clearCurrentLeads () {
      this.currentLeads = []
      this.setCurrentLeadsCount(0)
    },
    setCurrentLeadsCount (total) {
      this.leadsCount = total
    },
    markContact (ContactID) {
      this.markedContacts.push(ContactID)
    },
    unmarkContact (ContactID) {
      const index = this.markedContacts.indexOf(ContactID)
      if (index > -1) {
        this.markedContacts.splice(index, 1)
      }
    },
    async updateCurrentBrand (updatedData) {
      const brandUpdateBody = {
        BrandID: this.getCurrentBrandID,
        ...updatedData
      }
      await this.fpApi._put('brand', brandUpdateBody)
      for (const key in updatedData) {
        this.currentBrand[key] = updatedData[key]
      }
    },
    setCurrentFairUpdate (updatedData) {
      const currentFair = this.getCurrentFair
      this.currentBrand.BrandModules[0].Fair = {
        ...currentFair,
        ...updatedData
      }
    },
    async updateCurrentFair (updatedData) {
      const fairUpdateBody = {
        FairID: this.getCurrentFairID,
        ...updatedData
      }

      await this.fpApi._put('fair', fairUpdateBody)

      const fair = this.getCurrentFair
      for (const key in updatedData) {
        fair[key] = updatedData[key]
      }
    },
    async updateCurrentFairMainImage (newMainImage) {
      const fairUpdateBody = {
        FairID: this.getCurrentFairID,
        MainPictureID: newMainImage.MediumID
      }
      await this.fpApi._put('fair', fairUpdateBody)
      this.currentBrand.BrandModules[0].Fair.MainPictureID = newMainImage.MediumID
      this.currentBrand.BrandModules[0].Fair.MainPicture = newMainImage
    },
    async deleteFairPicture (FairPicture) {
      if (typeof FairPicture.Medium !== 'undefined' && FairPicture.Medium !== null) {
        await this.fpApi._delete(`FairPicture/${FairPicture.FairPictureID}`)
        if (FairPicture.MediumID !== null) {
          await this.fpApi._delete(`Medium/${FairPicture.MediumID}`)
        }
      }
    },
    async deleteFairVideo (FairVideo) {
      if (typeof FairVideo.Medium !== 'undefined' && FairVideo.Medium !== null) {
        const userStore = useUserStore()
        const deleteUrl = [Config.FPSERVICE_URL, 'mb/fair/video/delete/', this.currentBrand.BrandID, '/', FairVideo.MediumID].join('')
        await axios.delete(deleteUrl, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'User-Token': userStore.getToken
          }
        })

        await this.fpApi._delete(`FairVideo/${FairVideo.FairVideoID}`)
        const videoMedium = FairVideo.Medium
        if (videoMedium.StillImageID !== null) {
          await this.fpApi._delete(`Medium/${videoMedium.StillImageID}`)
        }
        if (videoMedium.MediumID !== null) {
          await this.fpApi._delete(`Medium/${videoMedium.MediumID}`)
        }
      }
    },
    async safeDeleteVideo (MediumID) {
      const userStore = useUserStore()
      const deleteUrl = [Config.FPSERVICE_URL, 'mb/fair/video/delete/', this.currentBrand.BrandID, '/', MediumID].join('')
      await axios.delete(deleteUrl, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'User-Token': userStore.getToken
        }
      })
    },
    setNewFairVideo (FairVideoID, Type) {
      this.currentBrand.BrandModules[0].Fair.FairVideos.forEach((FairVideo) => {
        if (FairVideo.FairVideoID === FairVideoID) {
          FairVideo.Type = Type
        }
      })
    },
    async addNewFairPicture (newPictureID) {
      const fairPictures = this.getCurrentFair.FairPictures
      const newFairPicture = await this.fpApi._put('FairPicture', {
        FairPictureID: -1,
        FairID: this.getCurrentFairID,
        MediumID: newPictureID,
        SortPos: fairPictures.length + 1
      })

      const NewFairPictureID = newFairPicture.body.Result.NewID

      const newSavedFairPictureResponse = await this.fpApi._post('FairPicture', {
        FullSubEntities: true,
        FairPictureID: NewFairPictureID
      })

      const newSavedFairPicture = newSavedFairPictureResponse.body.Result.Data
      fairPictures.push(newSavedFairPicture)

      this.currentBrand.BrandModules[0].Fair.FairPictures = fairPictures
      return fairPictures
    },
    async updateFairVideos () {
      const loadedFairVideos = await this.fpApi._post('FairVideos', {
        ResFrom: 1,
        ResTo: 1000,
        FullSubEntities: true,
        Filter: [
          {
            Field: 'FairID',
            Operator: 'eq',
            Value: this.getCurrentFairID
          }
        ]
      })
      this.currentBrand.BrandModules[0].Fair.FairVideos = loadedFairVideos.body.Result.Data
    },
    async getImageDimensions (blob) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
          resolve({
            width: img.naturalWidth,
            height: img.naturalHeight
          })
        }
        img.onerror = reject
        img.src = URL.createObjectURL(blob)
      })
    },
    async uploadImage (fileBlob, folder = 'SystemRoot') {
      if (fileBlob.size > 1e8) {
        Swal.fire(i18n.t('popupmessage.warn.imagetobigtitle'), i18n.t('popupmessage.warn.imagetogigmessage'), 'warning')
        return null
      }

      try {
        const resizeImageData = new FormData()
        resizeImageData.append('file', fileBlob)

        const response = await axios
          .post(`${Config.IMAGE_RESIZE_FUNCTION}binary`, resizeImageData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            responseType: 'blob'
          })

        const resizedFileBlob = new Blob([response.data], { type: fileBlob.type })
        const fileExtension = resizedFileBlob.type.split('/').pop()

        const newUploadFilename = [
          Vue.Utils.uuidv4(),
          fileExtension
        ].join('.')

        const uploadImageData = new FormData()
        uploadImageData.append('file', fileBlob, newUploadFilename)
        uploadImageData.append('folder', [folder, this.currentBrand.BrandID].join('/'))
        uploadImageData.append('overwriteMode', 1)
        uploadImageData.append('identifier', -1)

        const userStore = useUserStore()

        const uploadResponse = await axios
          .post(Config.MEDIA_API_URL, uploadImageData, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
              ...userStore.getAuthHeader
            }
          })

        const uploadedImage = uploadResponse.data.Data[0]
        const imageDimensions = await this.getImageDimensions(resizedFileBlob)

        const createMediumResponse = await this.fpApi._put('medium/', {
          MediumID: -1,
          FileID: uploadedImage.identifier,
          Filename: uploadedImage.fullPath,
          URL: uploadedImage.fullPath,
          FolderID: 5,
          Hash: uploadedImage.hash,
          Height: imageDimensions.height,
          Size: resizedFileBlob.size,
          State: 0,
          TypeID: 1,
          Width: imageDimensions.width
        })
        const newMediumID = createMediumResponse.body.Result.NewID
        const loadedMedium = await this.fpApi._post('medium/', {
          MediumID: newMediumID
        })

        return loadedMedium.body.Result.Data
      } catch (error) {
        console.error(error)
      }

      return null
    },
    // newVideo { Title, File }
    async uploadVideo (newVideo, folder = 'SystemRoot') {
      const pageStore = usePageStore()
      try {
        const formData = new FormData()
        formData.append('file', newVideo.File)
        formData.append('title', newVideo.Title)
        formData.append('brandId', this.currentBrand.BrandID)

        const userStore = useUserStore()

        const uploadUrl = [Config.FPSERVICE_URL, 'mb/fair/video/upload'].join('')
        const response = await axios.post(uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'User-Token': userStore.getToken
          },
          timeout: 0,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            pageStore.setVideoUploadProgress(percentCompleted)
          }
        })

        const videoData = response.data
        const stillImageUrl = videoData.StillImageURL

        const uploadImageData = new FormData()

        // Wait until the stillImageUrl becomes available, retrying up to 5 times
        let imageAvailable = false
        let retryCount = 0
        const maxRetries = 20

        pageStore.setVideoThumbnailState(true)

        while (!imageAvailable && retryCount < maxRetries) {
          try {
            await axios.request({
              method: 'HEAD',
              url: stillImageUrl,
              responseType: 'arraybuffer'
            })
            imageAvailable = true
          } catch (error) {
            retryCount++
            if (retryCount >= maxRetries) {
              throw new Error('Image not available after multiple retries.')
            }
            await new Promise(resolve => setTimeout(resolve, 2500))
          }
        }

        const fileBlobRequest = await axios.request({
          url: stillImageUrl,
          responseType: 'arraybuffer'
        })

        const uploadBlob = new Blob([fileBlobRequest.data], { type: 'image/jpeg' })
        uploadImageData.append('file', uploadBlob, `still-${videoData.MediumID}.jpg`)
        uploadImageData.append('folder', folder)
        uploadImageData.append('overwriteMode', 1)
        uploadImageData.append('identifier', -1)

        // this.APIMedia._put('medium', uploadImageData)

        const stillImageUploadResponse = await axios
          .post(Config.MEDIA_API_URL, uploadImageData, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
              ...userStore.getAuthHeader
            }
          })

        const uploadedStillImage = stillImageUploadResponse.data.Data[0]

        const createMediumResponse = await this.fpApi._put('Medium', {
          MediumID: -1,
          FileID: uploadedStillImage.identifier,
          Filename: uploadedStillImage.fullPath,
          URL: uploadedStillImage.fullPath,
          FolderID: 5,
          Hash: uploadedStillImage.hash,
          Height: uploadedStillImage.mediainfo.height,
          Size: uploadedStillImage.size,
          State: 0,
          TypeID: 1,
          Width: uploadedStillImage.mediainfo.width
        })

        await this.fpApi._put('Medium', {
          MediumID: videoData.MediumID,
          StillImageID: createMediumResponse.body.Result.NewID
        })

        const loadedVideo = await this.fpApi._post('Medium', {
          MediumID: videoData.MediumID,
          FullSubEntities: true
        })

        pageStore.endVideoUpload()

        return loadedVideo.body.Result.Data
      } catch (error) {
        console.error('Error uploading video', error)
      }
      return null
    },
    async addUploadedFairVideo (newUploadedVideo) {
      try {
        if (!newUploadedVideo) {
          throw new Error('Error uploading Video')
        }

        const currentFairVideos = this.currentBrand.BrandModules[0].Fair.FairVideos
        const maxSortPos = currentFairVideos.reduce((max, video) => {
          return video.SortPos > max ? video.SortPos : max
        }, 0)

        const newFairVideoOptions = {
          FairVideoID: -1,
          FairID: this.getCurrentFairID,
          MediumID: newUploadedVideo.MediumID,
          Type: 3,
          SortPos: maxSortPos + 1
        }

        const newFairVideo = await this.fpApi._put('FairVideo', newFairVideoOptions)
        const loadedFairVideo = await this.fpApi._post('FairVideo', {
          FairVideoID: newFairVideo.body.Result.NewID,
          FullSubEntities: true
        })

        this.addFairVideo(loadedFairVideo.body.Result.Data)
        return loadedFairVideo.body.Result.Data
      } catch (error) {
        console.error('Error uploading video', error)
      }
      return null
    },
    addFairVideo (fairVideo) {
      if (!this.currentBrand.BrandModules[0].Fair.FairVideos) {
        this.currentBrand.BrandModules[0].Fair.FairVideos = []
      }
      this.currentBrand.BrandModules[0].Fair.FairVideos.push(fairVideo)
    },
    async uploadFairVideo (newVideo, type = 3) {
      try {
        const newUploadedVideo = await this.uploadVideo(newVideo, 'SystemRoot/Fair/FairVideos')

        if (!newUploadedVideo) {
          throw new Error('Error uploading Video')
        }

        // search for currentFair.FairVideos with Type = type
        const currentFairVideos = this.currentBrand.BrandModules[0].Fair.FairVideos
        const currentFairVideo = currentFairVideos.find((video) => video.Type === type)

        if (currentFairVideo) {
          currentFairVideo.Type = 3
          await this.fpApi._put('FairVideo', {
            FairVideoID: currentFairVideo.FairVideoID,
            Type: 3
          })
        }

        const newFairVideoOptions = {
          FairVideoID: -1,
          FairID: this.getCurrentFairID,
          MediumID: newUploadedVideo.MediumID,
          Type: type ?? 3,
          SortPos: null
        }

        const newFairVideo = await this.fpApi._put('FairVideo', newFairVideoOptions)
        const loadedFairVideo = await this.fpApi._post('FairVideo', {
          FairVideoID: newFairVideo.body.Result.NewID,
          FullSubEntities: true
        })

        this.currentBrand.BrandModules[0].Fair.FairVideos.push(loadedFairVideo.body.Result.Data)
        return loadedFairVideo.body.Result.Data
      } catch (error) {
        console.error('Error uploading video', error)
      }
      return null
    },
    getVideoLink (video) {
      if (!video) {
        return ''
      }
      if (typeof video.Filename === 'undefined') {
        return ''
      }
      if (video.Filename === 'youtube') {
        return `https://www.youtube.com/watch?v=${video.URL}`
      }
      if (video.Filename === 'sproutvideo') {
        return `https://videos.sproutvideo.com/embed/${video.URL}`
      }
      return `${Config.MEDIA_API_URL}${video.URL}`
    },
    validateFairWelcome (Brand, Fair) {
      return (
        (Fair.FairContactName !== null && String(Fair.FairContactName).length > 0) &&
        (Brand.InterestedMail !== null && String(Brand.InterestedMail).length > 0) &&
        (
          Fair.FairContactWelcome !== null && (
            String(Fair.FairContactWelcome).length > 0 &&
            String(Fair.FairContactWelcome).length <= 400 &&
            String(Fair.FairContactWelcome).includes(Fair.FairContactName) &&
            (
              Brand.ShortBrandName !== null && String(Brand.ShortBrandName).length > 0
                ? (
                    String(Fair.FairContactWelcome).includes(Brand.ShortBrandName) ||
                String(Fair.FairContactWelcome).includes(Brand.Name)
                  )
                : String(Fair.FairContactWelcome).includes(Brand.Name)
            )
          )
        )
      )
    },
    validateFinancialConditionsForBrand (Brand) {
      return (

        // CondInvestSum
        (
          Brand.CondInvestSumType !== null &&
          Brand.CondInvestSumType !== '' &&
          (
            (
              Brand.CondInvestSumType === 1 &&
              Brand.CondInvestSumFrom !== null &&
              Brand.CondInvestSumFrom !== '' &&
              Brand.CondInvestSumTo !== null &&
              Brand.CondInvestSumTo !== '' &&
              Brand.CondInvestSumTo > Brand.CondInvestSumFrom
            ) ||
            (
              Brand.CondInvestSumType === 2 &&
              Brand.CondInvestSumFrom !== null &&
              Brand.CondInvestSumFrom !== ''
            ) ||
            (
              Brand.CondInvestSumType === 3 &&
              Brand.CondInvestSumFrom !== null &&
              Brand.CondInvestSumFrom !== ''
            )
          )
        ) &&

        // CondRequiredCapital
        (
          Brand.CondRequiredCapitalType !== null &&
          Brand.CondRequiredCapitalType !== '' &&
          (
            (
              Brand.CondRequiredCapitalType === 1 &&
              Brand.CondRequiredCapitalFrom !== null &&
              Brand.CondRequiredCapitalFrom !== '' &&
              Brand.CondRequiredCapitalTo > Brand.CondRequiredCapitalFrom
            ) ||
            (
              Brand.CondRequiredCapitalType === 2 &&
              Brand.CondRequiredCapitalFrom !== null &&
              Brand.CondRequiredCapitalFrom !== ''
            ) ||
            (
              Brand.CondRequiredCapitalType === 3 &&
              Brand.CondRequiredCapitalFrom !== null &&
              Brand.CondRequiredCapitalFrom !== ''
            )
          )
        ) &&

        // CondEntryFee
        (
          Brand.CondEntryFeeType !== null &&
          Brand.CondEntryFeeType !== '' &&
          (
            (
              Brand.CondEntryFeeType === 1 &&
              Brand.CondEntryFeeFrom !== null &&
              Brand.CondEntryFeeFrom !== '' &&
              Brand.CondEntryFeeTo > Brand.CondEntryFeeFrom
            ) ||
            (
              Brand.CondEntryFeeType === 2 &&
              Brand.CondEntryFeeFrom !== null &&
              Brand.CondEntryFeeFrom !== ''
            ) ||
            (
              Brand.CondEntryFeeType === 3 &&
              Brand.CondEntryFeeFrom !== null &&
              Brand.CondEntryFeeFrom !== ''
            )
          )
        ) &&

        // CondInitialCapital
        (
          Brand.CondInitialCapitalType !== null &&
          Brand.CondInitialCapitalType !== '' &&
          (
            (
              Brand.CondInitialCapitalType === -1
            ) ||
            (
              Brand.CondInitialCapitalType === 1 &&
              Brand.CondInitialCapitalFrom !== null &&
              Brand.CondInitialCapitalFrom !== '' &&
              Brand.CondInitialCapitalTo > Brand.CondInitialCapitalFrom
            ) ||
            (
              Brand.CondInitialCapitalType === 2 &&
              Brand.CondInitialCapitalFrom !== null &&
              Brand.CondInitialCapitalFrom !== ''
            ) ||
            (
              Brand.CondInitialCapitalType === 3 &&
              Brand.CondInitialCapitalFrom !== null &&
              Brand.CondInitialCapitalFrom !== ''
            )
          )
        ) &&

        // CondDescription
        String(Brand.CondDescription ?? '').length <= 500 &&

        // Relations to CondInvestSum
        (
          Brand.CondInvestSumFrom > Brand.CondRequiredCapitalFrom &&
          Brand.CondInvestSumFrom > Brand.CondEntryFeeFrom &&
          Brand.CondInvestSumFrom > Brand.CondInitialCapitalFrom
        )
      )
    }
  }
})
